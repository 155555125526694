import { useState } from 'react';

export const useModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return {
    open: () => {
      setIsModalVisible(true);
    },
    close: () => {
      setIsModalVisible(false);
    },
    isOpen: isModalVisible
  }
};
