import { VEHICLE_FETCHED } from '../actions/vehicles';
import { find } from 'lodash';

const initialState = {
  list: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE_FETCHED:
      let vehicleExist = find(state.list, { id: action.id });

      if (vehicleExist) {
        return state;
      } else {
        let vehicles = [...state.list, action.vehicles];

        return {
          ...state,
          list: vehicles
        };
      }
    default:
      return state;
  }
};
