import { APP_TOGGLE_USER_MENU } from '../actions/app';
import { APP_TOGGLE_LANGUAGE } from '../actions/app';

const initialState = {
  isUserMenuOpen: false,
  language: localStorage.getItem('lng') || 'fr'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_TOGGLE_USER_MENU:
      return {
        ...state,
        isUserMenuOpen: action.status
      };
    case APP_TOGGLE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state;
  }
};
