import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import config from '../../config';
import PasswordInput from './PasswordInput';
import { authenticateUser } from '../../store/actions/auth';
import { fetchUserData } from '../../store/actions/account';

const LoginForm = ({ history }) => {
  const tenant = useSelector(state => state.tenant);
  const { t } = useTranslation(['account', 'validation']);
  const dispatch = useDispatch();
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [sessionToken, setSessionToken] = useState();

  const initialValues = {
    username: '',
    password: ''
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required(t('validation:required'))
      .email(t('validation:invalid-format')),
    password: Yup.string().required(t('validation:required'))
  });

  const errorMessage = (
    <div className="error-message">{t('account:login-error')}</div>
  );

  const handleLoginSSO = async values => {
    let url =
      tenant.singleSignOnUrl +
      '&state=' +
      encodeURI(
        `{"source": "frontofficev2", "redirect_to": "${window.location.origin}/frontofficev2/implicit/callback"}`
      );
    window.location.href = url;
  };

  const handleLogin = async values => {
    await dispatch(authenticateUser(values));
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setIsAccessDenied(false);
      try {
        await handleLogin(values);
        history.push(config.auth.rootUrl);
      } catch (error) {
        setSubmitting(false);
        setIsAccessDenied(true);
      }
    }
  });

  if (sessionToken) {
    return null;
  }

  const handleRegister = () => {
    history.push(`${config.auth.rootUrl}forgot-password`);
  };

  const handleSignup = () => {
    history.push(`${config.auth.rootUrl}signup`);
  };

  return (
    <Container component="main" maxWidth="xs" className="login-or-register">
      <div className="inner">
        <Typography component="h1" variant="h5">
          {t('account:login-title')}
        </Typography>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <TextField
              name="username"
              placeholder={t('account:email')}
              variant="outlined"
              margin="normal"
              value={values.username}
              onChange={handleChange}
              error={touched.username && !!errors.username}
              helperText={
                touched.username && errors.username ? errors.username : null
              }
              fullWidth
            />
          </div>

          <div className="form-group">
            <PasswordInput
              name="password"
              placeholder={t('account:password')}
              value={values.password}
              onChange={handleChange}
              error={touched.password && !!errors.password}
              helperText={
                touched.password && errors.password ? errors.password : null
              }
            />

            <div className="links">
              <a onClick={handleRegister}>{t('account:forgot-password')}</a>
            </div>

            {tenant.useRegistration && tenant.autoRegister && (
              <div className="links">
                <a onClick={handleSignup}>{t('account:signup')}</a>
              </div>
            )}
          </div>

          {isAccessDenied ? errorMessage : null}

          <div className="button-group">
            <Button
              type="submit"
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {t('account:login')}
            </Button>
          </div>
        </form>
        <div className="button-sso">
          {tenant.useSingleSignOn ? (
            <Button
              fullWidth
              onClick={() => {
                handleLoginSSO();
              }}
              variant="outlined"
              color="primary"
            >
              {t('account:loginOidc')}
            </Button>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default withRouter(LoginForm);
