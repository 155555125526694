import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

import { getAccountDetails } from '../../store/selectors/account';
import { createGuestToken, logOut } from '../../store/actions/auth';
import PasswordInput from '../auth/PasswordInput';
import api from '../../utils/api';
import { useModal } from '../../utils/modal';

const DeleteAccountModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'validation']);
  const user = useSelector(getAccountDetails);
  const { enqueueSnackbar } = useSnackbar();
  const [redirectLogin, setRedirectLogin] = useState(false);
  const tenant = useSelector(state => state.tenant);

  const handleClose = () => {
    if (typeof onClose === 'function') {
      setRedirectLogin(false);
      onClose();
    }
  };

  const handleRedirect = async () => {
    try {
      handleClose();
      setRedirectLogin(false);
      await handleLogout();
    } catch (error) {}
  };

  const handleLogout = async () => {
    const id_token = localStorage.getItem('id_token');
    await dispatch(logOut(tenant.useSingleSignOn && id_token));
    if (tenant.useSingleSignOn && id_token) {
      let url =
        tenant.singleSignOnUrlLogout +
        '&state=' +
        encodeURI(
          `{"source": "frontofficev2", "redirect_to": "${window.location.origin}/frontofficev2/"}`
        ) +
        '&id_token_hint=' +
        id_token;
      window.location.href = url;
    }
    await dispatch(createGuestToken());
  };

  const handleDelete = async () => {
    try {
      await api.user.deleteUser(user);
      setRedirectLogin(true);
    } catch (error) {
      handleClose();
      let message = 'validation:internal-error';
      if (
        _.get(error, 'response.data.errors[0].code') ===
        'user_rentals_user_has_rental'
      ) {
        message = 'validation:profil-cant-delete-user';
      }
      if (
        _.get(error, 'response.data.errors[0].code') ===
        'user_invoices_as_buyer_user_has_pending_invoice'
      ) {
        message = 'validation:profil-cant-delete-user';
      }
      enqueueSnackbar(t(message), { variant: 'error' });
    }
  };

  const buttonModal = async () => {
    redirectLogin ? handleRedirect() : handleDelete();
  };

  return (
    <p>
      <Dialog
        className="delete-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="delete-dialog-title">
          {redirectLogin
            ? t('account:account-delete-title')
            : t('account:account-delete-title')}
        </DialogTitle>
        <DialogContent>
          {redirectLogin
            ? t('account:account-deleted-content')
            : t('account:account-delete-content')}
        </DialogContent>
        <DialogActions>
          {redirectLogin ? null : (
            <Button onClick={handleClose}>{t('common:cancel')}</Button>
          )}
          <Button
            onClick={buttonModal}
            type="submit"
            color="primary"
            disabled={false}
          >
            {redirectLogin ? t('common:next') : t('common:yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </p>
  );
};

export default DeleteAccountModal;
