import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

import SearchInput from './SearchInput';
import menuIcon from '../../assets/images/menu.svg';
import SearchButton from './SearchButton';
import DateFilter from './filters/DateFilter';
import CategoryFilter from './filters/CategoryFilter';
import OptionFilter from './filters/OptionFilter';
import SeatsCountFilter from './filters/SeatsCountFilter';
import StationFilter from './filters/StationFilter';
import GearsFilter from './filters/GearsFilter';
import { useDrawerMenu } from '../../hooks/drawerMenu';
import config from '../../config';

const SearchHeader = ({
  handleDeleteGearsType,
  handleDeleteSeatsCount,
  handleDeleteCategory,
  handleDeleteOptions,
  history
}) => {
  const tenant = useSelector(state => state.tenant);
  const { t } = useTranslation(['search']);
  const searchLocation = useSelector(state =>
    tenant.searchEngine === 'station'
      ? state.search.startStation.displayText
      : state.search.location.displayText
  );
  const userMenu = useDrawerMenu();

  const openSearchForm = () => {
    history.push(`${config.auth.rootUrl}search`);
  };

  const openUserMenu = () => {
    userMenu.open();
  };

  return (
    <div className="search-header">
      <div className="header">
        <div className="menu-icon">
          <IconButton onClick={openUserMenu}>
            <img src={menuIcon} alt="" width="30" height="30" />
          </IconButton>
        </div>

        {tenant.searchEngine === 'address' ? (
          <div className="search-bar" onClick={openSearchForm}>
            <SearchInput value={searchLocation} />
          </div>
        ) : null}
      </div>
      {tenant.searchEngine === 'station' ? (
        <div className="search-button" onClick={openSearchForm}>
          <h2>{t('search:menu-search-vehicule')}</h2>
          <SearchButton value={searchLocation} />
        </div>
      ) : null}
      <div className="search-filters">
        {tenant.searchEngine === 'station' ? (
          <StationFilter onClick={openSearchForm} />
        ) : null}
        <DateFilter onClick={openSearchForm} />
        <OptionFilter
          handleDeleteOptions={handleDeleteOptions}
          onClick={openSearchForm}
        />
        <CategoryFilter
          handleDeleteCategory={handleDeleteCategory}
          onClick={openSearchForm}
        />
        <SeatsCountFilter
          handleDeleteSeatsCount={handleDeleteSeatsCount}
          onClick={openSearchForm}
        />
        <GearsFilter
          handleDeleteGearsType={handleDeleteGearsType}
          onClick={openSearchForm}
        />
      </div>
    </div>
  );
};

export default withRouter(SearchHeader);
