import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { StripeProvider } from 'react-stripe-elements';

import 'moment/locale/fr';
import { useLanguageLoader } from './hooks/languageLoader';
import { fetchRentalLabels } from './store/actions/rentalLabels';
import { fetchUserData } from './store/actions/account';
import { fetchCategories } from './store/actions/categories';
import { fetchStations } from './store/actions/stations';
import { fetchTermsAndConditions } from './store/actions/termsAndConditions';
import CenteredLoader from './components/shared/CenteredLoader';

import AppRoutes from './AppRoutes';
import api from './utils/api';
import config from './config';

const MainComponent = ({ theme }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const tenant = useSelector(state => state.tenant);
  const [stripeKey, setStripeKey] = useState(config.stripe.publishableKey);
  const useLanguage = useLanguageLoader();

  const loadCurrentUser = async () => {
    const tokenUser = localStorage.getItem(config.auth.accessTokenKey);
    const tokenType = localStorage.getItem('token_type');
    if (tokenUser && tokenType === 'member') {
      try {
        await dispatch(fetchUserData());
        dispatch({ type: 'AUTH_LOGGED_IN' });
      } catch (error) {
        console.error(error);
      }
    } else {
      dispatch({ type: 'AUTH_LOGGED_OUT' });
    }
  };
  const loadStripeKey = async () => {
    const tokenStripe = localStorage.getItem(config.auth.accessTokenKey);
    const tokenType = localStorage.getItem('token_type');

    if (tokenStripe && tokenType === 'member') {
      const { stripe_key } = await api.stripe.getKey();
      setStripeKey(stripe_key.public);
    }
  };

  const setFavicon = async () => {
    let favicon = document.getElementById('favicon');
    document.createElement('link');
    favicon.id = 'favicon';
    favicon.type = 'image/x-icon';
    favicon.rel = 'shorcut icon';
    if (tenant.favicon) {
      favicon.href = tenant.favicon;
    } else {
      favicon.href = theme.tenant.assets.favicon;
    }

    document.getElementsByTagName('head')[0].appendChild(favicon);
  };

  const setTitle = async () => {
    document.title = tenant.human_name;
  };

  const loadData = async () => {
    setFavicon();
    setTitle();
    try {
      if (tenant.language === 'en') {
        useLanguage.toggleEnglish();
      }
      moment.tz.setDefault(tenant.timezone);
      await dispatch(fetchStations());
      if (tenant.useCategories) {
        await dispatch(fetchCategories());
      }
      if (tenant.useLabels) {
        await dispatch(fetchRentalLabels());
      }
      if (tenant.useTermsAndConditions) {
        await dispatch(fetchTermsAndConditions());
      }
      if (tenant.usePayments) {
        await loadStripeKey();
      }
      await loadCurrentUser();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error while loading data...');
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return !isLoading ? (
    <StripeProvider apiKey={stripeKey}>
      <AppRoutes tenant={tenant} />
    </StripeProvider>
  ) : (
    <CenteredLoader />
  );
};
export default MainComponent;
