export const distance = (autonomy, unit = 'km') => {
  if (autonomy === 0) return autonomy;
  let unit_map = {
    km: 1000,
    mi: 1609.344
  };
  let unit_value = unit_map[unit];

  return (autonomy / unit_value).toFixed(1);
};
