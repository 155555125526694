import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../assets/images/close.svg';
import DrawerFooter from './DrawerFooter';
import config from '../../config';

const GuestMenu = ({ onClose, history }) => {
  const tenant = useSelector(state => state.tenant);
  const { t } = useTranslation(['account', 'common']);

  const handleSignupClick = () => {
    history.push(`${config.auth.rootUrl}signup`);
  };

  const handleLoginClick = () => {
    history.push(`${config.auth.rootUrl}login`);
  };

  return (
    <div className="user-menu guest-menu tenant-theme">
      <div className="drawer-header">
        <div className="close-button">
          <IconButton onClick={onClose}>
            <img src={closeIcon} alt="" width="30" height="30" />
          </IconButton>
        </div>
      </div>

      <div className="drawer-body">
        <div className="logo-container">
          <img src={tenant.logo} alt={tenant.human_name} width="145" />
        </div>

        <div className="cta-text">{t('account:signin-cta')}</div>

        {tenant.useRegistration ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSignupClick}
          >
            {t('account:signup')}
          </Button>
        ) : null}

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleLoginClick}
        >
          {t('account:login')}
        </Button>
      </div>

      <DrawerFooter />
    </div>
  );
};

export default withRouter(GuestMenu);
