export const stepMapping = {
  driver_on_login_form: 'registration',
  driver_on_personal_informations_form: 'personalDetails',
  driver_on_sms_form: 'phoneNumber',
  driver_on_licence_form: 'licenseDetails',
  driver_on_documents_licence_form: 'driverLicense',
  driver_on_documents_identity_form: 'idDocument',
  driver_on_documents_selfie_form: 'selfieDocument',
  driver_on_documents_other_form: 'otherDocument',
  driver_on_card_form: 'creditCard',
  driver_waiting_for_validation: 'pendingValidation',
  driver_blocked: 'Blocked'
};

const signupSteps = [
  'registration',
  'personalDetails',
  'phoneNumber',
  'licenseDetails',
  'driverLicense',
  'idDocument',
  'selfieDocument',
  'otherDocument',
  'pendingValidation'
];

export function mapServerStep(serverStep) {
  let step = stepMapping[serverStep] ? stepMapping[serverStep] : null;
  return step;
}

export function isRegistrationOK(serverStep) {
  return ['driver_waiting_for_validation', 'driver_ok'].includes(serverStep);
}

export function isRegistrationComplete(serverStep) {
  return serverStep === 'driver_ok';
}

export function shouldCompleteSignup(currentStep) {
  return signupSteps.includes(currentStep);
}

export function getCurrentProcessSteps(currentStep) {
  if (shouldCompleteSignup(currentStep)) {
    return signupSteps;
  }
  return [];
}

export function getUserStatus(isValidated, workflowNextStep) {
  if (isValidated === true) {
    return 'verified';
  } else if (
    !isValidated &&
    workflowNextStep === 'driver_waiting_for_validation'
  ) {
    return 'pending_validation';
  } else {
    return 'incomplete';
  }
}
