import React from 'react';
import MultiDigitInputControl from './MultiDigitInputControl';

function MultiDigitInput({ digits = 6, onComplete, disabled = false }) {
  let initialValues = {};

  const initValues = (cb) => {
    const values = {};
    for (let i = 0; i < digits ; i += 1) {
      values[i] = '';
    }
    initialValues = {...values};

    if (typeof cb === 'function') {
      cb(values);
    }
  };

  const handleChange = (value, setValues) => {
    if (value.length === digits && typeof onComplete === 'function') {
      onComplete(value, () => {
        initValues(setValues);
      });
    }
  };

  initValues();

  return (
    <div className="multi-digit-input-container">
      <MultiDigitInputControl
        initialValues={initialValues}
        digits={digits}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
}

export default MultiDigitInput;
