import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import cardIcon from '../../assets/images/icon-cards.svg';
import FileUploadButton from '../shared/FileUploadButton';
import api from '../../utils/api';
import { useErrorToaster } from '../../utils/errorToaster';
import { fetchUserData } from '../../store/actions/account';
import { fetchAdditionalDocuments } from '../../store/actions/tenant';
import HelpModal from '../shared/HelpModal';
import { useModal } from '../../utils/modal';

function OtherDocument() {
  const { t } = useTranslation(['account', 'common']);
  const tenant = useSelector(state => state.tenant);
  const language = useSelector(state => state.app.language);
  const additionalDocumentsFr = tenant.additionalDocumentsFr;
  const additionalDocumentsEn = tenant.additionalDocumentsEn;
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const modal = useModal();
  const [uploadStatus, setUploadStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadAdditionalDocuments = async () => {
    try {
      await dispatch(fetchAdditionalDocuments());
    } catch (error) {
      console.error('Error while additional_documents.');
    }
  };

  useEffect(() => {
    setUploadStatus(
      Object.assign(
        {},
        ...additionalDocumentsFr.map(additionalDocument => ({
          [additionalDocument.name]: false
        }))
      )
    );
  }, [additionalDocumentsFr]);

  useEffect(() => {
    loadAdditionalDocuments();
  }, []);

  const handleDocumentUpload = async (
    type,
    additionalDocumentId,
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();
    setIsLoading(true);

    try {
      const response = await api.user.addDocument('other', file, {
        onUploadProgress: event => {
          const percent = Math.round((event.loaded * 100) / event.total);
          setProgress(percent);
        }
      });

      await api.user.attachDocument(response.document.id, additionalDocumentId);

      setIsLoading(false);
      setCompleted();

      setUploadStatus({
        ...uploadStatus,
        [type]: true
      });
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  const handleNextButton = async () => {
    try {
      await dispatch(fetchUserData());
    } catch (error) {
      displayError(error);
    }
  };

  const handleClose = () => {
    modal.close();
  };

  return (
    <div className="selfie-upload doc-upload-view">
      <Typography component="h1">{t('account:other-photo')}</Typography>

      <div className="about-button">
        <button type="button" className="help-button" onClick={modal.open}>
          {t('account:explain-other-document')}
        </button>
      </div>

      {language === 'fr' ? (
        <div className="upload-others">
          {additionalDocumentsFr.map(additionalDocument => (
            <FileUploadButton
              key={additionalDocument.name}
              className="upload-button"
              image={<img src={cardIcon} alt="" width="100" height="76" />}
              onFileSelected={(file, callbacks) =>
                handleDocumentUpload(
                  additionalDocument.name,
                  additionalDocument.id,
                  file,
                  callbacks
                )
              }
              accept={'image/*, application/pdf'}
              disabled={isLoading}
            >
              {additionalDocument.title}
            </FileUploadButton>
          ))}
        </div>
      ) : (
        <div className="upload-others">
          {additionalDocumentsEn.map(additionalDocument => (
            <FileUploadButton
              key={additionalDocument.name}
              className="upload-button"
              image={<img src={cardIcon} alt="" width="100" height="76" />}
              onFileSelected={(file, callbacks) =>
                handleDocumentUpload(
                  additionalDocument.name,
                  additionalDocument.id,
                  file,
                  callbacks
                )
              }
              accept={'image/*, application/pdf'}
              disabled={isLoading}
            >
              {additionalDocument.title}
            </FileUploadButton>
          ))}
        </div>
      )}

      <div className="button-group">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!Object.values(uploadStatus).every(v => v === true)}
          onClick={handleNextButton}
        >
          {t('common:next')}
        </Button>
      </div>

      <Dialog
        open={modal.isOpen}
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('account:explain-other-document')}
        </DialogTitle>
        <DialogContent dividers>
          {language === 'fr' ? (
            <DialogContentText>
              {additionalDocumentsFr.map(additionalDocument => (
                <p key={additionalDocument.name}>
                  <strong>{additionalDocument.title} :</strong>{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: decodeURIComponent(additionalDocument.description)
                    }}
                  ></span>
                </p>
              ))}
            </DialogContentText>
          ) : (
            <DialogContentText>
              {additionalDocumentsEn.map(additionalDocument => (
                <p key={additionalDocument.name}>
                  <strong>{additionalDocument.title} :</strong>{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: decodeURIComponent(additionalDocument.description)
                    }}
                  ></span>
                </p>
              ))}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={modal.close} color="primary">
            {t('common:close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OtherDocument;
