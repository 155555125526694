import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useCustomContent } from '../../hooks/customContent';
import { useTranslation } from 'react-i18next';

function AskPrivacyUsage({
  handleGeotracking,
  handleCloseGeotracking,
  buttonSubmitText,
  open,
  onClose
}) {
  const { t } = useTranslation(['common', 'booking']);
  const page = useCustomContent('privacy_usage');

  const handleClose = () => {
    if (typeof handleCloseGeotracking === 'function') {
      handleCloseGeotracking(false);
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleOkGeotracking = () => {
    if (typeof handleGeotracking === 'function') {
      handleGeotracking(true);
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div className="help-modal">
      <Dialog
        disableBackdropClick
        open={open}
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">{page.title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {page.content ? (
              <span
                className="pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(page.content)
                }}
              ></span>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkGeotracking} color="primary">
            {buttonSubmitText}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t('booking:closed')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AskPrivacyUsage;
