import React from 'react';
import { useTranslation } from 'react-i18next';
import powerOnImage from '../../../assets/images/vehicle/power-on.svg';
import powerOffImage from '../../../assets/images/vehicle/power-off.svg';
import autonomyImage from '../../../assets/images/vehicle/autonomy.svg';
import stateOfChargeImage from '../../../assets/images/vehicle/state-of-charge.svg';
import { distance } from '../../../utils/distanceHelper';

const ElectricOptions = ({
  stateOfCharge,
  autonomy,
  chargingStatus,
  unitAutonomy
}) => {
  const { t } = useTranslation(['booking']);
  const chargingStatuses = {
    charging: 'booking:charging',
    not_charging: 'booking:not_charging'
  };

  const chargingStatusesImages = {
    charging: powerOnImage,
    not_charging: powerOffImage
  };

  return (
    <div className="features">
      <div className="feature">
        <div className="icon">
          <img src={stateOfChargeImage} alt="" />
        </div>
        <div className="label">{stateOfCharge ? `${stateOfCharge}%` : `?`}</div>
      </div>

      <div className="feature">
        <div className="icon">
          <img src={autonomyImage} alt="" />
        </div>
        <div className="label">
          {autonomy
            ? `${(distance(autonomy), unitAutonomy)} ${unitAutonomy}`
            : `?`}
        </div>
      </div>

      <div
        className={
          chargingStatus && chargingStatuses[chargingStatus]
            ? 'feature'
            : 'hidden'
        }
      >
        <div className="icon">
          <img src={chargingStatusesImages[chargingStatus]} alt="" />
        </div>
        <div className="label">{t(chargingStatuses[chargingStatus])}</div>
      </div>
    </div>
  );
};

export default ElectricOptions;
