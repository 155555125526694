import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PasswordInput from '../auth/PasswordInput';
import * as Yup from 'yup';
import api from '../../utils/api';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

const ChangePasswordModal = ({ open, onClose }) => {
  const { t } = useTranslation(['common', 'validation']);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const initialValues = {
    currentPassword: '',
    password: '',
    passwordConfirmation: ''
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t('validation:required')),
    password: Yup.string()
      .required(t('validation:required'))
      .min(8, t('validation:pwd-too-short')),
    passwordConfirmation: Yup.string()
      .required(t('validation:required'))
      .oneOf([Yup.ref('password')], t('validation:pwd-mismatch'))
  });

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      await api.user.changePassword(values);
      enqueueSnackbar(t('account:password-changed'), { variant: 'success' });
      handleClose();
    } catch (error) {
      let message = 'validation:internal-error';

      if (
        _.get(error, 'response.data.errors[0].code') ===
        'user_current_password_invalid'
      ) {
        message = 'validation:incorrect-current-pwd';
      }

      enqueueSnackbar(t(message), { variant: 'error' });
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      className="change-password-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="password-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleChange, isSubmitting, errors, touched }) => (
          <Form className="frontoffice-form">
            <DialogTitle id="password-dialog-title">
              {t('account:change-password')}
            </DialogTitle>

            <DialogContent>
              <div className="form-group">
                <PasswordInput
                  id="old-pwd-input"
                  name="currentPassword"
                  placeholder={t('account:current-password')}
                  value={values.currentPassword}
                  onChange={handleChange}
                  error={touched.currentPassword && !!errors.currentPassword}
                  helperText={
                    touched.currentPassword && errors.currentPassword
                      ? errors.currentPassword
                      : null
                  }
                />
              </div>

              <div className="form-group">
                <PasswordInput
                  id="new-pwd-input"
                  name="password"
                  placeholder={t('account:new-password')}
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && !!errors.password}
                  helperText={
                    touched.password && errors.password ? errors.password : null
                  }
                />
              </div>

              <div className="form-group">
                <PasswordInput
                  id="confirm-pwd-input"
                  name="passwordConfirmation"
                  placeholder={t('account:confirm-password')}
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  error={
                    touched.passwordConfirmation &&
                    !!errors.passwordConfirmation
                  }
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                      ? errors.passwordConfirmation
                      : null
                  }
                />
              </div>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>{t('common:cancel')}</Button>

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {t('common:save')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ChangePasswordModal;
