import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';

const GearsFilter = ({ handleDeleteGearsType, onClick }) => {
  const { t } = useTranslation(['search']);
  const gearsType = useSelector(state => state.search.gearsType);

  if (gearsType && gearsType !== 'all') {
    return (
      <div style={{ display: 'inline' }}>
        <Chip
          key={`chip-gears-${gearsType}`}
          label={t(`gears-type-${gearsType}`)}
          onClick={onClick}
          onDelete={() => handleDeleteGearsType(gearsType)}
          color="primary"
          variant="outlined"
        />
      </div>
    );
  } else {
    return null;
  }
};

export default GearsFilter;
