import React from 'react';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';

const StationFilter = props => {
  const startStation = useSelector(state => state.search.startStation);

  if (startStation.displayText) {
    return (
      <div style={{ display: 'inline' }} {...props}>
        <Chip
          label={`Station : ${startStation.displayText}`}
          color="primary"
          variant="outlined"
        />
      </div>
    );
  } else {
    return null;
  }
};

export default StationFilter;
