import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Elements } from 'react-stripe-elements';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { orderBy } from 'lodash';

import IsolatedMenu from '../../components/shared/IsolatedMenu';
import closeIcon from '../../assets/images/close.svg';
import CreditCardForm from '../../components/stripe/CreditCardForm';
import {
  fetchCreditCards,
  setDefaultCreditCard,
  deleteCreditCard
} from '../../store/actions/account';
import { useErrorToaster } from '../../utils/errorToaster';
import config from '../../config';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const PaymentMethodPage = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation(['account', 'payment_method']);
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [currentCard, setCurrentCard] = useState(null);
  const [isModalDeleteCardOpen, setModalDeleteCardOpen] = useState(false);
  const language = useSelector(state => state.app.language);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const creditCards = orderBy(
    account.creditCards,
    ['set_as_default_at'],
    ['desc']
  );

  useEffect(() => {
    getCreditCards();
  }, []);

  const getCreditCards = async () => {
    try {
      await dispatch(fetchCreditCards());
    } catch (error) {
      displayError(error);
    }
  };

  const setDefaultCreditCards = async cardId => {
    setAnchorEl(null);
    try {
      await dispatch(setDefaultCreditCard(cardId));
      getCreditCards();
      enqueueSnackbar(t('payment_method:default-payment-methods-success'), {
        variant: 'success'
      });
    } catch (error) {
      displayError(error);
    }
  };

  const deleteCreditCards = async cardId => {
    setAnchorEl(null);
    try {
      await dispatch(deleteCreditCard(cardId));
      getCreditCards();
      enqueueSnackbar(t('payment_method:delete-payment-methods-success'), {
        variant: 'success'
      });
    } catch (error) {
      displayError(error);
    }
  };

  const handleModalConfirmationCreditCards = cardId => {
    setCurrentCard(cardId);
    setModalDeleteCardOpen(true);
  };

  const cancelModalCard = cardId => {
    setModalDeleteCardOpen(false);
    setCurrentCard(null);
  };

  const confirmationDeleteCreditCards = cardId => {
    setModalDeleteCardOpen(false);
    deleteCreditCards(currentCard);
    setCurrentCard(null);
  };

  const handleReturn = () => {
    history.push(config.auth.rootUrl);
  };

  return (
    <Container maxWidth="md" className="card-form account-page-container">
      <div className="page-header">
        <Typography component="h1">
          {t('payment_method:payment-methods')}
        </Typography>

        <div className="close-button">
          <IconButton onClick={handleReturn}>
            <img src={closeIcon} alt="" width="30" height="30" />
          </IconButton>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="label-table-card" align="right">
                {t('payment_method:brand')}
              </TableCell>
              <TableCell className="label-table-card" align="right">
                {t('payment_method:country')}
              </TableCell>
              <TableCell className="label-table-card" align="right">
                {t('payment_method:code')}
              </TableCell>
              <TableCell className="label-table-card" align="right">
                {t('payment_method:expiration_time')}
              </TableCell>
              <TableCell className="label-table-card" align="right">
                {t('payment_method:options')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditCards.map((card, index) => (
              <TableRow key={card.id}>
                <TableCell align="right">{card.brand}</TableCell>
                <TableCell align="right">{card.country}</TableCell>
                <TableCell align="right">{`XXXX XXXX XXXX ${card.last4}`}</TableCell>
                <TableCell align="right">
                  {`${card.exp_month}/${card.exp_year}`}
                </TableCell>
                <TableCell align="right">
                  {index === 0 ? (
                    <CheckCircleOutlineIcon className="main-card-icon" />
                  ) : (
                    <div>
                      <IsolatedMenu
                        handleModalConfirmationCreditCards={
                          handleModalConfirmationCreditCards
                        }
                        setDefaultCreditCards={setDefaultCreditCards}
                        id={card.id}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <br></br>

      <div className="credit-card">
        <Elements locale={language}>
          <CreditCardForm />
        </Elements>
      </div>

      <Dialog
        open={isModalDeleteCardOpen}
        onClose={cancelModalCard}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('payment_method:modal-delete-payment-methods-title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('payment_method:modal-delete-payment-methods-content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelModalCard} color="primary">
            {t('common:cancel')}
          </Button>
          <Button
            onClick={confirmationDeleteCreditCards}
            color="primary"
            autoFocus
          >
            {t('common:confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PaymentMethodPage;
