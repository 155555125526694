import httpClient from '../httpClient';

export default {
  search({
    start_station_id,
    end_station_id,
    estimated_distance,
    rental_label_id,
    latitude,
    longitude,
    destination_label,
    destination_name,
    destination_gmaps_place_id,
    destination_formatted_address,
    destination_latitude,
    destination_longitude,
    page = 1,
    limit = 50,
    radius = 30,
    categories = [],
    options = [],
    startTime,
    endTime,
    gears_type,
    seats_count
  }) {
    return httpClient('v0.4').post('/vehicles/search', {
      search: {
        start_station_id,
        end_station_id,
        estimated_distance: estimated_distance
          ? Number(estimated_distance)
          : null,
        destination_label,
        destination_name,
        destination_gmaps_place_id,
        destination_formatted_address,
        destination_latitude,
        destination_longitude,
        rental_label_id,
        latitude,
        longitude,
        page,
        limit,
        radius,
        categories,
        options,
        gears_type,
        seats_count: seats_count ? Number(seats_count) : null,
        start_time: startTime,
        end_time: endTime
      }
    });
  },
  getOne(id) {
    return httpClient('v0.4').get(`/vehicles/${id}`);
  }
};
