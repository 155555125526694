import React from 'react';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { useSelector } from 'react-redux';

import PrivateRoute from '../components/routing/PrivateRoute';
import MemberMenu from '../components/nav/MemberMenu';
import BookingsPage from './account/BookingsPage';
import AccountDetailsPage from './account/AccountDetailsPage';
import FaqPage from './account/help/FaqPage';
import CguPage from './account/help/CguPage';
import PrivacyPage from './account/PrivacyPage';
import ContactPage from './account/help/ContactPage';
import PaymentMethodPage from './account/PaymentMethodPage';
import BookingDetailsPage from './account/BookingDetailsPage';
import config from '../config';

const AccountPage = ({ history }) => {
  const tenant = useSelector(state => state.tenant);

  const handleReturn = () => {
    history.push(config.auth.rootUrl);
  };

  return (
    <div className="account-page">
      <Drawer variant="permanent" anchor="left">
        <MemberMenu onClose={handleReturn} />
      </Drawer>

      <div className="body-container">
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/bookings`}
          tenant={tenant}
          component={BookingsPage}
        />
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/bookings/:id`}
          tenant={tenant}
          component={BookingDetailsPage}
        />
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/details`}
          tenant={tenant}
          component={AccountDetailsPage}
        />
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/payment-method`}
          tenant={tenant}
          component={PaymentMethodPage}
        />
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/help/faq`}
          tenant={tenant}
          component={FaqPage}
        />
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/help/cgu`}
          tenant={tenant}
          component={CguPage}
        />
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/privacy`}
          tenant={tenant}
          component={PrivacyPage}
        />
        <PrivateRoute
          exact
          path={`${config.auth.rootUrl}account/help/contact`}
          tenant={tenant}
          component={ContactPage}
        />
      </div>
    </div>
  );
};

export default withRouter(AccountPage);
