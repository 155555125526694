import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';

const OptionFilter = ({ handleDeleteOptions, onClick }) => {
  const { t } = useTranslation(['search']);
  const options = useSelector(state => state.search.options);

  if (options.length > 0) {
    return (
      <div style={{ display: 'inline' }}>
        {options.map(option => (
          <Chip
            key={`chip-option-${option}`}
            onClick={onClick}
            label={t(`search:${option}`)}
            onDelete={() => handleDeleteOptions(option)}
            color="primary"
            variant="outlined"
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default OptionFilter;
