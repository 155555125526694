import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import check from '../../../assets/images/check.svg'

function Step({ title, active, completed }) {
  const classnames = {
    step: true,
    active,
    completed
  };

  const completedIcon = (
    <div className="step-completed-icon">
      <img src={check} alt="" width="26" height="26" />
    </div>
  );

  return (
    <div className={classNames(classnames)}>
      <div className="step-name">{title}</div>
      {completed ? completedIcon : null}
    </div>
  );
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  completed: PropTypes.bool
};

export default Step;
