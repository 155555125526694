import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { useTermsAndConditions } from '../../hooks/termsAndConditions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const TermsDialog = ({ open, onClose }) => {
  const { t } = useTranslation(['common']);
  const termsAndConditions = useTermsAndConditions();

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const term = () => {
    try {
      return decodeURIComponent(termsAndConditions[0].content);
    } catch (e) {
      return;
    }
  };

  return (
    <Dialog
      scroll="body"
      open={open}
      onClose={onClose}
      aria-labelledby="terms-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="terms-dialog-title">
        {t('account:tos-title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {termsAndConditions[0] && termsAndConditions[0].content ? (
            <span
              dangerouslySetInnerHTML={{
                __html: term()
              }}
            ></span>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsDialog;
