import httpClient from '../httpClient';
import {
  inProgressStatuses,
  upcomingStatuses
} from './../../utils/rentalStatuses';

export default {
  searchCarpoolings({
    start_station_id,
    end_station_id,
    rental_label_id,
    start_time,
    end_time,
    categories,
    page,
    limit
  }) {
    return httpClient('v0.4').post('/carpooling_rentals/search', {
      search: {
        start_station_id,
        end_station_id,
        rental_label_id,
        start_time,
        end_time,
        categories,
        page,
        limit
      },
      page: 1,
      limit: 30
    });
  },
  getPassengers(rentalId) {
    return httpClient('v0.4').get(`/rentals/${rentalId}/passengers`);
  },
  getAllCarpoolings() {
    return httpClient('v0.4').get('/user/carpooling_rentals', {
      params: {
        page: 1,
        limit: 30,
        statuses: inProgressStatuses.concat(upcomingStatuses)
      }
    });
  },
  getAll() {
    return httpClient('v0.4').get('/user/rentals', {
      params: { page: 1, limit: 30 }
    });
  },
  getOne(id) {
    return httpClient('v0.4').get(`/user/rentals/${id}`);
  },
  createRental({
    vehicleId,
    stationId,
    destinationId,
    driverId,
    startTime,
    endTime,
    estimatedDistance,
    rentalLabelId,
    geotracking,
    carpooling,
    carpoolingDestination
  }) {
    return httpClient('v0.4').post('/user/rentals', {
      vehicle_id: vehicleId,
      driver_id: driverId,
      destination_id: destinationId,
      reservation_start_time: startTime,
      reservation_end_time: endTime,
      start_station_id: stationId,
      end_station_id: stationId,
      estimated_distance: estimatedDistance,
      rental_label_id: rentalLabelId,
      geotracking: geotracking,
      carpooling: carpooling,
      carpooling_destination: carpoolingDestination
    });
  },
  createPassenger(rentalId) {
    return httpClient('v0.4').post(`/user/rentals/${rentalId}/passengers`);
  },
  cancelRental(id) {
    return httpClient('v0.4').delete(`/user/rentals/${id}`);
  },
  updateRental({ id, startTime, endTime }) {
    return httpClient('v0.4').put(`/user/rentals/${id}`, {
      reservation_start_time: startTime,
      reservation_end_time: endTime
    });
  },
  updatePrivacyUsage({ id, geotracking }) {
    return httpClient('v0.4').put(`/user/rentals/${id}`, {
      rental: {
        geotracking: geotracking
      }
    });
  }
};
