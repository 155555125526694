import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const SeatsCountAutocomplete = ({ initialValue, onValueChanged }) => {
  const seatsCounts = ['all', '2', '4', '7'];
  const { t } = useTranslation(['search']);

  const [seats, setInputValue] = useState(initialValue);

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      onValueChanged({ seats });
    }
  }, [seats]);

  return (
    <div className="location-input">
      <Paper className="input-wrapper">
        <Autocomplete
          value={seats}
          id={`seats-count-${seats ? seats : 'unknown'}`}
          className="input-control"
          options={seatsCounts}
          getOptionLabel={option => t(`seats-count-${option}`)}
          onChange={(event, value) => setInputValue(value)}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              variant="standard"
            />
          )}
        />
      </Paper>
    </div>
  );
};

export default SeatsCountAutocomplete;
