import api from '../../utils/api';

export const CUSTOM_CONTENT_FETCHED = 'CUSTOM_CONTENT_FETCHED';

export function fetchCustomContent(type) {
  return async function (dispatch) {
    try {
      const content = await api.customContents.getContent(type);

      dispatch({
        type: CUSTOM_CONTENT_FETCHED,
        contentType: type,
        content
      });
    } catch (error) {
      throw error;
    }
  }
}
