import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import GuestRoute from './components/routing/GuestRoute';
import PrivateRoute from './components/routing/PrivateRoute';

import AuthenticationPage from './views/AuthenticationPage';
import LoginPage from './views/LoginPage';
import ForgotPasswordPage from './views/ForgotPasswordPage';
import ResetPasswordPage from './views/ResetPasswordPage';
import SignupPage from './views/SignupPage';
import WelcomePage from './views/signup/WelcomePage';
import BlockedPage from './views/signup/BlockedPage';
import WaitingPage from './views/signup/WaitingPage';
import AccountPage from './views/AccountPage';
import HomePage from './views/HomePage';
import LoginCallback from './views/LoginCallback';
import SearchPage from './views/SearchPage';
import NotFound from './views/NotFound';
import config from './config';

const Routes = ({ tenant }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const account = useSelector(state => state.account);

  const isUnAuthenticatedRediction = () => {
    if (tenant.platformType === 'private') {
      return <Redirect to={`${config.auth.rootUrl}login`} />;
    } else {
      return <HomePage />;
    }
  };

  const isAuthenticatedRediction = () => {
    if (account.isLoaded && account.serverStep === 'driver_blocked') {
      return <Redirect to={`${config.auth.rootUrl}waiting`} />;
    } else if (
      tenant.platformType === 'private' &&
      tenant.useRegistration &&
      account.isLoaded &&
      account.serverStep !== 'driver_ok'
    ) {
      return <Redirect to={`${config.auth.rootUrl}signup`} />;
    } else if (
      tenant.platformType === 'private' &&
      !tenant.useRegistration &&
      account.isLoaded &&
      account.serverStep !== 'driver_ok'
    ) {
      return <Redirect to={`${config.auth.rootUrl}waiting-for-validation`} />;
    } else {
      return <HomePage />;
    }
  };

  return (
    <div>
      <GuestRoute
        exact
        path={`${config.auth.rootUrl}auth`}
        component={AuthenticationPage}
      ></GuestRoute>

      <Route
        exact
        path={`${config.auth.rootUrl}login`}
        component={LoginPage}
      ></Route>
      <Route
        exact
        path={`${config.auth.rootUrl}forgot-password`}
        component={ForgotPasswordPage}
      ></Route>
      <Route
        exact
        path={`${config.auth.rootUrl}reset-password`}
        component={ResetPasswordPage}
      ></Route>
      <Route
        exact
        path={`${config.auth.rootUrl}signup/welcome`}
        component={WelcomePage}
      ></Route>
      <Route
        exact
        path={`${config.auth.rootUrl}waiting`}
        component={BlockedPage}
      ></Route>
      <Route
        exact
        path={`${config.auth.rootUrl}waiting-for-validation`}
        component={WaitingPage}
      ></Route>

      {tenant.useRegistration ? (
        <Route
          exact
          path={`${config.auth.rootUrl}signup`}
          component={SignupPage}
        ></Route>
      ) : null}

      {tenant.useSingleSignOn ? (
        <Route
          path={`${config.auth.rootUrl}implicit/callback`}
          component={LoginCallback}
        />
      ) : null}

      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}search`}
        tenant={tenant}
        component={SearchPage}
      ></PrivateRoute>

      <PrivateRoute
        path={`${config.auth.rootUrl}account`}
        component={AccountPage}
        tenant={tenant}
      ></PrivateRoute>

      <Route exact path={`${config.auth.rootUrl}`}>
        {!isAuthenticated
          ? isUnAuthenticatedRediction()
          : isAuthenticatedRediction()}
      </Route>
    </div>
  );
};

export default Routes;
