import { combineReducers } from 'redux';
import account from './account';
import app from './app';
import auth from './auth';
import categories from './categories';
import customContents from './customContents';
import termsAndConditions from './termsAndConditions';
import rentals from './rentals';
import search from './search';
import tenant from './tenant';
import vehicles from './vehicles';
import stations from './stations';
import rentalLabels from './rentalLabels';

export default combineReducers({
  account,
  app,
  auth,
  categories,
  customContents,
  termsAndConditions,
  rentals,
  search,
  tenant,
  vehicles,
  stations,
  rentalLabels
});
