import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import CategoryName from '../shared/CategoryName';
import Price from '../shared/Price';
import fuelIcon from '../../assets/images/vehicle/fuel-icon.svg';
import chargeIcon from '../../assets/images/vehicle/charge-icon.svg';
import { distance } from '../../utils/distanceHelper';

const VehicleCard = props => {
  const theme = useTheme();
  const tenant = useSelector(state => state.tenant);
  const { vehicle, price, distanceInMeters, location, ...rest } = props;
  const { t } = useTranslation(['common']);
  const licensePlate = vehicle.license_plate;
  const internalIdentifier = vehicle.internal_identifier;

  return (
    <div className="vehicule-card" {...rest}>
      <div className="image">
        {vehicle.thumbnail_url ? (
          <img src={vehicle.thumbnail_url} alt="" />
        ) : null}
        <div className="icon-fuel-type">
          {tenant.btob ? (
            <div className="level">
              {vehicle.box_fuel_level !== null
                ? `${vehicle.box_fuel_level}L`
                : vehicle.box_state_of_charge
                ? `${vehicle.box_state_of_charge}%`
                : null}
            </div>
          ) : null}
          <img
            src={vehicle.box_state_of_charge !== null ? chargeIcon : fuelIcon}
            alt=""
            width="30"
            height="30"
          />
        </div>
      </div>
      <div className="details">
        <div className="desc">
          <div className="category">
            {vehicle.category_id ? (
              <CategoryName categoryId={vehicle.category_id}></CategoryName>
            ) : null}
          </div>

          <div className="model">
            {vehicle.make} {vehicle.model} {vehicle.year}
          </div>
          {distanceInMeters > 0 ? (
            <div style={{ color: theme.palette.primary.main }}>
              {`${t('common:distance')} ${distance(
                distanceInMeters,
                tenant.distanceUnit
              )} ${tenant.distanceUnit}`}
            </div>
          ) : null}
          <div
            style={{ color: theme.palette.primary.main }}
            className={'distance'}
          >
            {licensePlate ? licensePlate : null}{' '}
            {internalIdentifier ? `#${internalIdentifier}` : null}
          </div>
        </div>
        {price ? (
          <Price cents={price.value_cents} currency={price.iso_code} />
        ) : null}
      </div>
    </div>
  );
};

export default VehicleCard;
