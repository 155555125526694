import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import HeaderFooterLayout from './HeaderFooterLayout';
import ContainerWithSidebar from './ContainerWithSidebar';
import Stepper from '../shared/Stepper';
import LineStepper from '../shared/LineStepper';

function MultiStepForm({ stepperTitle, steps, activeStep, components, completedSteps = [] }) {
  const sidebarStepper = (
    <Stepper title={stepperTitle} steps={steps} activeStep={activeStep} completedSteps={completedSteps} />
  );

  const stepCount = Object.keys(steps).length || 0;
  const currentIndex = Object.keys(steps).indexOf(activeStep);

  const lineStepper = (
    <LineStepper steps={stepCount} currentIndex={currentIndex} />
  );

  const $component = components && components[activeStep]? components[activeStep] : null;

  const mainContent = (
    <div className="multistep-form-content">
      <Container maxWidth="xs" className="multistep-form-container">
        <div className="mobile-stepper">
          {lineStepper}
        </div>
        <div className="step-container">
          {$component ? <$component /> : null /* eslint-disable-line */}
        </div>
      </Container>
    </div>
  );

  return (
    <div className="multistep-form">
      <HeaderFooterLayout>
        <ContainerWithSidebar
          sidebar={sidebarStepper}
          main={mainContent}
        />
      </HeaderFooterLayout>
    </div>
  );
}

MultiStepForm.propTypes = {
  stepperTitle: PropTypes.string,
  steps: PropTypes.object.isRequired,
  completedSteps: PropTypes.array,
  activeStep: PropTypes.string.isRequired,
  components: PropTypes.object.isRequired
};

export default MultiStepForm;
