import React from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  sendValidationCode,
  submitValidationCode,
  fetchUserData
} from '../../../store/actions/account';
import { useSnackbar } from 'notistack';
import MultiDigitInput from '../../shared/MultiDigitInput';
import EditPhoneNumberModal from './EditPhoneNumberModal';
import { useErrorToaster } from '../../../utils/errorToaster';
import config from '../../../config';
import { useModal } from '../../../utils/modal';

function PhoneNumber({ history }) {
  const { t } = useTranslation(['account', 'home', 'common', 'validation']);
  const editPhoneModal = useModal();
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const { enqueueSnackbar } = useSnackbar();
  const newPhoneNumber = useSelector(state => state.account.newPhoneNumber);
  const phoneNumber = useSelector(state => state.account.phoneNumber);

  let isSubmitting = false;

  const handleCodeSubmit = async (code, reset) => {
    isSubmitting = true;

    try {
      await dispatch(submitValidationCode(code));
      await dispatch(fetchUserData());
    } catch (error) {
      displayError(error);
      reset();
      isSubmitting = false;
    }
  };

  const resendCode = async () => {
    try {
      await dispatch(sendValidationCode(newPhoneNumber || phoneNumber));
      enqueueSnackbar(t('account:sms-code-sent'), { variant: 'success' });
    } catch (error) {
      displayError(error);
    }
  };

  const handleTermClick = () => {
    editPhoneModal.open();
  };

  return (
    <div className="phone-validation">
      <Typography component="h3">{t('account:sms-code-sent')}</Typography>

      <div className="code-recipient-number">
        {newPhoneNumber || phoneNumber}
      </div>

      <MultiDigitInput
        digits={6}
        onComplete={handleCodeSubmit}
        disabled={isSubmitting}
      />

      <div className="resend-code action-link" onClick={resendCode}>
        {t('account:resend-code')}
      </div>
      <div className="resend-code action-link" onClick={handleTermClick}>
        {t('account:resend-phone')}
      </div>

      <EditPhoneNumberModal
        open={editPhoneModal.isOpen}
        onClose={editPhoneModal.close}
      />
    </div>
  );
}

export default withRouter(PhoneNumber);
