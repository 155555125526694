import api from '../../utils/api';

export const VEHICLE_FETCHED = 'VEHICLE_FETCHED';

export function fetchVehicleById(id) {
  return async function(dispatch) {
    try {
      const response = await api.vehicles.getOne(id);
      dispatch({
        type: VEHICLE_FETCHED,
        id: id,
        vehicles: response.vehicle
      });

      return response.vehicle;
    } catch (error) {
      throw error;
    }
  };
}
