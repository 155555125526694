import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStationById } from '../store/actions/stations';

export const useStationLoader = stationId => {
  const dispatch = useDispatch();
  const [station, setStation] = useState({});
  const stations = useSelector(state => state.stations.list);

  useEffect(() => {
    const fetchDataFromServer = id => {
      return dispatch(fetchStationById(id));
    };

    const loadStation = async () => {
      if (stationId) {
        let stationInStore = stations
          ? find(stations, { id: stationId })
          : null;

        if (!stationInStore) {
          const newStation = await fetchDataFromServer(stationId);
          return setStation(newStation);
        } else {
          return setStation(stationInStore);
        }
      }
    };
    loadStation();
  }, [stationId, setStation]);

  return { station };
};
