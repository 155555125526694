import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchTermsAndConditions } from '../store/actions/termsAndConditions';

export const useTermsAndConditions = () => {
  const dispatch = useDispatch();
  const termsAndConditions = useSelector(
    state => state.termsAndConditions.list
  );

  useEffect(() => {
    if (!termsAndConditions) {
      dispatch(fetchTermsAndConditions());
    }
  }, [termsAndConditions]);

  return termsAndConditions ? termsAndConditions : {};
};
