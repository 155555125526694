import React, { useState, useEffect } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import api from '../../utils/api';
import CardModal from '../stripe/CardModal';
import { fetchUserData, confimStripeCard } from '../../store/actions/account';

function CreditCard({ stripe }) {
  const { t } = useTranslation(['account']);
  const [setupIntent, setSetupIntent] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    openIntent();
  }, []);

  const openIntent = async () => {
    try {
      const response = await api.user.createStripeSetupIntents();
      setSetupIntent(response.stripe_setup_intent);
    } catch (error) {
      throw error;
    }
  };

  const confirmCard = async confirmMethod => {
    try {
      const response = await dispatch(
        confimStripeCard(setupIntent.id, confirmMethod.payment_method, false)
      );
      enqueueSnackbar(t('payment_method:add-payment-methods-success'), {
        variant: 'success'
      });
      await dispatch(fetchUserData());
    } catch (error) {
      throw error;
    }
  };

  const onResult = result => {
    if (result.error && result.error.message) {
      enqueueSnackbar(result.error.message, { variant: 'error' });
    } else if (result && result.setupIntent) {
      confirmCard(result.setupIntent);
    } else {
      enqueueSnackbar('error', { variant: 'error' });
    }
  };

  return (
    <div>
      <CardModal
        isModal={false}
        isMobile={isMobile}
        setupIntent={setupIntent}
        open={null}
        onClose={null}
        onResult={onResult}
      />
    </div>
  );
}

export default injectStripe(CreditCard);
