import tenantLogo from '../assets/images/yugo-logo.png';
import pinSmallMap from '../assets/images/custom/yugo/yugo-pin-small.svg';
import pinBigMap from '../assets/images/custom/yugo/yugo-pin-big.svg';
import favicon from '../assets/images/custom/yugo/favicon.png';

const yugo = {
  tenant: {
    name: 'Yugo',
    politique_de_confidentialite_url:
      'https://www.yugo.nc/politique-de-confidentialite/',
    assets: {
      favicon: favicon,
      logo: tenantLogo,
      pinSmallMap: pinSmallMap,
      pinBigMap: pinBigMap
    }
  },
  typography: {
    fontFamily: [
      'SofiaPro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      light: '#FF4640',
      main: '#FF4640',
      dark: '#FF4640'
    },
    secondary: {
      main: '#FF4640'
    },
    background: {
      default: '#ffffff'
    }
  }
};

export default yugo;
