import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { DialogContentText } from '@material-ui/core';

import config from '../../config';
import closeIcon from '../../assets/images/close.svg';
import { useDispatch } from 'react-redux';
import AnimatedCheckmark from '../../components/shared/AnimatedCheckmark';
import { sendDataRequest } from '../../store/actions/account';

const PrivacyPage = ({ history }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(false);
  }, []);

  const handleReturn = () => {
    history.push(config.auth.rootUrl);
  };

  const handleSubmit = async () => {
    try {
      await dispatch(sendDataRequest());
      setSuccess(true);
    } catch (error) {}
  };

  const successContent = () => {
    return (
      <div>
        <AnimatedCheckmark />
        <DialogContentText>
          {t('account:privacy-content-success')}
        </DialogContentText>
      </div>
    );
  };

  return (
    <div>
      <Container maxWidth="md" className="account-page-container">
        <div className="custom-content">
          <div className="page-header">
            <Typography component="h1">{t('account:privacy-title')}</Typography>

            <div className="close-button">
              <IconButton onClick={handleReturn}>
                <img src={closeIcon} alt="" width="30" height="30" />
              </IconButton>
            </div>
          </div>

          {isSuccess ? (
            successContent()
          ) : (
            <div>
              <div>{t('account:privacy-content')}</div>
              <br></br>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="primary"
              >
                {t('account:privacy-button')}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPage;
