import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';

import { useErrorToaster } from '../../../utils/errorToaster';
import closeIcon from '../../../assets/images/close.svg';
import api from '../../../utils/api';
import { useVehicleLoader } from '../../../hooks/vehicleLoader';
import AskPrivacyUsage from '../../bookings/AskPrivacyUsage';
import { useStationLoader } from '../../../hooks/stationLoader';
import Photo from '../../vehicles/VehicleDetails/Photo';
import CarpoolingInfo from '../../rentals/carpoolings/CarpoolingInfo';
import CancelBookingModal from '../../bookings/CancelBookingModal';
import ModifyBookingModal from '../../bookings/ModifyBookingModal';
import { useModal } from '../../../utils/modal';
import { rentalDateLongFormat } from '../../../utils/variables';
import { updatePrivacyUsage } from '../../../store/actions/rentals';

import config from '../../../config';
import _ from 'lodash';
import {
  inProgressStatuses,
  closedStatuses,
  upcomingStatuses
} from './../../../utils/rentalStatuses';
import { duration } from '../../../utils/dateHelper';
import { distance } from '../../../utils/distanceHelper';
import {
  initCancellationMinimalDelay,
  initBoundsModificationMinimalDelay
} from '../../../store/selectors/tenant';
import Price from '../../shared/Price';
import CategoryName from '../../shared/CategoryName';
import ElectricOptions from '../VehicleDetails/ElectricOptions';
import Options from '../VehicleDetails/Options';
import Equipments from '../VehicleDetails/Equipments';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px;',
    width: '100%',
    marginBottom: '20px'
  },
  options: {
    marginBottom: '60px',
    marginTop: '60px'
  }
}));

const VehicleBody = ({ history }) => {
  const { t } = useTranslation(['booking', 'common', 'search']);
  const displayError = useErrorToaster();
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.tenant);
  // const rental = useSelector(state => state.search.rental);
  const userId = useSelector(state => state.account.id);
  const btob = tenant.btob;

  const useEstimatedDistance = tenant.useEstimatedDistance;
  const useEstimatedDestinationField = tenant.useEstimatedDestinationField;

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [rental, setRental] = useState(null);
  const [driver, setDriver] = useState(null);
  const [geotracking, setGeotracking] = useState(null);
  const [passengers, setPassengers] = useState([]);
  const { vehicle } = useVehicleLoader(rental ? rental.vehicle_id : null);
  const { station } = useStationLoader(rental ? rental.start_station_id : null);
  const language = useSelector(state => state.app.language);
  const cancellationMinimalDelay = useSelector(initCancellationMinimalDelay);
  const boundsModificationMinimalDelay = useSelector(
    initBoundsModificationMinimalDelay
  );
  // const btob = tenant.btob;

  const modifyModal = useModal();
  const cancelModal = useModal();
  const modalTracking = useModal();

  useEffect(() => {
    const loadData = async () => {
      const rentalResponse = await api.rentals.getOne(id);
      const rental = rentalResponse.rental;
      const userResponse = await api.user.getProfile(rental.driver_id);
      const driver = userResponse.user;
      const passengersResponse = await api.rentals.getPassengers(rental.id);
      const passengers = passengersResponse.passengers;

      setDriver(driver);
      setRental(rental);
      setPassengers(passengers);
      setGeotracking(rental.geotracking);
      setIsLoaded(true);
    };

    loadData();
  }, [id]);

  const handleClose = () => {
    history.push(`${config.auth.rootUrl}account/bookings`);
  };

  const handleChangeSuccess = () => {
    enqueueSnackbar(t('booking:modification-confirmed'), {
      variant: 'success'
    });
    handleClose();
  };

  const handleCancelSuccess = () => {
    enqueueSnackbar(t('booking:cancellation-confirmed'), {
      variant: 'success'
    });
    handleClose();
  };

  const isCancellable = () => {
    if (rental.driver_id !== userId) return false;
    return (
      moment(rental.reservation_start_time) > cancellationMinimalDelay &&
      upcomingStatuses.indexOf(rental.status) > -1
    );
  };

  const geotrackingUsageWithStatus = () => {
    return ['in_progress'].includes(rental.status);
  };

  const canRefusePrivacyUsage = () => {
    if (rental.driver_id !== userId) return false;
    if (
      geotrackingUsageWithStatus() &&
      tenant.usePrivacyUsageTimeSlot &&
      vehicle.geotracking_privacy_usage &&
      geotracking
    )
      return true;
    return false;
  };

  const canActiveGeotracking = () => {
    if (rental.driver_id !== userId) return false;
    if (
      geotrackingUsageWithStatus() &&
      tenant.usePrivacyUsageTimeSlot &&
      vehicle.geotracking_privacy_usage &&
      !geotracking
    )
      return true;
    return false;
  };

  const isEditable = () => {
    if (rental.driver_id !== userId) return false;
    return (
      moment(rental.reservation_start_time) > boundsModificationMinimalDelay &&
      upcomingStatuses.indexOf(rental.status) > -1
    );
  };

  const handleCloseGeotracking = async close => {
    return;
  };

  const handleGeotracking = async () => {
    setGeotracking(!geotracking);
    handlePrivacyUsage(!geotracking);
  };

  const handlePrivacyUsage = async currentGeotracking => {
    try {
      let responseRental = await dispatch(
        updatePrivacyUsage({
          id: rental.id,
          geotracking: currentGeotracking
        })
      );

      enqueueSnackbar(t('booking:geotracking-updated-confirmed'), {
        variant: 'success'
      });
      setRental(responseRental);
    } catch (error) {
      displayError(error);
    }
  };

  const rentalDuration = () => {
    return duration(
      rental.reservation_start_time,
      rental.reservation_end_time,
      language
    );
  };

  return (
    <div className="vehicle-booking booking-details">
      <Container maxWidth="md" className="account-page-container">
        <Card className={classes.root}>
          {isLoaded ? (
            <div className="page-header">
              {rental && rental.driver_id !== userId ? (
                <Typography component="h1">
                  {t('booking:your-carpooling')}
                </Typography>
              ) : (
                <Typography component="h1">
                  {t('booking:your-booking')}
                </Typography>
              )}

              <div className="close-button">
                <IconButton onClick={handleClose}>
                  <img src={closeIcon} alt="" width="30" height="30" />
                </IconButton>
              </div>
            </div>
          ) : null}
          {isLoaded && vehicle && station && rental ? (
            <div>
              <Photo image={vehicle.photos ? vehicle.photos[0] : null} />

              <div className="sections">
                <div className="section vehicle-summary">
                  <div className="category">
                    {vehicle.category_id ? (
                      <CategoryName
                        categoryId={vehicle.category_id}
                      ></CategoryName>
                    ) : null}
                  </div>
                  <div className="model">
                    {vehicle.make} {vehicle.model} {vehicle.year}
                  </div>
                  <div className={classes.options}>
                    <Options
                      doors={vehicle.doors_count}
                      seats={vehicle.seats_count}
                      fuel={vehicle.fuel_type}
                      transmission={vehicle.gears_type}
                    />
                  </div>
                </div>

                <div className="section flex-row">
                  <div>
                    <div className="title">
                      {t('booking:internal_identifier')}
                    </div>
                    <div className="section-content">
                      {vehicle.internal_identifier}
                    </div>
                  </div>
                  <div>
                    <div className="title">{t('booking:plate-number')}</div>
                    <div className="section-content">
                      {vehicle.license_plate}
                    </div>
                  </div>
                </div>

                <div className={driver && driver.id === userId ? 'hidden' : ''}>
                  <CarpoolingInfo driver={driver} rental={rental} />
                </div>

                {vehicle.fuel_type === 'ELECTRIC' &&
                upcomingStatuses.indexOf(rental.status) > -1 ? (
                  <div className="section vehicle-summary">
                    <div className="title">{t('booking:vehicle-soc')}</div>
                    <div className="section-content">
                      <ElectricOptions
                        unitAutonomy={tenant.distanceUnit}
                        stateOfCharge={vehicle.box_state_of_charge}
                        chargingStatus={vehicle.box_charging_status}
                        autonomy={vehicle.box_autonomy}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="section flex-row">
                  <div>
                    <div className="title">{t('booking:start')}</div>
                    <div className="date">
                      {moment(rental.reservation_start_time).format(
                        rentalDateLongFormat
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="title">{t('booking:end')}</div>
                    <div className="date">
                      {moment(rental.reservation_end_time).format(
                        rentalDateLongFormat
                      )}
                    </div>
                  </div>
                </div>

                {tenant.btob && tenant.useCarpooling && rental.carpooling ? (
                  <div className="section">
                    <div className="title">{t('booking:passengers')}</div>
                    <div className="section-content">
                      {passengers.map((passenger, i) => (
                        <p key={i}>
                          {passenger.first_name} {passenger.last_name}
                        </p>
                      ))}
                    </div>
                  </div>
                ) : null}

                <div className="hidden section">
                  <div className="title">{t('booking:included-distance')}</div>
                  <div className="section-content">
                    XXX Km/{t('common:day')}
                  </div>
                </div>

                <div className="section flex-row">
                  <div>
                    <div className="title">{t('booking:duration')}</div>
                    <div className="section-content">{rentalDuration()}</div>
                  </div>
                  {rental.estimated_distance ? (
                    <div>
                      <div className="title">
                        {t('booking:estimated-distance')}
                      </div>
                      <div className="section-content">
                        {`${rental.estimated_distance} ${rental.distance_unit}`}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div
                  className={
                    rental.distance &&
                    closedStatuses.indexOf(rental.status) > -1
                      ? 'section'
                      : 'hidden'
                  }
                >
                  <div className="title">{t('booking:distance')}</div>
                  <div className="section-content">
                    {rental.distance} {rental.distance_unit}
                  </div>
                </div>

                {rental.price && (
                  <div
                    className={
                      closedStatuses.indexOf(rental.status) > -1
                        ? 'section'
                        : 'hidden'
                    }
                  >
                    <div className="title">{t('booking:price')}</div>
                    <div className="section-content">
                      <Price
                        cents={rental.price.value_cents}
                        currency={rental.price.iso_code}
                      />
                    </div>
                  </div>
                )}

                <div
                  className={
                    Array.isArray(vehicle.options) && vehicle.options.length > 0
                      ? 'section'
                      : 'hidden'
                  }
                >
                  <div className="title">{t('booking:equipments')}</div>
                  <div className="section-content">
                    <Equipments equipments={vehicle.options} />
                  </div>
                </div>

                {(vehicle.power && vehicle.power != 0) ||
                vehicle.payload_capacity ||
                vehicle.volume ? (
                  <div className="section flex-row">
                    {vehicle.power && vehicle.power != 0 ? (
                      <div>
                        <div className="title">{t('booking:power')}</div>
                        <div className="section-content">{vehicle.power}</div>
                      </div>
                    ) : null}

                    {vehicle.payload_capacity ? (
                      <div>
                        <div className="title">
                          {t('booking:payload_capacity')}
                        </div>
                        <div className="section-content">
                          {vehicle.payload_capacity}
                        </div>
                      </div>
                    ) : null}

                    {vehicle.volume ? (
                      <div>
                        <div className="title">{t('booking:volume')}</div>
                        <div className="section-content">{vehicle.volume}</div>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className={station.address ? 'section' : 'hidden'}>
                  <div className="title">
                    {btob ? station.name : t('booking:address')}
                  </div>
                  <div className="section-content">{station.address}</div>
                </div>

                {useEstimatedDistance &&
                useEstimatedDestinationField &&
                rental &&
                rental.destination ? (
                  <div className="section">
                    <div className="title">{t('search:destination')}</div>
                    <div className="section-content">
                      {rental.destination.formatted_address}
                    </div>
                    <div className="section-content">
                      {rental.destination.label}
                    </div>
                  </div>
                ) : null}

                <div className={vehicle.description ? 'section' : 'hidden'}>
                  <div className="title">{t('booking:description')}</div>
                  <div className="section-content">{vehicle.description}</div>
                </div>
              </div>

              {canRefusePrivacyUsage() ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Button
                      className={'booking-actions'}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={modalTracking.open}
                    >
                      {t('booking:refuse-tracking')}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              {canActiveGeotracking() ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Button
                      className={'booking-actions'}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={modalTracking.open}
                    >
                      {t('booking:active-tracking')}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    className={isEditable() ? 'booking-actions' : 'hidden'}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={modifyModal.open}
                  >
                    {t('booking:modify-booking')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    className={
                      isCancellable() ? 'cancel-reservation-button' : 'hidden'
                    }
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={cancelModal.open}
                  >
                    {t('booking:cancel-booking')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : null}
        </Card>
      </Container>

      {isLoaded ? (
        <div>
          <AskPrivacyUsage
            handleGeotracking={handleGeotracking}
            handleCloseGeotracking={handleCloseGeotracking}
            buttonSubmitText={
              geotracking
                ? t('booking:confirm-private-usage')
                : t('booking:active-tracking')
            }
            open={modalTracking.isOpen}
            onClose={modalTracking.close}
          />
          <ModifyBookingModal
            open={modifyModal.isOpen}
            onClose={modifyModal.close}
            rental={rental}
            onSuccess={handleChangeSuccess}
          />
          <CancelBookingModal
            open={cancelModal.isOpen}
            onClose={cancelModal.close}
            rentalId={id}
            onSuccess={handleCancelSuccess}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(VehicleBody);
