import api from '../../utils/api';

export const RENTAL_LABELS_FETCHED = 'RENTAL_LABELS_FETCHED';

export function fetchRentalLabels() {
  return async function(dispatch) {
    try {
      const response = await api.rentalLabels.getAll();

      dispatch({
        type: RENTAL_LABELS_FETCHED,
        rental_labels: response.rental_labels
      });
    } catch (error) {
      throw error;
    }
  };
}
