import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import HeaderFooterLayout from '../../components/layout/HeaderFooterLayout';
import config from '../../config';
import { useCustomContent } from '../../hooks/customContent';

function BlockedPage({ history }) {
  const { t } = useTranslation(['common']);
  const page = useCustomContent('contact');

  return (
    <div className="blocked-page">
      <HeaderFooterLayout>
        <div className="content-wrapper">
          <div className="message">
            <p>{t('account:account-blocked')}</p>
            {page && page.content ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(page.content)
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </HeaderFooterLayout>
    </div>
  );
}

export default withRouter(BlockedPage);
