import React from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import BookingHistoryListItem from './BookingHistoryListItem';
import config from './../../../config';

const BookingHistoryList = ({ history, historyByMonth }) => {
  const handleBookingClick = id => {
    history.push(`${config.auth.rootUrl}account/bookings/${id}`);
  };

  return (
    <div className="bookings-list">
      {Object.keys(historyByMonth).map(month => (
        <div key={`month-${month}`} className="bookings-list-group">
          <div className="list-name">
            {moment(`${month}-15`).format('MMMM YYYY')}
          </div>

          <div className="list-items">
            {historyByMonth[month].map(booking => (
              <BookingHistoryListItem
                key={`booking-${booking.id}`}
                startTime={booking.reservation_start_time}
                endTime={booking.reservation_end_time}
                vehicleId={booking.vehicle_id}
                price={booking.price}
                status={booking.status}
                onClick={() => handleBookingClick(booking.id)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default withRouter(BookingHistoryList);
