import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { dateInputFormat } from '../../../utils/variables';

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;
  const tenant = useSelector(state => state.tenant);

  const maskFormat = () => {
    if (tenant.dateFormat === dateInputFormat)
      return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
    else return [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  };

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskFormat}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
};

function DateMaskedInput({
  required = false,
  placeholder = '',
  onChange,
  value = '',
  name = '',
  error = false,
  helperText = null
}) {
  const tenant = useSelector(state => state.tenant);
  const [inputValue, setInputValue] = useState(value);

  const handleChange = e => {
    setInputValue(e.target.value);

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  return (
    <TextField
      required={required}
      name={name}
      variant="outlined"
      margin="none"
      error={!!error}
      helperText={!!helperText ? helperText : null}
      fullWidth
      label={placeholder}
      InputProps={{
        inputComponent: TextMaskCustom,
        value: inputValue,
        onChange: handleChange,
        placeholder: tenant.dateFormat
      }}
    />
  );
}
export default DateMaskedInput;
