import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { google, GoogleApiWrapper } from 'google-maps-react';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MyLocationIcon from '@material-ui/icons/MyLocation';

import config from '../../config';

const LocationInput = ({ google, initialValue, onValueChanged, language }) => {
  const { t } = useTranslation(['search']);
  const autocompleteRef = useRef(null);
  const tenant = useSelector(state => state.tenant);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(initialValue.displayText);

  useEffect(() => {
    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      componentRestrictions: { country: tenant.iso3166CountryCode }
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      onValueChanged({
        displayText: place.formatted_address,
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),
        name: place.name,
        place_id: place.place_id,
        formatted_address: place.formatted_address
      });

      setInputValue(place.formatted_address);
    });

    autocompleteRef.current = autocomplete;
    return () => {
      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, [google, onValueChanged]);

  const handleFocus = event => {
    event.nativeEvent.target.select();
  };

  const onPlaceChanged = autocomplete => {
    const place = autocomplete.getPlace();
    setInputValue(place.formatted_address);
  };

  return (
    <div className="location">
      <Paper className="input-wrapper">
        <IconButton>
          <MyLocationIcon />
        </IconButton>
        <InputBase
          id="location-input"
          className="input-control"
          value={inputValue}
          onFocus={handleFocus}
          inputRef={inputRef}
          onChange={e => setInputValue(e.target.value)}
        />
      </Paper>
    </div>
  );
};

export default GoogleApiWrapper(props => ({
  apiKey: config.google.mapsApiKey,
  language: props.language
}))(LocationInput);
