import React, { createRef, useState } from 'react';
import classNames from 'classnames';

import UploadProgress from './UploadProgress';

function FileUploadIcon({
  children,
  icon,
  className,
  onFileSelected,
  accept = '*'
}) {
  const fileInputRef = createRef();

  const [state, setState] = useState({
    isUploading: false,
    isCompleted: false,
    progress: 0
  });

  const openFileDialog = () => {
    if (!state.isUploading) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelection = e => {
    const files = e.target.files;

    if (files[0] && typeof onFileSelected === 'function') {
      onFileSelected(files[0], {
        setStarted,
        setProgress,
        setCompleted,
        resetState
      });
    }
  };

  const setProgress = value => {
    setState({
      ...state,
      progress: value
    });
  };

  const resetState = () => {
    setState({
      isUploading: false,
      isCompleted: false,
      progress: 0
    });
  };

  const setStarted = () => {
    setState({
      ...state,
      progress: 0,
      isUploading: true
    });
  };

  const setCompleted = () => {
    setState({
      ...state,
      progress: 100,
      isUploading: false,
      isCompleted: true
    });
  };

  return (
    <div
      className={classNames({
        [className]: !!className,
        'file-upload-icon': true,
        uploading: state.isUploading,
        'upload-success': state.isCompleted
      })}
      onClick={openFileDialog}
    >
      {icon ? <div className="image">{icon}</div> : null}
      {!state.isUploading ? <div className="label">{children}</div> : null}
      {state.isUploading ? <UploadProgress progress={state.progress} /> : null}
      <input
        ref={fileInputRef}
        className="file-input"
        type="file"
        accept={accept}
        onChange={handleFileSelection}
      />
    </div>
  );
}

export default FileUploadIcon;
