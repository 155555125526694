import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { cancelRental } from '../../store/actions/rentals';
import { useErrorToaster } from '../../utils/errorToaster';

function CancelBookingModal({ open, onClose, rentalId, onSuccess }) {
  const { t } = useTranslation(['common', 'booking']);
  const dispatch = useDispatch();
  const displayError = useErrorToaster();

  const [isProcessing, setProcessing] = useState(false);

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleConfirm = async () => {
    setProcessing(true);

    try {
      await dispatch(cancelRental(rentalId));

      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      handleClose();
    } catch (error) {
      displayError(error);
      setProcessing(false);
    }
  };

  return (
    <div className="cancel-booking-modal">
      <Dialog
        open={open}
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="booking-dialog-title"
      >
        <DialogTitle id="booking-dialog-title">
          {t('booking:cancellation-modal-title')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {t('booking:cancellation-modal-line1')}
            <br />
            {t('booking:cancellation-modal-line2')}
          </DialogContentText>
          <DialogContentText>
            {t('booking:confirm-cancellation-text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('common:back')}</Button>

          <Button
            type="submit"
            color="primary"
            disabled={isProcessing}
            onClick={handleConfirm}
          >
            {t('booking:confirm-cancellation-cta')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CancelBookingModal;
