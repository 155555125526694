import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Price from '../../shared/Price';
import { useVehicleLoader } from '../../../hooks/vehicleLoader';
import { rentalDateFormat } from '../../../utils/variables';

const BookingHistoryListItem = ({
  startTime,
  endTime,
  vehicleId,
  price,
  status,
  onClick
}) => {
  const formattedStartTime = moment(startTime).format(rentalDateFormat);
  const formattedEndTime = moment(endTime).format(rentalDateFormat);
  const { t } = useTranslation(['booking']);
  const { vehicle } = useVehicleLoader(vehicleId);
  const theme = useTheme();

  if (vehicle) {
    return (
      <div className="list-item" onClick={onClick}>
        <Grid container spacing={2}>
          <Grid item xs={4} className="date">
            {formattedStartTime}
          </Grid>
          <Grid item xs={4} className="date">
            {formattedEndTime}
          </Grid>
          {price && (
            <Grid
              item
              xs={4}
              className="cost"
              style={{ color: theme.palette.primary.main }}
            >
              <Price cents={price.value_cents} currency={price.iso_code} />
            </Grid>
          )}
        </Grid>
        <div className="vehicule-name">
          {vehicle.make} {vehicle.model} ({t('booking:' + status)})
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default BookingHistoryListItem;
