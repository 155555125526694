import api from '../../utils/api';

export const STATION_FETCHED = 'STATION_FETCHED';
export const STATIONS_FETCHED = 'STATIONS_FETCHED';

export function fetchStationById(id) {
  return async function(dispatch) {
    try {
      const response = await api.stations.getOne(id);

      dispatch({
        type: STATION_FETCHED,
        id: id,
        stations: response.station
      });

      return response.station;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchStations() {
  return async function(dispatch) {
    try {
      const response = await api.stations.getAll();

      dispatch({
        type: STATIONS_FETCHED,
        stations: response.stations
      });
    } catch (error) {
      throw error;
    }
  };
}
