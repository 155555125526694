import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { createMuiTheme } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import config from './config';
import './utils/validationMethods';
import CenteredLoader from './components/shared/CenteredLoader';
import { fetchTenant } from './store/actions/tenant';
import { fetchRegistrationStep } from './store/actions/tenant';
import { createGuestToken } from './store/actions/auth';

import openfleet from './themes/openfleet';
import koolicar from './themes/koolicar';
import yugo from './themes/yugo';
import lerat from './themes/lerat';
import ios from './themes/ios';

import MainComponent from './MainComponent';

function Frontoffice() {
  const dispatch = useDispatch();
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const tenant = useSelector(state => state.tenant);
  const themes = {
    openfleet: openfleet,
    koolicar: koolicar,
    lerat: lerat,
    yugo: yugo,
    ios: ios
  };
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const theme = createMuiTheme(
    themes[tenant.theme || 'openfleet'] || themes['openfleet']
  );
  const [token, SetToken] = useState(
    localStorage.getItem(config.auth.accessTokenKey)
  );
  const getAccessToken = async () => {
    if (!token) {
      const tokenResponse = await dispatch(createGuestToken());
      SetToken(tokenResponse);
    }
  };

  Yup.addMethod(Yup.mixed, 'validDate', function(message) {
    return Yup.mixed().test('validDate', message, function(value) {
      if (value === undefined || value === null) return false;
      return moment(
        value,
        tenant.dateFormat.replace(/\//g, '-'),
        true
      ).isValid();
    });
  });

  const loadTenant = async () => {
    try {
      await dispatch(fetchTenant());
      await dispatch(fetchRegistrationStep());
      setHasAccess(true);
      setIsLoading(false);
    } catch (error) {
      setHasAccess(false);
      setIsLoading(false);
      console.error('Error while loading tenant.');
      console.error(error);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    if (token) {
      loadTenant();
    }
  }, [token]);

  return hasAccess ? (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={3}
        preventDuplicate
        action={key => (
          <IconButton
            className="alert-delete-button"
            onClick={onClickDismiss(key)}
            aria-label="delete"
          >
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <div className="App tenant-theme">
          <CssBaseline />
          {isLoading ? (
            <CenteredLoader />
          ) : (
            <Router>
              <MainComponent theme={theme} />
            </Router>
          )}
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  ) : null;
}

export default Frontoffice;
