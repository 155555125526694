import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import searchIcon from '../../assets/images/search.svg';

const SearchInput = ({ value, onClick }) => {
  const { t } = useTranslation(['search']);
  const tenant = useSelector(state => state.tenant);

  return (
    <div className="search-input" onClick={onClick}>
      <Paper className="search-input-wrapper">
        <div className="search-icon">
          <img src={searchIcon} alt="" width="30" height="30" />
        </div>
        <InputBase
          className="search-input-control"
          placeholder={t('search:search-vehicule')}
          inputProps={{ 'aria-label': t('search:search-vehicule') }}
          value={value}
          disabled={true}
        />
      </Paper>
    </div>
  );
};

export default SearchInput;
