import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useCustomContent } from '../../hooks/customContent';
import { useTranslation } from 'react-i18next';

function RentalTermsModal({ open, onClose }) {
  const { t } = useTranslation(['common', 'booking']);
  const page = useCustomContent('faq');

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div className="help-modal">
      <Dialog
        open={open}
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('booking:rental-terms')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {page.content ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(page.content)
                }}
              ></span>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('common:close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RentalTermsModal;
