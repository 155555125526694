import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { useErrorToaster } from '../../../utils/errorToaster';
import { savePhone } from '../../../store/actions/account';

const EditPhoneNumberModal = ({ open, onClose }) => {
  const { t } = useTranslation(['account']);
  const dispatch = useDispatch();
  const newPhoneNumber = useSelector(state => state.account.newPhoneNumber);
  const phoneNumber = useSelector(state => state.account.phoneNumber);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const displayError = useErrorToaster();

  const initialValues = {
    phoneNumber: newPhoneNumber || phoneNumber
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required(t('validation:required'))
  });

  const handleFormSubmit = async ({ phoneNumber }, { setSubmitting }) => {
    try {
      await dispatch(savePhone(phoneNumber));
      enqueueSnackbar(t('account:code-resend-success'), { variant: 'success' });
      onClose();
    } catch (error) {
      setSubmitting(false);
      displayError(error);
    }
  };

  return (
    <Dialog
      className="edit-number-modal"
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={isMobile ? 'sm' : 'xs'}
    >
      <div className="number-modal">
        <Typography component="h1">{t('account:resend-phone')}</Typography>

        <Formik
          classNameclassName="edit-number-modal"
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            handleChange,
            isSubmitting,
            errors,
            touched,
            setFieldValue
          }) => (
            <Form>
              <div className="form-group">
                <TextField
                  name="phoneNumber"
                  type="tel"
                  placeholder={t('account:phone-number')}
                  variant="outlined"
                  margin="none"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  error={touched.phoneNumber && !!errors.phoneNumber}
                  fullWidth
                />
              </div>

              <div className="button-group">
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  {t('common:next')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default EditPhoneNumberModal;
//
