import React from 'react';
import Rollbar from 'rollbar';
import { withTranslation } from 'react-i18next';

import config from './config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      rollbar: new Rollbar({
        accessToken: config.rollbar.rollbarAccessToken,
        environment: config.rollbar.rollbarEnv,
        captureUncaught: true,
        captureUnhandledRejections: true
      })
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.state.rollbar.info(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <h2 className="error-message-rollbar">
          {this.props.t('common:error-default')}
        </h2>
      );
    }
    return this.props.children;
  }
}

export default withTranslation('common')(ErrorBoundary);
