import React, { useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function PasswordInput({ placeholder = '', onChange, value = '', name = '', error = false, helperText = null, id = 'password-input' }) {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  return (
    <FormControl fullWidth>
      <OutlinedInput
        id={id}
        name={name}
        type={passwordVisibility ? 'text' : 'password'}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        error={!!error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
            >
              {passwordVisibility ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && helperText ? <FormHelperText error={true}>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}

export default PasswordInput;
