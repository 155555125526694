import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function LineStepper({ steps, currentIndex }) {
  const elements = [];

  for (let i = 0; i < steps ; i += 1) {
    elements.push(
      <div
        key={`step-${i}`}
        className={classNames({ step: true, active: i <= currentIndex })}>
      </div>
    );
  }

  return (
    <div className="line-stepper">
      <div className="steps">
        {elements}
      </div>
    </div>
  );
}

LineStepper.propTypes = {
  steps: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired
};

export default LineStepper;
