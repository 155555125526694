import tenantLogo from '../assets/images/koolicar-logo.svg';
import koolicarPinSmallMap from '../assets/images/custom/koolicar/koolicar-pin-small.svg';
import koolicarPinBigMap from '../assets/images/custom/koolicar/koolicar-pin-big.svg';
import favicon from '../assets/images/custom/openfleet/favicon.png';

const koolicar = {
  tenant: {
    name: 'Koolicar',
    politique_de_confidentialite_url:
      'https://www.openfleet.com/politique-de-confidentialite/',
    assets: {
      favicon: favicon,
      logo: tenantLogo,
      pinSmallMap: koolicarPinSmallMap,
      pinBigMap: koolicarPinBigMap
    }
  },
  typography: {
    fontFamily: [
      'SofiaPro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      light: '#ae45e5',
      main: '#8736b2',
      dark: '#8736b2'
    },
    secondary: {
      main: '#ff8533'
    },
    background: {
      default: '#ffffff'
    }
  }
};

export default koolicar;
