import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getAccountDetails } from '../../../store/selectors/account';
import { useErrorToaster } from '../../../utils/errorToaster';
import { updateUser, sendValidationCode } from '../../../store/actions/account';
import AddressCustomInput from '../../../components/account/AddressCustomInput';
import AccountDateInput from '../../../components/account/AccountDateInput';
import AccountTextInput from '../../../components/account/AccountTextInput';

function UserDetails() {
  const { t } = useTranslation(['account', 'home', 'common', 'validation']);
  const tenant = useSelector(state => state.tenant);
  const dispatch = useDispatch();
  const accountDetails = useSelector(getAccountDetails(tenant.dateFormat));
  const displayError = useErrorToaster();
  const ref = React.useRef(null);

  const validationSchema = () => {
    const schemaFields = {};

    if (tenant.requiredDriverFields.includes('first_name'))
      schemaFields.firstName = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('last_name'))
      schemaFields.lastName = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('birthday_time'))
      schemaFields.birthdayTime = Yup.string().validDate(
        t('validation:date-invalid')
      );
    if (tenant.requiredDriverFields.includes('birthday_location'))
      schemaFields.birthdayLocation = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('cell_phone'))
      schemaFields.phoneNumber = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_line1'))
      schemaFields.addressLine1 = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_line2'))
      schemaFields.addressLine2 = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_postal_code'))
      schemaFields.addressPostalCode = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_locality'))
      schemaFields.addressLocality = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_state'))
      schemaFields.addressState = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_country'))
      schemaFields.addressCountry = Yup.string()
        .nullable()
        .required(t('validation:required'));
    return Yup.object(schemaFields);
  };

  const handleFormSubmit = async (
    {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      addressPostalCode,
      addressLocality,
      addressState,
      addressCountry,
      phoneNumber,
      birthdayTime,
      birthdayLocation
    },
    { setSubmitting }
  ) => {
    try {
      await dispatch(
        updateUser({
          firstName,
          lastName,
          addressLine1,
          addressLine2,
          addressPostalCode,
          addressLocality,
          addressState,
          addressCountry,
          phoneNumber,
          birthdayTime,
          birthdayLocation
        })
      );
      if (phoneNumber && tenant.driver_on_sms_form)
        await dispatch(sendValidationCode(phoneNumber));
    } catch (error) {
      setSubmitting(false);
      displayError(error);
    }
  };

  return (
    <div className="account-page personal-details">
      <Typography component="h1">{t('account:your-details')}</Typography>

      <Formik
        innerRef={ref}
        validationSchema={validationSchema}
        initialValues={accountDetails}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleChange,
          isSubmitting,
          errors,
          touched,
          setFieldValue
        }) => (
          <Form noValidate={true} className="frontoffice-form">
            <AccountTextInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'firstName'}
              driverKey={'first_name'}
              Keylabel={'account:firstName'}
            />

            <AccountTextInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'lastName'}
              driverKey={'last_name'}
              Keylabel={'account:lastName'}
            />

            <AccountDateInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'birthdayTime'}
              driverKey={'birthday_time'}
              Keylabel={'account:birthday_time'}
            />

            <AccountTextInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'birthdayLocation'}
              driverKey={'birthday_location'}
              Keylabel={'account:birthday_location'}
            />

            <AccountTextInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'phoneNumber'}
              driverKey={'cell_phone'}
              Keylabel={'account:phone-number'}
            />

            {(tenant.driverFields.includes('address_line1') ||
              tenant.driverFields.includes('address_postal_code') ||
              tenant.driverFields.includes('address_locality') ||
              tenant.driverFields.includes('address_state') ||
              tenant.driverFields.includes('address_country')) && (
              <div className="form-group">
                <AddressCustomInput
                  name="address"
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              </div>
            )}

            <div className="button-group">
              <Button
                type="submit"
                fullWidth
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                {t('common:next')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UserDetails;
