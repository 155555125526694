import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';

import VehicleCard from '../components/search/VehicleCard';
import VehicleCarPoolingCard from '../components/search/VehicleCarPoolingCard';
import SearchHeader from '../components/search/SearchHeader';
import PageWithDrawerMenu from '../components/layout/PageWithDrawerMenu';
import { SEARCH_RESULT_SELECTED } from '../store/actions/search';
import { submitSearch, submitCarpooling } from '../store/actions/search';
import MapView from './search/MapView';
import VehicleView from './search/VehicleView';
import BookingView from './search/BookingView';
import BookingConfirmationView from './search/BookingConfirmationView';
import config from '../config';
import { distance } from '../utils/distanceHelper';

const HomePage = ({ history }) => {
  const isInitialMount = React.useRef(true);
  const { t } = useTranslation(['search']);
  const dispatch = useDispatch();
  const theme = useTheme();
  const tenant = useSelector(state => state.tenant);
  const searchState = useSelector(state => state.search);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const view = useSelector(state => state.search.view);
  const submited = useSelector(state => state.search.submited);
  const searchResults = useSelector(state => state.search.results);
  const carPoolingSearchResults = useSelector(
    state => state.search.carpoolings
  );

  const [formState, setFormState] = useState({
    isProcessing: false,
    startStation: searchState.startStation,
    endStation: searchState.endStation,
    location: searchState.location,
    startDate: searchState.startDate,
    endDate: searchState.endDate,
    rentalLabel: searchState.rentalLabel,
    estimatedDistance: searchState.estimatedDistance,
    categories: searchState.categories
      ? searchState.categories.map(id => Number(id))
      : null,
    options: searchState.options,
    gearsType: searchState.gearsType,
    seatsCount: searchState.seatsCount
  });

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (
        typeof handleDeleteGearsType === 'function' ||
        typeof handleDeleteSeatsCount === 'function' ||
        typeof handleDeleteCategory === 'function' ||
        typeof handleDeleteOptions === 'function' ||
        typeof handleStations === 'function'
      ) {
        refreshSearch();
      }
    }
  }, [formState]);

  const openSearchForm = () => {
    history.push(`${config.auth.rootUrl}search`);
  };

  const selectResult = result => {
    const vehicleId =
      result && result.vehicle.id ? result && result.vehicle.id : null;
    const stationId =
      result && result.station.id ? result && result.station.id : null;
    const destinationId =
      result && result.destination.id ? result && result.destination.id : null;
    const estimatedDistance = result.distance_in_meters
      ? distance(result.distance_in_meters, tenant.distanceUnit)
      : null;
    const rental = null;

    dispatch({
      type: SEARCH_RESULT_SELECTED,
      vehicleId,
      stationId,
      destinationId,
      estimatedDistance,
      rental
    });
  };

  const selectCarPoolingResult = rental => {
    const vehicleId = rental.vehicle_id ? rental && rental.vehicle_id : null;
    const stationId = rental.start_station_id ? rental.start_station_id : null;

    dispatch({
      type: SEARCH_RESULT_SELECTED,
      vehicleId,
      stationId,
      rental
    });
  };

  const refreshSearch = async () => {
    try {
      let carpoolingResults = [];

      const results = await dispatch(submitSearch(formState));

      if (tenant.useCarpooling) {
        carpoolingResults = await dispatch(submitCarpooling(formState));
      }
    } catch (error) {}
  };

  const handleDeleteGearsType = item => {
    setFormState({
      ...formState,
      gearsType: 'all'
    });
  };

  const handleDeleteSeatsCount = item => {
    setFormState({
      ...formState,
      seatsCount: 'all'
    });
  };

  const handleDeleteCategory = item => {
    setFormState({
      ...formState,
      categories: _.pull(formState.categories, item)
    });
  };

  const handleDeleteOptions = item => {
    setFormState({
      ...formState,
      options: _.pull(formState.options, item)
    });
  };

  const handleChangeStations = station => {
    if (!station) return;
    setFormState({
      ...formState,
      startStation: {
        displayText: station.name,
        station: station
      },
      endStation: {
        displayText: station.name,
        station: station
      },
      location: {
        displayText: station.address,
        lat: station.latitude,
        long: station.longitude
      },
      destination: {
        displayText: searchState.destination
          ? searchState.destination.displayText
          : null,
        label: searchState.destination ? searchState.destination.label : null
      }
    });
  };

  return (
    <div
      className={classNames({
        'search-view': true,
        'mobile-layout': isMobile,
        'web-layout': !isMobile
      })}
    >
      <PageWithDrawerMenu>
        {!isMobile || ((view === 'map' || view === 'vehicle') && submited) ? (
          <Drawer variant="permanent" anchor={isMobile ? 'bottom' : 'left'}>
            <SearchHeader
              handleDeleteGearsType={handleDeleteGearsType}
              handleDeleteSeatsCount={handleDeleteSeatsCount}
              handleDeleteCategory={handleDeleteCategory}
              handleDeleteOptions={handleDeleteOptions}
            />

            <div className="drawer-content">
              <div className="vehicule-list">
                <div className="vehicule-cards">
                  {searchResults.length > 0 ||
                  carPoolingSearchResults.length > 0 ? (
                    <h4>{t('search:title-pick-vehicle')}</h4>
                  ) : null}
                  {carPoolingSearchResults.map(result => (
                    <VehicleCarPoolingCard
                      key={result.vehicle_id}
                      startDate={new Date(result.reservation_start_time)}
                      endDate={new Date(result.reservation_end_time)}
                      vehicleId={result.vehicle_id}
                      carpoolingDestination={result.carpooling_destination}
                      startStationId={result.start_station_id}
                      onClick={() => selectCarPoolingResult(result)}
                    />
                  ))}
                  {searchResults.map(result => (
                    <VehicleCard
                      key={result.vehicle.id}
                      vehicle={result.vehicle}
                      distanceInMeters={result.distance_in_meters}
                      price={result.price}
                      location={result.station}
                      onClick={() => selectResult(result)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Drawer>
        ) : null}

        <div className="body-container">
          {view === 'map' ? (
            <MapView
              handleChangeStations={handleChangeStations}
              onSearchInputClick={openSearchForm}
            />
          ) : null}
          {view === 'vehicle' ? <VehicleView /> : null}
          {view === 'booking' ? <BookingView /> : null}
          {view === 'confirmation' ? <BookingConfirmationView /> : null}
        </div>
      </PageWithDrawerMenu>
    </div>
  );
};

export default withRouter(HomePage);
