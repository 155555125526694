import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import styles from '../../../assets/maps/theme-silver';
import config from '../../../config';
import { useTheme } from '@material-ui/core/styles';

const mapStyles = {
  width: '100%',
  height: '100%'
};

const StationLocation = ({ latitude, longitude, google }) => {
  const theme = useTheme();

  return (
    <div className="map-wrapper">
      <Map
        google={google}
        zoom={17}
        style={mapStyles}
        initialCenter={{
          lat: latitude,
          lng: longitude
        }}
        mapTypeControl={false}
        streetViewControl={false}
        styles={styles}
      >
        <Marker
          position={{
            lat: latitude,
            lng: longitude
          }}
          icon={{
            url: theme.tenant.assets.pinSmallMap,
            anchor: new google.maps.Point(44, 44),
            scaledSize: new google.maps.Size(44, 44)
          }}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: config.google.mapsApiKey
})(StationLocation);
