import React from 'react';
import Header from '../shared/Header';
import Footer from '../shared/Footer';

function HeaderFooterLayout({ children }) {
  return (
    <div className="header-footer-layout">
      <div className="header-container">
        <Header />
      </div>
      <div className="body-container">
        {children}
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
}

export default HeaderFooterLayout;
