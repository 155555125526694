import auth from './auth';
import categories from './categories';
import customContents from './customContents';
import termsAndConditions from './termsAndConditions';
import rentals from './rentals';
import stations from './stations';
import stripe from './stripe';
import user from './user';
import vehicles from './vehicles';
import tenant from './tenant';
import rentalLabels from './rentalLabels';

export default {
  auth,
  categories,
  customContents,
  termsAndConditions,
  rentals,
  stations,
  stripe,
  user,
  vehicles,
  tenant,
  rentalLabels
};
