export default {
  PASSWORD_TOO_SHORT: 'validation:pwd-too-short',
  EMAIL_ALREADY_TAKEN: 'validation:email-taken',
  EMAIL_IS_INVALID: 'validation:email-invalid',
  INVALID_PHONE: 'validation:phone-invalid',
  INVALID_SMS_CODE: 'validation:sms-code-invalid',
  SMS_CODE_EXPIRED: 'validation:sms-code-expired',
  INVALID_FILE_TYPE: 'validation:invalid-file-type',
  INVALID_FILE_EXTENSION: 'validation:invalid-file-extension',
  FILE_TOO_BIG: 'validation:file-too-big',
  RENTAL_DATES_IN_PAST: 'validation:rental-dates-in-past',
  START_DATE_AFTER_END_DATE: 'validation:start-date-after-end-date',
  RENTAL_ALREADY_CANCELLED: 'validation:rental-already-cancelled',
  SEARCH_END_TIME_TOO_LONG: 'validation:search-end-time-too-long',
  SEARCH_END_TIME_TOO_SHORT: 'validation:search-end-time-too-short',
  SEARCH_START_TIME_TOO_FAR_IN_ADVANCE:
    'validation:search-start-time-too-far-in-advance',
  SEARCH_NO_ACTIVE_CARD: 'validation:search-no-active-card',
  RENTAL_BASE_REQUIRED_PAYMENT_CARD_MISSING: 'validation:rental-no-active-card',
  SEARCH_TOO_MANY_RESERVATIONS: 'validation:search-too-many-reservations',
  SEARCH_END_TIME_AFTER: 'validation:search-end-time-after',
  SEARCH_START_TIME_BEFORE: 'validation:search-start-time-before'
};
