import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';

import { confirmPassword } from '../../store/actions/account';
import config from '../../config';
import PasswordInput from './PasswordInput';
import { useErrorToaster } from '../../utils/errorToaster';
import AnimatedCheckmark from '../../components/shared/AnimatedCheckmark';

const ResetPassword = ({ history }) => {
  const { t } = useTranslation(['account', 'validation']);
  const dispatch = useDispatch();
  const [resetPasswordToken, setResetPasswordToken] = useState(null);
  const [isSuccess, setSuccess] = useState(false);
  const location = useLocation();
  const displayError = useErrorToaster();

  useEffect(() => {
    setResetPasswordToken(
      new URLSearchParams(location.search).get('reset_password_token')
    );
  }, []);

  const initialValues = {
    password: '',
    passwordConfirmation: ''
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t('validation:required')),
    passwordConfirmation: Yup.string().required(t('validation:required'))
  });

  const successMessage = (
    <div className="content-wrapper">
      <AnimatedCheckmark />

      <div className="success-message">
        <div className="line1">{t('account:reset-password-success')}</div>
        <div className="forgot-password">
          <a href={`${config.auth.rootUrl}login`}>{t('account:login')}</a>
        </div>
      </div>
    </div>
  );

  const errorMessage = (
    <div className="error-message">{t('account:reset-password-error')}</div>
  );

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await dispatch(
          confirmPassword(
            values.password,
            values.passwordConfirmation,
            resetPasswordToken
          )
        );
        setSuccess(true);
      } catch (error) {
        setSubmitting(false);
        displayError(error);
      }
    }
  });

  return (
    <Container component="main" maxWidth="xs" className="login-or-register">
      <div className="inner">
        <Typography component="h1" variant="h5">
          {t('account:new-password')}
        </Typography>

        {resetPasswordToken ? (
          !isSuccess ? (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <PasswordInput
                  name="password"
                  placeholder={t('account:password')}
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && !!errors.password}
                  helperText={
                    touched.password && errors.password ? errors.password : null
                  }
                />
              </div>

              <div className="form-group">
                <PasswordInput
                  name="passwordConfirmation"
                  placeholder={t('account:password')}
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  error={
                    touched.passwordConfirmation &&
                    !!errors.passwordConfirmation
                  }
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                      ? errors.passwordConfirmation
                      : null
                  }
                />

                <div className="links forgot-password">
                  <a href={`${config.auth.rootUrl}login`}>
                    {t('account:login')}
                  </a>
                </div>
              </div>

              <div className="button-group">
                <Button
                  type="submit"
                  fullWidth
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  {t('account:confirm')}
                </Button>
              </div>
            </form>
          ) : (
            successMessage
          )
        ) : (
          errorMessage
        )}
      </div>
    </Container>
  );
};

export default withRouter(ResetPassword);
