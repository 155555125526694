import { TERMS_AND_CONDITIONS_FETCHED } from '../actions/termsAndConditions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TERMS_AND_CONDITIONS_FETCHED:
      return {
        ...state,
        list: action.terms_and_conditions_versions
      };
    default:
      return state;
  }
};
