import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const CategoryName = ({ categoryId }) => {
  const categories = useSelector(state => state.categories.list);
  if (categories.length > 0 && categoryId) {
    const category = categories.find(o => o.id === categoryId);
    return <span>{category.name || ''}</span>;
  } else {
    return null;
  }
};

CategoryName.propTypes = {
  categoryId: PropTypes.number.isRequired
};

export default CategoryName;
