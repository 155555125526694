import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import _ from 'lodash';

import { Container } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import BackupIcon from '@material-ui/icons/Backup';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

import api from '../../utils/api';
import { getAccountDetails } from '../../store/selectors/account';
import { fetchUserData } from '../../store/actions/account';
import { fetchAdditionalDocuments } from '../../store/actions/tenant';
import { useModal } from '../../utils/modal';
import FileUploadIcon from '../../components/shared/FileUploadIcon';
import ChangePasswordModal from '../../components/account/ChangePasswordModal';
import DeleteAccountModal from '../../components/account/DeleteAccountModal';
import AddressCustomInput from '../../components/account/AddressCustomInput';
import AccountDateInput from '../../components/account/AccountDateInput';
import AccountTextInput from '../../components/account/AccountTextInput';
import ValidatePhoneNumberModal from '../../components/account/ValidatePhoneNumberModal';
import { useErrorToaster } from '../../utils/errorToaster';
import closeIcon from '../../assets/images/close.svg';
import config from '../../config';

import {
  ACCOUNT_NEW_PHONE_NUMBER_DISCARDED,
  submitNewPhoneNumber,
  updateUser
} from '../../store/actions/account';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px;',
    width: '100%',
    marginBottom: '20px'
  }
}));

const AccountDetailsPage = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['account']);
  const language = useSelector(state => state.app.language);
  const tenant = useSelector(state => state.tenant);
  const accountDetails = useSelector(getAccountDetails(tenant.dateFormat));
  const classes = useStyles();
  const additionalDocumentsFr = tenant.additionalDocumentsFr;
  const additionalDocumentsEn = tenant.additionalDocumentsEn;
  const passwordModal = useModal();
  const deleteAccountModal = useModal();
  const displayError = useErrorToaster();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadData = async () => {
      await dispatch(fetchAdditionalDocuments());
    };
    loadData();
  }, []);

  const [uploadStatus, setUploadStatus] = useState({
    profile: false,
    licence_front: false,
    licence_back: false,
    identity_front: false,
    identity_back: false
  });

  const validationSchema = () => {
    const schemaFields = {};

    if (tenant.requiredDriverFields.includes('first_name'))
      schemaFields.firstName = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('last_name'))
      schemaFields.lastName = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_line1'))
      schemaFields.addressLine1 = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_line2'))
      schemaFields.addressLine2 = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_postal_code'))
      schemaFields.addressPostalCode = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_locality'))
      schemaFields.addressLocality = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_state'))
      schemaFields.addressState = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('address_country'))
      schemaFields.addressCountry = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('birthday_time'))
      schemaFields.birthdayTime = Yup.string().validDate(
        t('validation:date-invalid')
      );
    if (tenant.requiredDriverFields.includes('birthday_location'))
      schemaFields.birthdayLocation = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('driving_licence_number'))
      schemaFields.licenceNumber = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('driving_licence_location'))
      schemaFields.licenceLocation = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('driving_licence_obtaining_time'))
      schemaFields.licenceObtainingTime = Yup.string().validDate(
        t('validation:date-invalid')
      );
    if (tenant.requiredDriverFields.includes('driving_licence_expiration_time'))
      schemaFields.licenceExpirationTime = Yup.string().validDate(
        t('validation:date-invalid')
      );
    if (tenant.requiredDriverFields.includes('cell_phone'))
      schemaFields.phoneNumber = Yup.string()
        .nullable()
        .required(t('validation:required'));
    return Yup.object(schemaFields);
  };

  const cancelPhoneNumberChange = () => {
    dispatch({
      type: ACCOUNT_NEW_PHONE_NUMBER_DISCARDED
    });
  };

  const handleReturn = () => {
    history.push(config.auth.rootUrl);
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      if (values.phoneNumber !== accountDetails.phoneNumber) {
        enqueueSnackbar(t('account:sms-code-sent'), { variant: 'warning' });
        await dispatch(submitNewPhoneNumber(values.phoneNumber));
      }
    } catch (error) {
      setSubmitting(false);
      displayError(error);
    }
    try {
      if (
        !_.isEqual(
          _.omit(values, ['phoneNumber']),
          _.omit(accountDetails, ['phoneNumber'])
        )
      ) {
        await dispatch(updateUser(values));
        enqueueSnackbar(t('account:profil-changed'), { variant: 'success' });
      }
    } catch (error) {
      setSubmitting(false);
      displayError(error);
    }
  };

  const handleDocumentUpload = async (
    type,
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();
    enqueueSnackbar(t('account:file-sent'), { variant: 'warning' });
    try {
      await api.user.addDocument(type, file, {
        onUploadProgress: event => {
          const percent = Math.round((event.loaded * 100) / event.total);
          setProgress(percent);
        }
      });
      enqueueSnackbar(t('account:file-changed'), { variant: 'success' });
      setCompleted();

      setUploadStatus({
        ...uploadStatus,
        [type]: true
      });
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  const handleOtherDocumentUpload = async (
    type,
    additionalDocumentId,
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();
    enqueueSnackbar(t('account:file-sent'), { variant: 'warning' });
    try {
      const response = await api.user.addDocument('other', file, {
        onUploadProgress: event => {
          const percent = Math.round((event.loaded * 100) / event.total);
          setProgress(percent);
        }
      });

      await api.user.attachDocument(response.document.id, additionalDocumentId);

      enqueueSnackbar(t('account:file-changed'), { variant: 'success' });
      setCompleted();

      setUploadStatus({
        ...uploadStatus,
        [type]: true
      });
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  const handPhotoUpload = async (
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();
    enqueueSnackbar(t('account:file-sent'), { variant: 'warning' });
    try {
      await api.user.addPhoto(file, {
        onUploadProgress: event => {
          const percent = Math.round((event.loaded * 100) / event.total);
          setProgress(percent);
        }
      });
      enqueueSnackbar(t('account:file-changed'), { variant: 'success' });
      setCompleted();

      setUploadStatus({
        ...uploadStatus,
        profile: true
      });

      await dispatch(fetchUserData());
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  return (
    <Container maxWidth="md" className="account-page-container">
      <Card className={classes.root}>
        <div className="page-header">
          <Typography component="h1">{t('account:my-information')}</Typography>

          <div className="close-button">
            <IconButton onClick={handleReturn}>
              <img src={closeIcon} alt="" width="30" height="30" />
            </IconButton>
          </div>
        </div>

        <Formik
          validationSchema={validationSchema}
          initialValues={accountDetails}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            handleChange,
            isSubmitting,
            errors,
            touched,
            setFieldValue,
            dirty,
            isValid
          }) => (
            <Form noValidate={true} className="frontoffice-form">
              <Typography component="h2">
                {t('account:personal-info')}
              </Typography>

              <Grid container spacing={2} justify="left">
                <Grid item xs={12} sm={12} md={6}>
                  <div className="form-group">
                    <TextField
                      required
                      name="email"
                      label={t('account:email')}
                      variant="outlined"
                      margin="none"
                      value={values.email ? values.email : ''}
                      disabled
                      fullWidth
                    />
                  </div>

                  <AccountTextInput
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    keyName={'firstName'}
                    driverKey={'first_name'}
                    Keylabel={'account:firstName'}
                  />

                  <AccountTextInput
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    keyName={'lastName'}
                    driverKey={'last_name'}
                    Keylabel={'account:lastName'}
                  />

                  <AccountDateInput
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    keyName={'birthdayTime'}
                    driverKey={'birthday_time'}
                    Keylabel={'account:birthday_time'}
                  />

                  <AccountTextInput
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    keyName={'birthdayLocation'}
                    driverKey={'birthday_location'}
                    Keylabel={'account:birthday_location'}
                  />

                  <AccountTextInput
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    keyName={'phoneNumber'}
                    driverKey={'cell_phone'}
                    Keylabel={'account:phone-number'}
                  />
                </Grid>

                {(tenant.driverDocumentsSelfie ||
                  tenant.driverDocumentsIdentify) && (
                  <Grid container justify="center" item xs={12} sm={12} md={6}>
                    <Grid
                      container
                      alignItems="center"
                      direction="column"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {tenant.driverDocumentsSelfie && (
                        <div className="">
                          <div className="avatar">
                            <Badge
                              className=""
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                              badgeContent={
                                <FileUploadIcon
                                  className="upload-icon"
                                  icon={<EditIcon className="EditIcon" />}
                                  onFileSelected={(file, callbacks) =>
                                    handPhotoUpload(file, callbacks)
                                  }
                                  accept={'image/*, application/pdf'}
                                ></FileUploadIcon>
                              }
                            >
                              <Avatar
                                className="avatar-edit"
                                alt="Travis Howard"
                                src={values.avatar}
                              />
                            </Badge>
                          </div>
                        </div>
                      )}

                      {tenant.driverDocumentsIdentify && (
                        <div>
                          <Grid
                            className="file-container"
                            container
                            direction="row"
                          >
                            <h3>{t('account:id-card')}</h3>
                          </Grid>
                          <Grid
                            className="file-container"
                            container
                            direction="row"
                          >
                            <span>{t('account:recto')} :</span>
                            <VisibilityIcon className="disabled VisibilityIcon" />
                            <GetAppIcon className="disabled GetAppIcon" />
                            <FileUploadIcon
                              className="upload-icon"
                              icon={<BackupIcon className="BackupIcon" />}
                              onFileSelected={(file, callbacks) =>
                                handleDocumentUpload(
                                  'identity_front',
                                  file,
                                  callbacks
                                )
                              }
                              accept={'image/*, application/pdf'}
                            ></FileUploadIcon>
                          </Grid>

                          <Grid
                            className="file-container"
                            container
                            direction="row"
                          >
                            <span>{t('account:verso')} :</span>
                            <VisibilityIcon className="disabled VisibilityIcon" />
                            <GetAppIcon className="disabled GetAppIcon" />
                            <FileUploadIcon
                              className="upload-icon"
                              icon={<BackupIcon className="BackupIcon" />}
                              onFileSelected={(file, callbacks) =>
                                handleDocumentUpload(
                                  'identity_back',
                                  file,
                                  callbacks
                                )
                              }
                              accept={'image/*, application/pdf'}
                            ></FileUploadIcon>
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {(tenant.driverFields.includes('address_line1') ||
                tenant.driverFields.includes('address_postal_code') ||
                tenant.driverFields.includes('address_locality') ||
                tenant.driverFields.includes('address_state') ||
                tenant.driverFields.includes('address_country')) && (
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="form-group">
                        <AddressCustomInput
                          name="address"
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}

              {(tenant.driverFields.includes('driving_licence_number') ||
                tenant.driverFields.includes('driving_licence_location') ||
                tenant.driverFields.includes(
                  'driving_licence_obtaining_time'
                ) ||
                tenant.driverFields.includes(
                  'driving_licence_expiration_time'
                ) ||
                tenant.driverFields.includes(
                  'driving_licence_expiration_time'
                ) ||
                tenant.driverDocumentsLicence) && (
                <div>
                  <Typography component="h2">
                    {t('account:driver-license')}
                  </Typography>

                  <Grid container spacing={2}>
                    {(tenant.driverFields.includes('driving_licence_number') ||
                      tenant.driverFields.includes(
                        'driving_licence_location'
                      ) ||
                      tenant.driverFields.includes(
                        'driving_licence_obtaining_time'
                      ) ||
                      tenant.driverFields.includes(
                        'driving_licence_expiration_time'
                      ) ||
                      tenant.driverFields.includes(
                        'driving_licence_expiration_time'
                      )) && (
                      <Grid item xs={12} sm={12} md={6}>
                        <div>
                          <AccountTextInput
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                            touched={touched}
                            keyName={'licenceNumber'}
                            driverKey={'driving_licence_number'}
                            Keylabel={'account:license-number'}
                          />

                          <AccountTextInput
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                            touched={touched}
                            keyName={'licenceLocation'}
                            driverKey={'driving_licence_location'}
                            Keylabel={'account:obtaining-location'}
                          />

                          <AccountDateInput
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                            touched={touched}
                            keyName={'licenceObtainingTime'}
                            driverKey={'driving_licence_obtaining_time'}
                            Keylabel={'account:obtaining-date'}
                          />

                          <AccountDateInput
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                            touched={touched}
                            keyName={'licenceExpirationTime'}
                            driverKey={'driving_licence_expiration_time'}
                            Keylabel={'account:expiration-date'}
                          />
                        </div>
                      </Grid>
                    )}

                    {tenant.driverDocumentsLicence && (
                      <Grid
                        container
                        justify="center"
                        item
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <Grid
                          container
                          alignItems="center"
                          direction="column"
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <div>
                            <Grid
                              className="file-container"
                              container
                              direction="row"
                            >
                              <h3>{t('account:driver-license')}</h3>
                            </Grid>
                            <Grid
                              className="file-container"
                              container
                              direction="row"
                              alignItems="center"
                            >
                              <span>{t('account:recto')} :</span>
                              <VisibilityIcon className="disabled VisibilityIcon" />
                              <GetAppIcon className="disabled GetAppIcon" />
                              <FileUploadIcon
                                className="upload-icon"
                                icon={<BackupIcon className="BackupIcon" />}
                                onFileSelected={(file, callbacks) =>
                                  handleDocumentUpload(
                                    'licence_front',
                                    file,
                                    callbacks
                                  )
                                }
                                accept={'image/*, application/pdf'}
                              ></FileUploadIcon>
                            </Grid>

                            <Grid
                              className="file-container"
                              container
                              direction="row"
                              alignItems="center"
                            >
                              <span>{t('account:verso')} :</span>
                              <VisibilityIcon className="disabled VisibilityIcon" />
                              <GetAppIcon className="disabled GetAppIcon" />
                              <FileUploadIcon
                                className="upload-icon"
                                icon={<BackupIcon className="BackupIcon" />}
                                onFileSelected={(file, callbacks) =>
                                  handleDocumentUpload(
                                    'licence_back',
                                    file,
                                    callbacks
                                  )
                                }
                                accept={'image/*, application/pdf'}
                              ></FileUploadIcon>
                            </Grid>

                            {additionalDocumentsFr.length > 0 && (
                              <Grid
                                className="file-container"
                                container
                                direction="row"
                              >
                                <h3>{t('account:other-photo')}</h3>
                              </Grid>
                            )}
                            <span>
                              {(language === 'fr'
                                ? additionalDocumentsFr
                                : additionalDocumentsEn
                              ).map(additionalDocument => (
                                <Grid
                                  key={additionalDocument.id}
                                  className="file-container"
                                  container
                                  direction="row"
                                  alignItems="center"
                                >
                                  <span>{additionalDocument.title}</span>
                                  <VisibilityIcon className="disabled VisibilityIcon" />
                                  <GetAppIcon className="disabled GetAppIcon" />
                                  <FileUploadIcon
                                    className="upload-icon"
                                    icon={<BackupIcon className="BackupIcon" />}
                                    onFileSelected={(file, callbacks) =>
                                      handleOtherDocumentUpload(
                                        additionalDocument.name,
                                        additionalDocument.id,
                                        file,
                                        callbacks
                                      )
                                    }
                                    accept={'image/*, application/pdf'}
                                  ></FileUploadIcon>
                                </Grid>
                              ))}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}

              <Grid container spacing={2}>
                <div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="button-wrapper">
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={deleteAccountModal.open}
                    >
                      {t('account:delete')}
                    </Button>
                  </div>
                </div>
              </Grid>
              <div className="button-box">
                <div className="button-wrapper">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {t('account:update-my-info')}
                  </Button>
                </div>
                <div className="button-wrapper">
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={passwordModal.open}
                  >
                    {t('account:change-password')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <DeleteAccountModal
          open={deleteAccountModal.isOpen}
          onClose={deleteAccountModal.close}
        />
        <ChangePasswordModal
          open={passwordModal.isOpen}
          onClose={passwordModal.close}
        />
        <ValidatePhoneNumberModal
          open={!!accountDetails.newPhoneNumber}
          onClose={cancelPhoneNumberChange}
        />
      </Card>
    </Container>
  );
};

export default AccountDetailsPage;
