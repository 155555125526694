import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderFooterLayout from '../../components/layout/HeaderFooterLayout';
import AnimatedCheckmark from '../../components/shared/AnimatedCheckmark';
import config from '../../config';

function WelcomePage({ history }) {
  const { t } = useTranslation(['common']);
  const firstName = useSelector(state => state.account.firstName);

  setTimeout(() => {
    history.push(`${config.auth.rootUrl}signup`);
  }, 4000);

  return (
    <div className="signup-completed-page">
      <HeaderFooterLayout>
        <div className="content-wrapper">
          <AnimatedCheckmark />

          <div className="welcome-message">
            <div className="line1">{t('common:welcome')}</div>
            <div className="line2">{firstName}</div>
          </div>
        </div>
      </HeaderFooterLayout>
    </div>
  );
}

export default withRouter(WelcomePage);
