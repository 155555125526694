import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button, Container, Divider, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';

import closeIcon from '../../assets/images/close.svg';
import appStore from '../../assets/images/app-store.svg';
import googePlay from '../../assets/images/google-play.svg';
import { useVehicleLoader } from '../../hooks/vehicleLoader';
import { useStationLoader } from '../../hooks/stationLoader';
import StationLocation from '../../components/vehicles/VehicleDetails/StationLocation';
import Photo from '../../components/vehicles/VehicleDetails/Photo';
import AnimatedCheckmark from '../../components/shared/AnimatedCheckmark';
import { useScrollRestore } from '../../hooks/scrollRestore';
import { rentalDateFormat } from '../../utils/variables';
import config from '../../config';
import { getSearchDates } from '../../store/selectors/search';
import { resetSearch } from '../../store/actions/search';

const BookingConfirmationView = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['booking', 'common', 'search']);
  const search = useSelector(state => state.search);
  const searchDates = useSelector(getSearchDates);
  const vehicleId = search.selectedResult;
  const stationId = search.selectedStation;
  const startDate = searchDates.startDate;
  const endDate = searchDates.endDate;
  const rental = search.rental;
  const userId = useSelector(state => state.account.id);
  const tenant = useSelector(state => state.tenant);
  const { vehicle } = useVehicleLoader(vehicleId);
  const { station } = useStationLoader(stationId);
  useScrollRestore(vehicleId);

  const handleClose = () => {
    history.push(`${config.auth.rootUrl}account/bookings`);
  };

  useEffect(() => {
    return () => {
      dispatch(resetSearch());
    };
  }, []);

  return (
    <div className="vehicle-booking">
      <Container maxWidth="md" className="page-container">
        <div className="page-header">
          {rental && rental.driver_id !== userId ? (
            <Typography component="h1">
              {t('booking:booking-carpooling-confirmed')}
            </Typography>
          ) : (
            <Typography component="h1">
              {t('booking:booking-confirmed')}
            </Typography>
          )}
          <div className="close-button">
            <IconButton onClick={handleClose}>
              <img src={closeIcon} alt="" width="30" height="30" />
            </IconButton>
          </div>
        </div>

        <AnimatedCheckmark />

        <Paper className="download-app-box">
          {rental && rental.driver_id !== userId ? (
            <div className="title">{t('booking:carpooling-access')}</div>
          ) : (
            <div className="title">{t('booking:vehicle-access')}</div>
          )}
          <div className="text">
            {rental && rental.driver_id !== userId ? (
              <p>{t('booking:carpooling-access-text')}</p>
            ) : (
              <p>{t('booking:vehicle-access-text')}</p>
            )}
            <p className="hidden">{t('booking:vehicle-access-cta')}</p>
          </div>

          <div className="hidden flex-even">
            <div>
              <a href="/">
                <img src={appStore} alt="" width="149" height="45" />
              </a>
            </div>
            <div>
              <a href="/">
                <img src={googePlay} alt="" width="149" height="45" />
              </a>
            </div>
          </div>
        </Paper>

        {vehicle && station ? (
          <div>
            <Photo image={vehicle.photos ? vehicle.photos[0] : null} />

            <div className="sections">
              <div className="section flex-row">
                <div>
                  <div className="title">{t('booking:vehicle')}</div>
                  <div className="section-content">
                    {vehicle.make} {vehicle.model} {vehicle.year}
                  </div>
                </div>
                <div>
                  <div className="title">{t('booking:plate-number')}</div>
                  <div className="section-content">{vehicle.license_plate}</div>
                </div>
              </div>

              <div className="section">
                <div className="date-range-labels">
                  <div className="label">{t('search:departure')}</div>
                  <div className="label">{t('search:return')}</div>
                </div>

                <Paper className="date-range-paper">
                  <div className="date">
                    {startDate
                      ? moment(startDate).format(rentalDateFormat)
                      : null}
                  </div>
                  <Divider orientation="vertical" className="date-divider" />
                  <div className="date">
                    {endDate ? moment(endDate).format(rentalDateFormat) : null}
                  </div>
                </Paper>
              </div>

              <div className="hidden section">
                <div className="title">{t('booking:included-distance')}</div>
                <div className="section-content">
                  <span className="kilometers">XXX</span> Km/{t('common:day')}
                </div>
              </div>

              <div className="section">
                <div className="title">
                  {tenant.btob ? station.name : t('booking:address')}
                </div>
                <div className="section-content">
                  {station.address ? station.address : null}
                </div>
                {station.latitude && station.longitude ? (
                  <StationLocation
                    latitude={station.latitude}
                    longitude={station.longitude}
                  />
                ) : null}
              </div>
            </div>

            <Grid container justify="center">
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to={`${config.auth.rootUrl}account/help/faq`}
              >
                {t('booking:how-it-works')}
              </Button>
            </Grid>
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default withRouter(BookingConfirmationView);
