import React, { useEffect, useState } from 'react';
import VehicleBody from '../../components/vehicles/VehicleBody/VehicleBody';
import { Container } from '@material-ui/core';
import { withRouter, useParams } from 'react-router-dom';

const BookingDetailsPage = ({ history }) => {
  return (
    <Container maxWidth="md" className="account-page-container">
      <VehicleBody />
    </Container>
  );
};

export default withRouter(BookingDetailsPage);
