import { orderBy } from 'lodash';
import moment from 'moment';
import {
  closedStatuses,
  inProgressStatuses,
  upcomingStatuses
} from './../../utils/rentalStatuses';

const getSortedBookings = state => {
  let rentals = [];

  if (state.rentals.list) rentals = rentals.concat(state.rentals.list);
  if (state.rentals.carpoolings)
    rentals = rentals.concat(state.rentals.carpoolings);

  return orderBy(rentals, ['reservation_start_time'], ['desc']);
};

const getSortedUpcomingBookings = state => {
  let rentals = [];

  if (state.rentals.list) rentals = rentals.concat(state.rentals.list);
  if (state.rentals.carpoolings)
    rentals = rentals.concat(state.rentals.carpoolings);

  return orderBy(rentals, ['reservation_start_time'], ['asc']);
};

export const getInProgressBookings = state => {
  return getSortedBookings(state).filter(rental => {
    return (
      closedStatuses.indexOf(rental.status) === -1 &&
      upcomingStatuses.indexOf(rental.status) === -1
    );
  });
};

export const getUpcomingBookings = state => {
  return getSortedUpcomingBookings(state).filter(rental => {
    return (
      closedStatuses.indexOf(rental.status) === -1 &&
      inProgressStatuses.indexOf(rental.status) === -1
    );
  });
};

export const getBookingHistoryByMonth = state => {
  const history = {};

  getSortedBookings(state).forEach(rental => {
    if (closedStatuses.indexOf(rental.status) > -1) {
      const monthIdentifier = moment(rental.reservation_end_time).format(
        'YYYY-MM'
      );

      if (history[monthIdentifier] === undefined) {
        history[monthIdentifier] = [];
      }

      history[monthIdentifier].push(rental);
    }
  });

  return history;
};
