import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';

import BookingCard from '../../components/bookings/BookingCard';
import closeIcon from '../../assets/images/close.svg';
import {
  getUpcomingBookings,
  getBookingHistoryByMonth,
  getInProgressBookings
} from '../../store/selectors/bookings';
import BookingHistoryList from '../../components/bookings/BookingHistoryList';
import AsyncContent from '../../components/shared/AsyncContent';
import { fetchRentals, fetchAllCarpoolings } from '../../store/actions/rentals';
import config from '../../config';

const BookingsPage = ({ history }) => {
  const { t } = useTranslation(['account']);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const upcomingBookings = useSelector(getUpcomingBookings);
  const inProgressBookings = useSelector(getInProgressBookings);
  const bookingHistory = useSelector(getBookingHistoryByMonth);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await dispatch(fetchAllCarpoolings());
      await dispatch(fetchRentals());
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleBookingClick = id => {
    history.push(`${config.auth.rootUrl}account/bookings/${id}`);
  };

  const handleReturn = () => {
    history.push(config.auth.rootUrl);
  };

  return (
    <div className="bookings-page">
      <Container
        maxWidth="md"
        className="account-page-container future-bookings-container"
      >
        <div className="page-header">
          <Typography component="h1">{t('account:my-bookings')}</Typography>

          <div className="close-button">
            <IconButton onClick={handleReturn}>
              <img src={closeIcon} alt="" width="30" height="30" />
            </IconButton>
          </div>
        </div>

        <Typography component="h2">
          {t('account:bookings-inprogress')}
        </Typography>

        <AsyncContent loading={isLoading}>
          {inProgressBookings && inProgressBookings.length ? (
            <div className="future-bookings-list">
              {inProgressBookings.map(booking => (
                <BookingCard
                  key={`booking-${booking.id}`}
                  rental={booking}
                  startTime={booking.reservation_start_time}
                  endTime={booking.reservation_end_time}
                  vehicleId={booking.vehicle_id}
                  status={booking.status}
                  onClick={() => handleBookingClick(booking.id)}
                />
              ))}
            </div>
          ) : (
            <div className="no-booking">{t('account:no-booking')}</div>
          )}
        </AsyncContent>
        <br />
        <Typography component="h2">{t('account:bookings-upcoming')}</Typography>

        <AsyncContent loading={isLoading}>
          {upcomingBookings && upcomingBookings.length ? (
            <div className="future-bookings-list">
              {upcomingBookings.map(booking => (
                <BookingCard
                  key={`booking-${booking.id}`}
                  startTime={booking.reservation_start_time}
                  endTime={booking.reservation_end_time}
                  rental={booking}
                  stationId={booking.start_station_id}
                  status={booking.status}
                  onClick={() => handleBookingClick(booking.id)}
                />
              ))}
            </div>
          ) : (
            <div className="no-booking">{t('account:no-upcoming-booking')}</div>
          )}
        </AsyncContent>
      </Container>

      <div className="white-background past-bookings">
        <Container maxWidth="md" className="account-page-container">
          <Typography component="h2">
            {t('account:bookings-history')}
          </Typography>

          <AsyncContent loading={isLoading}>
            {typeof bookingHistory === 'object' &&
            Object.keys(bookingHistory).length ? (
              <BookingHistoryList historyByMonth={bookingHistory} />
            ) : (
              <div className="no-booking">{t('account:no-booking')}</div>
            )}
          </AsyncContent>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(BookingsPage);
