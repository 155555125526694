import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const SearchButton = ({ value, onClick }) => {
  const { t } = useTranslation(['search']);
  const searchSubmited = useSelector(state => state.search.submited);

  return (
    <div className="search-button" onClick={onClick}>
      <Button variant="contained" color="primary" disableElevation>
        {searchSubmited ? (
          <span>{t('search:search-button-edit')}</span>
        ) : (
          <span>{t('search:search-button')}</span>
        )}
      </Button>
    </div>
  );
};

export default SearchButton;
