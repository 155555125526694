import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import TermsDialog from '../content/TermsDialog';
import { useModal } from '../../utils/modal';

import { createGuestToken, logOut } from '../../store/actions/auth';
import config from '../../config';

function Footer() {
  const dispatch = useDispatch();
  const { t } = useTranslation('footer');
  const termsModal = useModal();
  const theme = useTheme();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const handleLegalClick = () => {
    termsModal.open();
  };

  const handleLogout = async () => {
    await dispatch(logOut());
    await dispatch(createGuestToken());
  };

  return (
    <div className="tenant-footer">
      <div className="menu-items">
        {isAuthenticated ? (
          <div className="menu-item" onClick={handleLogout}>
            <span>{t('logout')}</span>
          </div>
        ) : null}

        <div className="menu-item" onClick={handleLegalClick}>
          <span>{t('terms')}</span>
        </div>
        <TermsDialog open={termsModal.isOpen} onClose={termsModal.close} />
        <div className="menu-item">
          <a
            target="_blank"
            href={theme.tenant.politique_de_confidentialite_url}
          >
            {t('privacy')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
