import api from '../../utils/api';

export const TERMS_AND_CONDITIONS_FETCHED = 'TERMS_AND_CONDITIONS_FETCHED';

export function fetchTermsAndConditions() {
  return async function(dispatch) {
    try {
      const response = await api.termsAndConditions.getAll();

      dispatch({
        type: TERMS_AND_CONDITIONS_FETCHED,
        terms_and_conditions_versions: response.terms_and_conditions_versions
      });
    } catch (error) {
      throw error;
    }
  };
}
