import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import PasswordInput from '../PasswordInput';
import { useErrorToaster } from '../../../utils/errorToaster';
import { useModal } from '../../../utils/modal';
import { createUser } from '../../../store/actions/auth';
import TermsDialog from '../../content/TermsDialog';

function CreateAccount() {
  const { t } = useTranslation(['account', 'home', 'common', 'validation']);
  const tenant = useSelector(state => state.tenant);
  const termsModal = useModal();
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const theme = useTheme();

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    terms: false
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation:required'))
      .email(t('validation:invalid-format')),
    password: Yup.string().required(t('validation:required')),
    confirmPassword: Yup.string().when('password', {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        t('account:error-confirm-password')
      )
    })
  });

  const handleFormSubmit = async ({ email, password }, { setSubmitting }) => {
    try {
      await dispatch(createUser({ email, password }));
    } catch (error) {
      setSubmitting(false);
      displayError(error);
    }
  };

  const term = () => {
    if (tenant.theme === 'ios')
      return (
        <span>
          {t('account:accept-terms-ios-1')}
          <a onClick={handleTermClick}>Terms of Use</a>
          {t('account:accept-terms-ios-2')}
          <a
            className="read-terms"
            target="_blank"
            href={theme.tenant.politique_de_confidentialite_url}
          >
            {'GO-EV Car Share privacy policy'}
          </a>
        </span>
      );
    else
      return (
        <span>
          {t('account:accept-terms')}
          <br />
          <a onClick={handleTermClick}>{t('common:read-more')}</a>
        </span>
      );
  };

  const handleTermClick = () => {
    termsModal.open();
  };

  return (
    <div className="create-account">
      <Typography component="h1">{t('account:signup')}</Typography>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleChange, isSubmitting, errors, touched }) => (
          <Form>
            <div className="form-group">
              <TextField
                name="email"
                placeholder={t('account:email')}
                variant="outlined"
                margin="none"
                value={values.username}
                onChange={handleChange}
                error={touched.username && !!errors.username}
                helperText={
                  touched.username && errors.username ? errors.username : null
                }
                fullWidth
              />
            </div>

            <div className="form-group">
              <PasswordInput
                name="password"
                placeholder={t('account:password')}
                value={values.password}
                onChange={handleChange}
                error={touched.password && !!errors.password}
                helperText={
                  touched.password && errors.password ? errors.password : null
                }
              />
            </div>

            <div className="form-group">
              <PasswordInput
                type="password"
                name="confirmPassword"
                placeholder={t('account:confirm-password')}
                value={values.confirmPassword}
                onChange={handleChange}
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : null
                }
              />
            </div>
            <div className="term form-group">
              <FormControlLabel
                control={
                  <Checkbox
                    name="terms"
                    checked={values.terms}
                    onChange={handleChange}
                    value={true}
                    color="primary"
                  />
                }
                label={term()}
              />
            </div>

            <div className="button-group">
              <Button
                type="submit"
                fullWidth
                disabled={isSubmitting || !values.terms}
                variant="contained"
                color="primary"
              >
                {t('common:next')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <TermsDialog open={termsModal.isOpen} onClose={termsModal.close} />
    </div>
  );
}

export default CreateAccount;
