import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';

const StationAutocomplete = ({ initialValue, onValueChanged }) => {
  const stations = useSelector(state => state.stations.list);

  const [station, setInputValue] = useState([initialValue]);

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      onValueChanged({
        displayText: station ? station.name : '',
        station: station
      });
    }
  }, [station]);

  return (
    <div className="location-input">
      <Paper className="input-wrapper">
        <Autocomplete
          disableClearable
          openOnFocus
          value={Array.isArray(station) ? station[0] : station} // todo check
          id={`${station.id}`}
          className="input-control"
          options={stations}
          getOptionLabel={option => option.name}
          onChange={(event, value) => setInputValue(value)}
          required={true}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              variant="standard"
            />
          )}
        />
      </Paper>
    </div>
  );
};

export default StationAutocomplete;
