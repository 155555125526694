import config from '../../config';
import httpClient from '../httpClient';

export default {
  getUserTokenByOidcToken({ oidcToken }) {
    return httpClient().post('/oauth2/token', {
      openid_token: oidcToken,
      grant_type: 'password',
      client_id: config.api.clientId,
      client_secret: config.api.clientSecret
    });
  },
  getUserToken({ username, password }) {
    return httpClient().post('/oauth2/token', {
      username,
      password,
      grant_type: 'password',
      client_id: config.api.clientId,
      client_secret: config.api.clientSecret
    });
  },
  authentSSO(id_token) {
    return httpClient('v0.4').post('/sso/openid', {
      openid: {
        id_token
      }
    });
  },
  getGuestToken() {
    return httpClient().post('/oauth2/token', {
      grant_type: 'client_credentials',
      client_id: config.api.clientId,
      client_secret: config.api.clientSecret
    });
  }
};
