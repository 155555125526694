import api from '../../utils/api';

export const TENANT_FETCHED = 'TENANT_FETCHED';
export const FETCH_REGISTRATION_STEP = 'FETCH_REGISTRATION_STEP';
export const FETCH_ADDITIONAL_DOCUMENTS = 'FETCH_ADDITIONAL_DOCUMENTS';

export function fetchRegistrationStep() {
  return async function(dispatch) {
    try {
      const response = await api.tenant.getRegistrationFlows();

      dispatch({
        type: FETCH_REGISTRATION_STEP,
        registrationSteps: response.registration_flows
      });
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAdditionalDocuments() {
  return async function(dispatch) {
    try {
      const responseFr = await api.tenant.getAdditionalDocuments('fr');
      const responseEn = await api.tenant.getAdditionalDocuments('en');

      dispatch({
        type: FETCH_ADDITIONAL_DOCUMENTS,
        additionalDocumentsFr: responseFr.additional_documents,
        additionalDocumentsEn: responseEn.additional_documents
      });
    } catch (error) {
      throw error;
    }
  };
}

export function fetchTenant() {
  return async function(dispatch) {
    try {
      const response = await api.tenant.get();

      dispatch({
        type: TENANT_FETCHED,
        tenant: response.tenant
      });
    } catch (error) {
      throw error;
    }
  };
}
