import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';

const AccountTextInput = ({
  handleChange,
  values,
  errors,
  touched,
  keyName,
  driverKey,
  Keylabel
}) => {
  const { t } = useTranslation(['account']);
  const tenant = useSelector(state => state.tenant);

  return (
    <div className="form-group">
      {tenant.driverFields.includes(driverKey) && (
        <TextField
          required={tenant.requiredDriverFields.includes(driverKey)}
          id={keyName}
          name={keyName}
          label={t(Keylabel)}
          variant="outlined"
          margin="none"
          onChange={handleChange}
          value={values[keyName]}
          error={touched[keyName] && !!errors[keyName]}
          helperText={
            touched[keyName] && errors[keyName] ? errors[keyName] : null
          }
          fullWidth
        />
      )}
    </div>
  );
};

export default AccountTextInput;
