import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import config from '../../config';
import { getAccountDetails } from '../../store/selectors/account';

function AddressCustomInput({
  onChange,
  errors = null,
  touched = null,
  values = null,
  handleChange = null
}) {
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.tenant);
  const accountDetails = useSelector(getAccountDetails);
  const { t } = useTranslation(['account']);

  return (
    <div
      className="address-input"
      aria-labelledby="address-input"
      fullWidth
      maxWidth="sm"
    >
      <Typography component="h2">{t('account:address')}</Typography>

      <div>
        {tenant.driverFields.includes('address_line1') && (
          <div className="form-group">
            <TextField
              id="addressLine1"
              name="addressLine1"
              required={tenant.requiredDriverFields.includes('address_line1')}
              variant="outlined"
              label={t('account:address_line1')}
              fullWidth
              placeholder={t('account:address_line1')}
              value={values.addressLine1}
              onChange={handleChange}
              error={touched.addressLine1 && !!errors.addressLine1}
              helperText={
                touched.addressLine1 && errors.addressLine1
                  ? errors.addressLine1
                  : null
              }
            />
          </div>
        )}

        {tenant.driverFields.includes('address_line2') && (
          <div className="form-group">
            <TextField
              id="addressLine2"
              name="addressLine2"
              required={tenant.requiredDriverFields.includes('address_line2')}
              variant="outlined"
              label={t('account:additional-address')}
              fullWidth
              placeholder={t('account:additional-address-placeholder')}
              value={values.addressLine2}
              onChange={handleChange}
              error={touched.addressLine2 && !!errors.addressLine2}
              helperText={
                touched.address_line2 && errors.addressLine2
                  ? errors.addressLine2
                  : null
              }
            />
          </div>
        )}

        <br></br>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={12} md={6}>
            {tenant.driverFields.includes('address_postal_code') && (
              <div className="form-group">
                <TextField
                  id="addressPostalCode"
                  name="addressPostalCode"
                  required={tenant.requiredDriverFields.includes(
                    'address_postal_code'
                  )}
                  variant="outlined"
                  label={t('account:address_postal_code')}
                  fullWidth
                  placeholder={t('account:address_postal_code')}
                  value={values.addressPostalCode}
                  onChange={handleChange}
                  error={
                    touched.addressPostalCode && !!errors.addressPostalCode
                  }
                  helperText={
                    touched.addressPostalCode && errors.addressPostalCode
                      ? errors.addressPostalCode
                      : null
                  }
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {tenant.driverFields.includes('address_locality') && (
              <div className="form-group">
                <TextField
                  id="addressLocality"
                  name="addressLocality"
                  required={tenant.requiredDriverFields.includes(
                    'address_locality'
                  )}
                  variant="outlined"
                  label={t('account:address_locality')}
                  fullWidth
                  placeholder={t('account:address_locality')}
                  value={values.addressLocality}
                  onChange={handleChange}
                  error={touched.addressLocality && !!errors.addressLocality}
                  helperText={
                    touched.addressLocality && errors.addressLocality
                      ? errors.addressLocality
                      : null
                  }
                />
              </div>
            )}
          </Grid>
        </Grid>

        {tenant.driverFields.includes('address_state') && (
          <div className="form-group">
            <TextField
              id="addressState"
              name="addressState"
              required={tenant.requiredDriverFields.includes('address_state')}
              variant="outlined"
              label={t('account:address_state')}
              fullWidth
              placeholder={t('account:address_state')}
              value={values.addressState}
              onChange={handleChange}
              error={touched.addressState && !!errors.addressState}
              helperText={
                touched.addressState && errors.addressState
                  ? errors.addressState
                  : null
              }
            />
          </div>
        )}

        {tenant.driverFields.includes('address_country') && (
          <div className="form-group">
            <TextField
              id="addressCountry"
              name="addressCountry"
              required={tenant.requiredDriverFields.includes('address_country')}
              variant="outlined"
              label={t('account:address_country')}
              fullWidth
              placeholder={t('account:address_country')}
              value={values.addressCountry}
              onChange={handleChange}
              error={touched.addressCountry && !!errors.addressCountry}
              helperText={
                touched.addressCountry && errors.addressCountry
                  ? errors.addressCountry
                  : null
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddressCustomInput;
