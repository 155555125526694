import { useDispatch, useSelector } from 'react-redux';
import { APP_TOGGLE_USER_MENU } from '../store/actions/app';

export const useDrawerMenu = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.app.isUserMenuOpen);

  return {
    isOpen,
    open: () => {
      dispatch({
        type: APP_TOGGLE_USER_MENU,
        status: true
      });
    },
    close: () => {
      dispatch({
        type: APP_TOGGLE_USER_MENU,
        status: false
      });
    }
  }
};
