import api from '../../utils/api';
import { overrideTimezone } from '../../utils/dateHelper';

export const RENTALS_FETCHED = 'RENTALS_FETCHED';
export const CARPOOLINGS_FETCHED = 'CARPOOLINGS_FETCHED';
export const RENTAL_CREATED = 'RENTAL_CREATED';
export const RENTAL_UPDATED = 'RENTAL_UPDATED';
export const PASSENGER_CREATED = 'PASSENGER_CREATED';

export function fetchRentals() {
  return async function(dispatch) {
    try {
      const response = await api.rentals.getAll();

      dispatch({
        type: RENTALS_FETCHED,
        rentals: response.rentals
      });
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllCarpoolings() {
  return async function(dispatch) {
    try {
      const response = await api.rentals.getAllCarpoolings();

      dispatch({
        type: CARPOOLINGS_FETCHED,
        carpoolings: response.rentals
      });
    } catch (error) {
      throw error;
    }
  };
}

export function createRental({
  driverId,
  vehicleId,
  destinationId,
  stationId,
  startTime,
  endTime,
  estimatedDistance,
  rentalLabelId,
  geotracking,
  carpooling,
  carpoolingDestination
}) {
  return async function(dispatch) {
    try {
      const response = await api.rentals.createRental({
        driverId,
        vehicleId,
        destinationId,
        stationId,
        startTime: startTime.format(),
        endTime: endTime.format(),
        estimatedDistance,
        rentalLabelId,
        geotracking,
        carpooling,
        carpoolingDestination
      });
      const rental = response.rental;

      dispatch({
        type: RENTAL_CREATED,
        rental
      });
    } catch (error) {
      throw error;
    }
  };
}

export function createPassenger({ rental }) {
  return async function(dispatch) {
    try {
      await api.rentals.createPassenger(rental.id);

      dispatch({
        type: PASSENGER_CREATED,
        rental
      });
    } catch (error) {
      throw error;
    }
  };
}

export function cancelRental(id) {
  return async function(dispatch) {
    try {
      await api.rentals.cancelRental(id);

      const response = await api.rentals.getOne(id);
      const rental = response.rental;

      dispatch({
        type: RENTAL_UPDATED,
        rental
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateRental({ id, startDate, endDate }) {
  return async function(dispatch) {
    try {
      const response = await api.rentals.updateRental({
        id,
        startTime: overrideTimezone(startDate).format(),
        endTime: overrideTimezone(endDate).format()
      });

      const rental = response.rental;

      dispatch({
        type: RENTAL_UPDATED,
        rental
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updatePrivacyUsage({ id, geotracking }) {
  return async function(dispatch) {
    try {
      const response = await api.rentals.updatePrivacyUsage({
        id,
        geotracking: geotracking
      });

      const rental = response.rental;

      dispatch({
        type: RENTAL_UPDATED,
        rental
      });
      return response.rental;
    } catch (error) {
      throw error;
    }
  };
}
