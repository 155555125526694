import React from 'react';
import Grid from '@material-ui/core/Grid';
import { chunk } from 'lodash';
import { useTranslation } from 'react-i18next';

const getLabel = (option = '') => {
  const translationKey = option.replace('_', '-').toLowerCase();
  return `booking:option-${translationKey}`;
}

const Equipments = ({ equipments = [] }) => {
  const { t } = useTranslation(['booking']);

  const listSize = Math.ceil(equipments.length / 2);
  const lists = chunk(equipments, listSize);

  return (
    <Grid container spacing={2}>
      {
        lists.map((options, index) => (
          <Grid item xs={6} key={`list-${index}`}>
            <ul>
              {
                options.map((option, i) => (
                  <li key={`option-${index}-${i}`}>{t(getLabel(option))}</li>
                ))
              }
            </ul>
          </Grid>
        ))
      }
    </Grid>
  );
};

export default Equipments;
