import React from 'react';

const Photo = ({ image }) => {
  return (
    <div className="vehicle-image">
      {image ? <img src={image.medium_url} alt="" /> : null}
    </div>
  );
};

export default Photo;
