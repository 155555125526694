import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTheme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { all } from '@fortawesome/fontawesome-free/js/all.js';

import CategoryName from '../shared/CategoryName';
import Price from '../shared/Price';
import fuelIcon from '../../assets/images/vehicle/fuel-icon.svg';
import chargeIcon from '../../assets/images/vehicle/charge-icon.svg';
import { distance } from '../../utils/distanceHelper';
import moment from 'moment-timezone';
import { rentalDateMonthFormat } from '../../utils/variables';
import { useVehicleLoader } from '../../hooks/vehicleLoader';
import AsyncContent from '../../components/shared/AsyncContent';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  media: {
    height: '50%',
    height: 0,
    paddingTop: '55.66%'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  battery_value: {
    fontSize: '0.7em',
    marginRight: '10px'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {}
}));

const BookingCard = ({ startTime, endTime, rental, stationId, onClick }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const formattedStartTime = moment(startTime).format(rentalDateMonthFormat);
  const formattedEndTime = moment(endTime).format(rentalDateMonthFormat);
  const { vehicle } = useVehicleLoader(rental.vehicle_id);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const tenant = useSelector(state => state.tenant);
  const { t } = useTranslation(['common']);
  const licensePlate = vehicle.license_plate;
  const internalIdentifier = vehicle.internal_identifier;

  useEffect(() => {
    if (vehicle.id) setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  const fuelType = () => {
    if (vehicle.fuel_type === 'ELECTRIC')
      return <i className="fas fa-charging-station"></i>;
    if (vehicle.fuel_type === 'GASOLINE')
      return <i className="fas fa-gas-pump"></i>;
    if (vehicle.fuel_type === 'DIESEL')
      return <i className="fas fa-gas-pump"></i>;
  };

  // const batteryLevel = () => {
  //   if (vehicle.fuel_type === 'ELECTRIC')
  //     return <i className="fas fa-battery-full"></i>;
  //   if (vehicle.fuel_type === 'GASOLINE')
  //     return <i className="fas fa-battery-quarter"></i>;
  // };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <AsyncContent loading={!isLoaded}>
        <CardHeader
          onClick={onClick}
          avatar={
            <Avatar
              style={{ backgroundColor: theme.palette.primary.main }}
              aria-label="recipe"
              className={classes.avatar}
            >
              {fuelType()}
            </Avatar>
          }
          // action={
          //   <IconButton aria-label="settings">
          //   { vehicle.fuel_type == 'ELECTRIC' ? (<span className={classes.battery_value}>97 %</span>) : <span className={classes.battery_value}>33 %</span> }
          //   { batteryLevel() }
          //   </IconButton>
          // }
          title={`${vehicle.make} ${vehicle.model} - ${vehicle.year}`}
          subheader={`${vehicle.internal_identifier} - ${vehicle.license_plate}`}
        />
        <CardContent onClick={onClick}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={6} sm={6} md={6}>
              {vehicle.thumbnail_url ? (
                <CardMedia
                  className={classes.media}
                  image={vehicle.thumbnail_url}
                  title="Paella dish"
                />
              ) : null}
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              {_.includes(vehicle.options, 'GPS') ? (
                <Tooltip title="GPS" placement="top-start">
                  <IconButton aria-label="add to favorites">
                    <i className="fas fa-map-marked-alt"></i>
                  </IconButton>
                </Tooltip>
              ) : null}
              {_.includes(vehicle.options, 'USB_PLUG') ? (
                <Tooltip title="USB" placement="top-start">
                  <IconButton aria-label="add to favorites">
                    <i className="fab fa-usb"></i>
                  </IconButton>
                </Tooltip>
              ) : null}
              {_.includes(vehicle.options, 'BLUETOOTH') ? (
                <Tooltip title="BLUETOOTH" placement="top-start">
                  <IconButton aria-label="share">
                    <i className="fab fa-bluetooth"></i>
                  </IconButton>
                </Tooltip>
              ) : null}
              {_.includes(vehicle.options, 'AIR_CONDITIONING') ? (
                <Tooltip title="AIR CONDITIONING" placement="top-start">
                  <IconButton aria-label="share">
                    <i className="fas fa-fan"></i>
                  </IconButton>
                </Tooltip>
              ) : null}
              <Typography variant="h6" gutterBottom>
                {`${formattedStartTime} - ${formattedEndTime}`}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {vehicle.description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </AsyncContent>
    </Card>
  );
};

export default BookingCard;
