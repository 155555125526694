import React from 'react';
import { Container } from '@material-ui/core';

import { useCustomContent } from '../../../hooks/customContent';
import CustomContent from '../../../components/shared/CustomContent';
import config from '../../../config';

const FaqPage = ({ history }) => {
  const page = useCustomContent('faq');

  const handleReturn = () => {
    history.push(config.auth.rootUrl);
  };

  return (
    <div>
      <Container maxWidth="md" className="account-page-container">
        <CustomContent handleReturn={handleReturn} {...page} />
      </Container>
    </div>
  );
};

export default FaqPage;
