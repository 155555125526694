import React from 'react';
import { Container } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import closeIcon from '../../../assets/images/close.svg';
import { useTermsAndConditions } from '../../../hooks/termsAndConditions';
import { useCustomContent } from '../../../hooks/customContent';
import CustomContent from '../../../components/shared/CustomContent';
import config from '../../../config';

const CguPage = ({ history }) => {
  const termsAndConditions = useTermsAndConditions();

  const handleReturn = () => {
    history.push(config.auth.rootUrl);
  };

  return (
    <Container maxWidth="md" className="account-page-container">
      <div className="custom-content">
        <div className="page-header">
          <div className="close-button">
            <IconButton onClick={handleReturn}>
              <img src={closeIcon} alt="" width="30" height="30" />
            </IconButton>
          </div>
        </div>

        {termsAndConditions[0] ? (
          <div
            dangerouslySetInnerHTML={{
              __html: decodeURIComponent(termsAndConditions[0].content)
            }}
          ></div>
        ) : null}
      </div>
    </Container>
  );
};

export default CguPage;
