import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import moment from 'moment-timezone';
import { nextQuarter, overrideTimezone } from '../../utils/dateHelper';
import { datePickerFormat } from '../../utils/variables';

registerLocale('fr', fr);

const DateRangeInput = ({
  instantSearch,
  initialValue,
  onValueChanged,
  minimumReservationDuration = 901,
  timezone = 'America/Montreal',
  withPortal = false,
  language = 'fr'
}) => {
  const { t } = useTranslation(['common']);
  const [startDate, setStartDate] = useState(initialValue.startDate);
  const [endDate, setEndDate] = useState(initialValue.endDate);
  const tenant = useSelector(state => state.tenant);
  moment.tz.setDefault(tenant.timezone);

  const adaptedEndDate = new Date(
    nextQuarter(
      moment(startDate).add(minimumReservationDuration, 'seconds')
    ).format()
  );

  const isStartDateToday = overrideTimezone(startDate).isSame(moment(), 'day');
  const isEndDateToday = overrideTimezone(endDate).isSame(moment(), 'day');
  const minStartTime = setHours(
    setMinutes(
      new Date(),
      isStartDateToday ? initialValue.minStartDate.getMinutes() : 0
    ),
    isStartDateToday ? initialValue.minStartDate.getHours() : 0
  );

  const minEndTime = setHours(
    setMinutes(new Date(), isEndDateToday ? adaptedEndDate.getMinutes() : 0),
    isEndDateToday ? adaptedEndDate.getHours() : 0
  );

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      onValueChanged({
        startDate,
        endDate
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const StartDateInput = ({ value, onClick }, ref) => (
    <InputBase
      inputRef={ref}
      value={value}
      className="input-control"
      onClick={onClick}
    />
  );

  const EndDateInput = ({ value, onClick }, ref) => (
    <InputBase
      inputRef={ref}
      value={value}
      className="input-control"
      onClick={onClick}
    />
  );

  const instantSearchMinStartTime = () => {
    if (!instantSearch || !isStartDateToday) return minStartTime;
    return moment(moment().format(datePickerFormat)).toDate();
  };

  const injetTimeInstantSearch = () => {
    if (!instantSearch || !isStartDateToday) return [];
    let injectTimes = [...Array(15)].map((_, i) =>
      setMinutes(minStartTime, minStartTime.getMinutes() + i)
    );
    injectTimes = injectTimes.concat(
      [...Array(15)].map((_, i) =>
        setMinutes(minStartTime, minStartTime.getMinutes() - i)
      )
    );
    return injectTimes;
  };

  const handleChangeStartDate = date => {
    setStartDate(date);
    let eDate = new Date(
      nextQuarter(
        moment(date).add(minimumReservationDuration, 'seconds')
      ).format()
    );
    setEndDate(eDate);
  };

  const CustomStartInput = forwardRef(StartDateInput);
  const CustomEndInput = forwardRef(EndDateInput);

  return (
    <div className="date-range-input">
      <Paper className="input-wrapper">
        <DatePicker
          locale={language}
          selected={startDate}
          onChange={date => handleChangeStartDate(date)}
          showTimeSelect
          timeCaption={t('common:time')}
          timeFormat="HH:mm"
          timeIntervals={15}
          injectTimes={injetTimeInstantSearch()}
          dateFormat="d MMM yyyy HH:mm"
          customInput={<CustomStartInput />}
          minTime={instantSearchMinStartTime()}
          maxTime={setHours(setMinutes(new Date(), 45), 23)}
          minDate={initialValue.minStartDate}
          maxDate={initialValue.maxStartDate}
          withPortal={withPortal}
        />
        <Divider orientation="vertical" className="input-divider" />
        <DatePicker
          locale={language}
          selected={endDate}
          onChange={date => setEndDate(date)}
          showTimeSelect
          timeCaption={t('common:time')}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="d MMM yyyy HH:mm"
          customInput={<CustomEndInput />}
          minDate={adaptedEndDate}
          maxDate={initialValue.maxEndDate}
          minTime={minEndTime}
          maxTime={setHours(setMinutes(new Date(), 45), 23)}
          popperPlacement="auto"
          popperModifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false
            }
          }}
          withPortal={withPortal}
        />
      </Paper>
    </div>
  );
};

export default DateRangeInput;
