import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Routes from './Routes';
import { authenticateUserOidcToken } from './store/actions/auth';
import { fetchUserData } from './store/actions/account';
import CustomContentDialog from './components/content/CustomContentDialog';
import AcceptTermsAndConditionsDialog from './components/content/AcceptTermsAndConditionsDialog';
import CookieConsentModal from './components/content/CookieConsentModal';
import { useModal } from './utils/modal';

const AppRoutes = props => {
  const tenant = props.tenant;
  const { t } = useTranslation(['account']);
  const termsModal = useModal();
  const cookieModal = useModal();
  const welcomeModal = useModal();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const tosAccepted = useSelector(state => state.account.tosAccepted);
  const isLoaded = useSelector(state => state.account.isLoaded);

  useEffect(() => {
    loadModal();
  }, [isAuthenticated, tosAccepted]);

  useEffect(() => {
    if (localStorage.getItem('showWelcomeModal') == null) welcomeModal.open();
  }, [isAuthenticated, tosAccepted]);

  const loadModal = async () => {
    if (isAuthenticated) {
      if (tenant.useTermsAndConditions && isLoaded && !tosAccepted) {
        termsModal.open();
      }
      var showCookieModal = document.cookie.match(
        '(^|;) ?' + 'cookieModalShown' + '=([^;]*)(;|$)'
      );
      if (tenant.useCookieConsent && !showCookieModal) {
        cookieModal.open();
      }
    }
  };

  const handleConfirm = async () => {
    welcomeModal.close();
    localStorage.setItem('showWelcomeModal', true);
  };

  return (
    <div>
      <AcceptTermsAndConditionsDialog
        open={termsModal.isOpen}
        onClose={termsModal.close}
      />
      <CookieConsentModal
        open={cookieModal.isOpen}
        onClose={cookieModal.close}
      />
      <CustomContentDialog
        title={t('account:welcome-fo-title')}
        content={t('account:welcome-fo-content')}
        button={t('account:welcome-fo-button')}
        handleConfirm={handleConfirm}
        open={welcomeModal.isOpen}
        onClose={welcomeModal.close}
      />
      <Routes {...props} />
    </div>
  );
};

export default AppRoutes;
