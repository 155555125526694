import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from './../config';
import { authentSSO } from '../store/actions/auth';
import { fetchUserData } from '../store/actions/account';
import { getParameterByName } from '../utils/paramsHelper';

function LoginCallback({ match, history }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    const loadData = async () => {
      let token = getParameterByName('id_token');
      localStorage.setItem('id_token', token);
      await dispatch(authentSSO(token));
      await dispatch(fetchUserData());
    };

    if (!isAuthenticated) {
      loadData();
    }
    history.push(config.auth.rootUrl);
  }, [isAuthenticated]);

  return <div></div>;
}

export default withRouter(LoginCallback);
