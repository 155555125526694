import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FileUploadButton from '../shared/FileUploadButton';
import api from '../../utils/api';
import { useErrorToaster } from '../../utils/errorToaster';
import cardIcon from '../../assets/images/icon-cards.svg';
import idImage from '../../assets/images/id.svg';
import HelpModal from '../shared/HelpModal';
import { fetchUserData } from '../../store/actions/account';
import { useModal } from '../../utils/modal';

function IdentityDocuments() {
  const { t } = useTranslation(['account', 'common']);
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const [isLoading, setIsLoading] = useState(false);
  const modal = useModal();

  const [uploadStatus, setUploadStatus] = useState({
    identity_front: false,
    identity_back: false
  });

  const handleDocumentUpload = async (
    type,
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();
    setIsLoading(true);

    try {
      await api.user.addDocument(type, file, {
        onUploadProgress: event => {
          const percent = Math.round((event.loaded * 100) / event.total);
          setProgress(percent);
        }
      });

      setIsLoading(false);
      setCompleted();

      setUploadStatus({
        ...uploadStatus,
        [type]: true
      });
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  const handleNextButton = async () => {
    try {
      await dispatch(fetchUserData());
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="identity-document doc-upload-view">
      <div className="step-illustration">
        <img src={idImage} alt="" width="240" height="170" />
      </div>

      <Typography component="h1">{t('account:id-card')}</Typography>

      <div className="subtitle">{t('account:upload-id-card')}</div>

      <div className="about-button">
        <button type="button" className="help-button" onClick={modal.open}>
          {t('account:explain-me-why')}
        </button>
      </div>

      <div className="upload-buttons">
        <FileUploadButton
          className="upload-button"
          image={<img src={cardIcon} alt="" width="100" height="76" />}
          onFileSelected={(file, callbacks) =>
            handleDocumentUpload('identity_front', file, callbacks)
          }
          accept={'image/*, application/pdf'}
          disabled={isLoading}
        >
          {t('account:recto')}
        </FileUploadButton>

        <FileUploadButton
          className="upload-button"
          image={<img src={cardIcon} alt="" width="100" height="76" />}
          onFileSelected={(file, callbacks) =>
            handleDocumentUpload('identity_back', file, callbacks)
          }
          accept={'image/*, application/pdf'}
          disabled={isLoading}
        >
          {t('account:verso')}
        </FileUploadButton>
      </div>

      <div className="button-group">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!uploadStatus.identity_front || !uploadStatus.identity_back}
          onClick={handleNextButton}
        >
          {t('common:next')}
        </Button>
      </div>

      <HelpModal open={modal.isOpen} onClose={modal.close} />
    </div>
  );
}

export default IdentityDocuments;
