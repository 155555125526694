import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';

import { useCustomContent } from '../../hooks/customContent';
import HeaderFooterLayout from '../../components/layout/HeaderFooterLayout';
import config from '../../config';
import CustomContent from '../../components/shared/CustomContent';

function WaitingPage({ history }) {
  const { t } = useTranslation(['account']);
  const user = useSelector(state => state.account);
  const page = useCustomContent('contact');

  const handleBackOfficeLink = () => {
    window.location.href = '/backoffice';
  };

  return (
    <div className="waiting-page">
      <HeaderFooterLayout>
        <div className="content-wrapper">
          <div className="message">
            <p>{t('account:agent-review-and-no-register-flow')}</p>
            {page && page.content ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(page.content)
                }}
              ></div>
            ) : null}
            {user.canAccessBackOffice ? (
              <p>
                {t('account:agent-review-can-access-to-backoffice')}
                <a className="action-link" onClick={handleBackOfficeLink}>
                  {' '}
                  {t('account:click-custom-address')}{' '}
                </a>
              </p>
            ) : null}
          </div>
        </div>
      </HeaderFooterLayout>
    </div>
  );
}

export default withRouter(WaitingPage);
