import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import MemberMenu from '../nav/MemberMenu';
import GuestMenu from '../nav/GuestMenu';
import { useDrawerMenu } from '../../hooks/drawerMenu';
import { useSelector } from 'react-redux';

const PageWithDrawerMenu = ({ children }) => {
  const drawerMenu = useDrawerMenu();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const handleClose = () => {
    drawerMenu.close();
  };

  useEffect(
    () => () => {
      drawerMenu.close();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  return (
    <div className="page-with-drawer-menu">
      <Drawer
        open={drawerMenu.isOpen}
        onClose={handleClose}
        className="drawer-menu-container"
      >
        {isAuthenticated ? (
          <MemberMenu onClose={handleClose} />
        ) : (
          <GuestMenu onClose={handleClose} />
        )}
      </Drawer>

      {children}
    </div>
  );
};

export default PageWithDrawerMenu;
