import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';

import { fullSearchFilterDateFormat } from '../../../utils/variables';
import { getSearchDates } from '../../../store/selectors/search';

const DateFilter = props => {
  const search = useSelector(getSearchDates);
  const startDate = search.startDate;
  const endDate = search.endDate;

  if (startDate && endDate) {
    return (
      <div style={{ display: 'inline' }} {...props}>
        <Chip
          label={
            moment(startDate).format(fullSearchFilterDateFormat) +
            ' - ' +
            moment(endDate).format(fullSearchFilterDateFormat)
          }
          color="primary"
          variant="outlined"
        />
      </div>
    );
  } else {
    return null;
  }
};

export default DateFilter;
