import React from 'react';

const symbols = {
  EUR: '€',
  USD: '$',
  CAD: '$',
  GBP: '£'
};

const Price = ({ cents, currency = 'EUR' }) => {
  if (cents === 0) {
    return null;
  } else {
    if (currency === 'EUR') {
      return (
        <div className="inline price">
          <span>
            {cents / 100} {symbols[currency]}
          </span>
        </div>
      );
    } else {
      return (
        <div className="inline price">
          <span>
            {symbols[currency]}
            {cents / 100}
          </span>
        </div>
      );
    }
  }
};

export default Price;
