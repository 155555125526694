import account from './fr/account';
import payment_method from './fr/payment_method';
import booking from './fr/booking';
import common from './fr/common';
import footer from './fr/footer';
import home from './fr/home';
import search from './fr/search';
import validation from './fr/validation';

export default {
  account,
  payment_method,
  booking,
  common,
  footer,
  home,
  search,
  validation
};
