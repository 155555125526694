import { getCurrentProcessSteps } from '../../utils/stepMachine';
import moment from 'moment-timezone';

export const getCompletedSteps = state => {
  const currentStep = state.account.registrationStep;
  const steps = getCurrentProcessSteps(currentStep);
  const index = steps.indexOf(currentStep);

  return index > 0 ? steps.slice(0, index) : [];
};

export const getAccountDetails = dateFormat => state => {
  const format = date =>
    dateFormat ? moment(date).format(dateFormat.replace(/\//g, '-')) : date;
  const {
    email,
    firstName,
    lastName,
    birthdayLocation,
    birthdayTime,
    addressLine1,
    addressLine2,
    addressPostalCode,
    addressLocality,
    addressState,
    addressCountry,
    phoneNumber,
    newPhoneNumber,
    licenceExpirationTime,
    licenceLocation,
    licenceNumber,
    licenceObtainingTime,
    creditCard,
    avatar
  } = state.account;

  return {
    email,
    firstName,
    lastName,
    birthdayLocation,
    birthdayTime: format(birthdayTime),
    addressLine1,
    addressLine2,
    addressPostalCode,
    addressLocality,
    addressState,
    addressCountry,
    phoneNumber,
    newPhoneNumber,
    licenceExpirationTime: format(licenceExpirationTime),
    licenceLocation,
    licenceNumber,
    licenceObtainingTime: format(licenceObtainingTime),
    creditCard,
    avatar
  };
};
