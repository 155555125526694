import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Options from './Options';
import ElectricOptions from './ElectricOptions';
import StationLocation from './StationLocation';
import Equipments from './Equipments';
import CarpoolingInfo from '../../rentals/carpoolings//CarpoolingInfo';
import CategoryName from '../../shared/CategoryName';

const VehicleDetails = ({ vehicle = {}, station = {}, driver = {} }) => {
  const { t } = useTranslation(['booking', 'common', 'search']);
  const tenant = useSelector(state => state.tenant);
  const useEstimatedDistance = tenant.useEstimatedDistance;
  const useEstimatedDestinationField = tenant.useEstimatedDestinationField;
  const searchState = useSelector(state => state.search);
  const rental = useSelector(state => state.search.rental);
  const userId = useSelector(state => state.account.id);
  const btob = tenant.btob;

  return (
    <div className="vehicle-details">
      <div className="sections">
        <div className="section vehicle-summary">
          <div className="category">
            {vehicle.category_id ? (
              <CategoryName categoryId={vehicle.category_id}></CategoryName>
            ) : null}
          </div>
          <div className="model">
            {vehicle.make} {vehicle.model} {vehicle.year}
          </div>

          <Options
            doors={vehicle.doors_count}
            seats={vehicle.seats_count}
            fuel={vehicle.fuel_type}
            transmission={vehicle.gears_type}
          />
        </div>

        <div className={driver && driver.id === userId ? 'hidden' : ''}>
          <CarpoolingInfo driver={driver} rental={rental} />
        </div>

        {btob && vehicle.fuel_type === 'ELECTRIC' ? (
          <div className="section vehicle-summary">
            <div className="title">{t('booking:vehicle-soc')}</div>
            <div className="section-content">
              <ElectricOptions
                unitAutonomy={tenant.distanceUnit}
                stateOfCharge={vehicle.box_state_of_charge}
                chargingStatus={vehicle.box_charging_status}
                autonomy={vehicle.box_autonomy}
              />
            </div>
          </div>
        ) : null}

        {btob ? (
          <div
            className={
              vehicle.internal_identifier || vehicle.license_plate
                ? 'section flex-row'
                : 'hidden'
            }
          >
            <div className={vehicle.internal_identifier ? '' : 'hidden'}>
              <div className="title">{t('booking:internal_identifier')}</div>
              <div className="section-content">
                {vehicle.internal_identifier}
              </div>
            </div>
            <div className={vehicle.license_plate ? '' : 'hidden'}>
              <div className="title">{t('booking:plate-number')}</div>
              <div className="section-content">{vehicle.license_plate}</div>
            </div>
          </div>
        ) : null}

        <div
          className={
            Array.isArray(vehicle.options) && vehicle.options.length > 0
              ? 'section'
              : 'hidden'
          }
        >
          <div className="title">{t('booking:equipments')}</div>
          <div className="section-content">
            <Equipments equipments={vehicle.options} />
          </div>
        </div>

        {(vehicle.power && vehicle.power != 0) ||
        vehicle.payload_capacity ||
        vehicle.volume ? (
          <div className="section flex-row">
            {vehicle.power && vehicle.power != 0 ? (
              <div>
                <div className="title">{t('booking:power')}</div>
                <div className="section-content">{vehicle.power}</div>
              </div>
            ) : null}

            {vehicle.payload_capacity ? (
              <div>
                <div className="title">{t('booking:payload_capacity')}</div>
                <div className="section-content">
                  {vehicle.payload_capacity}
                </div>
              </div>
            ) : null}

            {vehicle.volume ? (
              <div>
                <div className="title">{t('booking:volume')}</div>
                <div className="section-content">{vehicle.volume}</div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className={vehicle.description ? 'section' : 'hidden'}>
          <div className="title">{t('booking:description')}</div>
          <div className="section-content">{vehicle.description}</div>
        </div>

        <div className="hidden section">
          <div className="title">{t('booking:included-kilometers')}</div>
          <div className="section-content">
            <span className="kilometers">XXX</span> Km/{t('common:day')}
          </div>
        </div>

        {useEstimatedDistance &&
        useEstimatedDestinationField &&
        searchState &&
        searchState.destination ? (
          <div className="section">
            <div className="title">{t('search:destination')}</div>
            <div className="section-content">
              {searchState.destination.formatted_address}
            </div>
            <div className="section-content">
              {searchState.destination.label}
            </div>
          </div>
        ) : null}

        <div className="section">
          <div className="title">
            {btob ? station.name : t('booking:address')}
          </div>
          <div className="section-content">
            {station.address ? station.address : null}
          </div>
          {station.latitude && station.longitude ? (
            <StationLocation
              latitude={station.latitude}
              longitude={station.longitude}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
