import { find } from 'lodash';
import {
  RENTAL_CREATED,
  RENTAL_UPDATED,
  RENTALS_FETCHED,
  CARPOOLINGS_FETCHED,
  PASSENGER_CREATED
} from '../actions/rentals';

const initialState = {
  list: [],
  carpoolings: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RENTALS_FETCHED:
      return {
        ...state,
        list: action.rentals
      };

    case CARPOOLINGS_FETCHED:
      return {
        ...state,
        carpoolings: action.carpoolings
      };

    case RENTAL_CREATED:
      return {
        ...state,
        list: [...state.list, action.rental]
      };

    case PASSENGER_CREATED:
      return {
        ...state
      };

    case RENTAL_UPDATED:
      const list = [...state.list];
      const rental = find(state.list, { id: action.rental.id });
      const index = rental ? state.list.indexOf(rental) : -1;
      if (index > -1) {
        list.splice(index, 1, action.rental);
      }

      return {
        ...state,
        list
      };

    default:
      return state;
  }
};
