import React from 'react';

function ContainerWithLeftPanel({ panel, main }) {
  return (
    <div className="container-with-left-panel">
      <div className="left-panel">
        {panel}
      </div>
      <div className="main-container">
        {main}
      </div>
    </div>
  );
}

export default ContainerWithLeftPanel;
