import { find } from 'lodash';
import { duration, overrideTimezone } from '../../utils/dateHelper';

export const getVehicleSearchDuration = state => {
  const { startDate, endDate } = state.search;
  const lang = state.app.language;

  return duration(startDate, endDate, lang);
};

export const getSelectedSearchResult = state => {
  return state.search.selectedResult
    ? find(
        state.search.results,
        o => Number(o.vehicle.id) === Number(state.search.selectedResult)
      )
    : null;
};

export const getSearchDates = state => {
  return {
    startDate: overrideTimezone(state.search.startDate),
    endDate: overrideTimezone(state.search.endDate)
  };
};
