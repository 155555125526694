import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import _ from 'lodash';

import { stepMapping } from '../utils/stepMachine';
import { getCompletedSteps } from '../store/selectors/account';
import MultiStepForm from '../components/layout/MultiStepForm';
import CreateAccount from '../components/auth/signup/CreateAccount';
import UserDetails from '../components/auth/signup/UserDetails';
import PhoneNumber from '../components/auth/signup/PhoneNumber';
import DriverDetails from '../components/identity/DriverDetails';
import DriverLicense from '../components/identity/DriverLicense';
import IdentityDocuments from '../components/identity/IdentityDocuments';
import SelfieDocument from '../components/identity/SelfieDocument';
import OtherDocument from '../components/identity/OtherDocument';
import CreditCard from '../components/identity/CreditCard';
import HeaderFooterLayout from '../components/layout/HeaderFooterLayout';
import ProcessCompleteMessage from '../components/identity/ProcessCompleteMessage';
import { fetchRegistrationStep } from '../store/actions/tenant';
import config from '../config';
import { getParameterByName } from '../utils/paramsHelper';

function SignupPage({ history }) {
  const { t } = useTranslation(['account']);
  const currentStep = useSelector(state => state.account.registrationStep);
  const language = useSelector(state => state.app.language);
  const tenant = useSelector(state => state.tenant);
  const dispatch = useDispatch();
  const isRegistrationComplete = useSelector(
    state => state.account.isRegistrationComplete
  );

  const isPendingValidation = currentStep === 'pendingValidation';
  const completedSteps = useSelector(getCompletedSteps);

  if (isRegistrationComplete) {
    let redirect_uri = getParameterByName('redirect_uri');
    const access_token = localStorage.getItem(config.auth.accessTokenKey);

    if (redirect_uri)
      window.location.href =
        decodeURIComponent(redirect_uri) + '?access_token=' + access_token;
    else history.replace(config.auth.rootUrl);
  }

  const loadRegistrationStep = async () => {
    try {
      await dispatch(fetchRegistrationStep());
    } catch (error) {
      console.error('Error while registration steps.');
    }
  };

  useEffect(() => {
    loadRegistrationStep();
  }, []);

  const steps = {
    registration: t('account:signup'),
    personalDetails: t('account:personal-info'),
    phoneNumber: t('account:phone-verification'),
    licenseDetails: t('account:driver-details'),
    driverLicense: t('account:driver-license'),
    idDocument: t('account:id-card'),
    selfieDocument: t('account:id-photo'),
    otherDocument: t('account:other-photo'),
    creditCard: t('account:payment-method')
  };

  const components = {
    registration: CreateAccount,
    personalDetails: UserDetails,
    phoneNumber: PhoneNumber,
    licenseDetails: DriverDetails,
    driverLicense: DriverLicense,
    idDocument: IdentityDocuments,
    selfieDocument: SelfieDocument,
    otherDocument: OtherDocument,
    creditCard: CreditCard
  };

  const stepMappingPicked = _.map(
    _.pickBy(stepMapping, (value, key) =>
      tenant.registrationSteps.includes(key)
    ),
    function(key) {
      return key;
    }
  );

  const usedComponents = _.pickBy(components, (value, key) =>
    stepMappingPicked.includes(key)
  );
  const usedSteps = _.pickBy(steps, (value, key) =>
    stepMappingPicked.includes(key)
  );

  const completeComponent = (
    <div className="complete-message">
      <HeaderFooterLayout>
        <ProcessCompleteMessage />
      </HeaderFooterLayout>
    </div>
  );

  const formComponent = (
    <Elements locale={language}>
      <MultiStepForm
        stepperTitle={t('account:account-creation')}
        steps={usedSteps}
        activeStep={currentStep}
        components={usedComponents}
        completedSteps={completedSteps}
      />
    </Elements>
  );

  return (
    <div className="identity-page signup-page">
      {isPendingValidation ? completeComponent : formComponent}
    </div>
  );
}

export default withRouter(SignupPage);
