import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import config from '../../config';

function LoginOrRegister({ history }) {
  const tenant = useSelector(state => state.tenant);
  const { t } = useTranslation(['account', 'home']);

  const handleSignupClick = () => {
    history.push(`${config.auth.rootUrl}signup`);
  };

  const handleLoginClick = () => {
    history.push(`${config.auth.rootUrl}login`);
  };

  return (
    <Container component="main" maxWidth="xs" className="login-or-register">
      <div className="inner">
        <div className="logo-container">
          <img src={tenant.logo} alt={tenant.human_name} width="269" />
        </div>

        <div className="intro-text">{t('home:quick-signup')}</div>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSignupClick}
        >
          {t('account:signup')}
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleLoginClick}
        >
          {t('account:login')}
        </Button>
      </div>
    </Container>
  );
}

export default withRouter(LoginOrRegister);
