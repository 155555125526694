import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import config from '../../config';
import { resetPassword } from '../../store/actions/account';

const ForgotPassword = ({ history }) => {
  const { t } = useTranslation(['account', 'validation']);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation:required'))
      .email(t('validation:invalid-format'))
  });

  const handleResetPassword = async values => {
    await dispatch(resetPassword(values));
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting,
    dirty,
    isValid
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await handleResetPassword(values);
        resetForm();
        enqueueSnackbar(t('account:email-helper'), { variant: 'success' });
      } catch (error) {
        setSubmitting(false);
        enqueueSnackbar(t('account:login-error'), { variant: 'error' });
      }
    }
  });

  const handleLogin = () => {
    history.push(`${config.auth.rootUrl}login`);
  };

  return (
    <Container component="main" maxWidth="xs" className="login-or-register">
      <div className="inner">
        <Typography component="h1" variant="h5">
          {t('account:forgot-password')}
        </Typography>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <TextField
              name="email"
              placeholder={t('account:email')}
              variant="outlined"
              margin="normal"
              value={values.email}
              onChange={handleChange}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email ? errors.email : null}
              fullWidth
            />

            <div className="links">
              <a onClick={handleLogin}>{t('account:login')}</a>
            </div>
          </div>

          <div className="button-group">
            <Button
              type="submit"
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
              disabled={!(isValid && dirty && !isSubmitting)}
              variant="contained"
              color="primary"
            >
              {t('account:reset')}
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default withRouter(ForgotPassword);
