import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { GoogleApiWrapper } from 'google-maps-react';

import menuIcon from '../../assets/images/menu.svg';
import SearchInput from '../../components/search/SearchInput';
import SearchButton from '../../components/search/SearchButton';
import SearchMap from '../../components/search/SearchMap';
import { useDrawerMenu } from '../../hooks/drawerMenu';
import { locateUser } from '../../store/actions/search';
import config from '../../config';

const MapView = ({ google, handleChangeStations, onSearchInputClick }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userMenu = useDrawerMenu();

  const searchLocationLabel = useSelector(
    state => state.search.location.displayText
  );

  const tenant = useSelector(state => state.tenant);
  const btob = tenant.btob;
  const submited = useSelector(state => state.search.submited);
  const selectedResult = useSelector(state => state.search.selectedResult);
  const mapZoom = useSelector(state => state.search.mapZoom);
  const mapCenter = useSelector(state => state.search.mapCenter);
  const isDefaultCenter = useSelector(state => state.search.isDefaultMapCenter);
  const userLocation = useSelector(state => state.search.userLocation);
  const stations = useSelector(state => state.stations.list);

  const [state, setState] = useState({
    bounds: null
  });

  const initMapCenterBounds = () => {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < stations.length; i++) {
      let place = { lat: stations[i].latitude, lng: stations[i].longitude };
      bounds.extend(place);
    }
    setState({
      ...state,
      bounds: bounds
    });
  };

  useEffect(() => {
    if (mapCenter.lat == null && mapCenter.lng == null && !submited) {
      initMapCenterBounds();
    }
    if (
      isDefaultCenter &&
      userLocation.lat == null &&
      userLocation.long == null
    ) {
      dispatch(locateUser());
    }
  }, []);

  const getMarkers = () => {
    const markers = [];

    stations.forEach(station => {
      if (station.latitude && station.longitude) {
        markers.push({
          id: station.id,
          address: station.address,
          description: station.description,
          name: station.name,
          location: {
            lat: station.latitude,
            lng: station.longitude
          }
        });
      }
    });

    return markers;
  };

  return (
    <div className="map-view">
      <div className="tenant-logo">
        <img src={tenant.logo} alt={tenant.human_name} />
      </div>
      {isMobile ? (
        <Fab className="drawer-icon" onClick={() => userMenu.open()}>
          <img src={menuIcon} alt="" width="30" height="30" />
        </Fab>
      ) : null}
      {isMobile ? (
        tenant.searchEngine === 'station' ? (
          <SearchButton onClick={onSearchInputClick} />
        ) : (
          <SearchInput
            value={searchLocationLabel}
            onClick={onSearchInputClick}
          />
        )
      ) : null}

      <SearchMap
        google={google}
        mapCenter={mapCenter}
        zoom={mapZoom}
        handleChangeStations={handleChangeStations}
        userPosition={{
          lat: userLocation.lat,
          lng: userLocation.long
        }}
        markers={getMarkers()}
        selectedMarker={selectedResult}
        bounds={state.bounds}
      />
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: config.google.mapsApiKey
})(MapView);
