import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

const CustomContentDialog = ({
  title,
  content,
  button,
  handleConfirm,
  open,
  onClose
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['account']);
  const [hasAccepted, setHasAccepted] = useState(false);

  return (
    <Dialog
      scroll="body"
      open={open}
      aria-labelledby="terms-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="terms-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          {button}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomContentDialog;
