import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import FileUploadButton from '../shared/FileUploadButton';
import api from '../../utils/api';
import { useErrorToaster } from '../../utils/errorToaster';
import selfieIcon from '../../assets/images/icon-selfie.svg';
import selfieImage from '../../assets/images/selfie.svg';
import { useDispatch } from 'react-redux';
import { fetchUserData } from '../../store/actions/account';
import HelpModal from '../shared/HelpModal';
import { useModal } from '../../utils/modal';

function SelfieDocument() {
  const { t } = useTranslation(['account', 'common']);
  const dispatch = useDispatch();
  const displayError = useErrorToaster();

  const modal = useModal();

  const [uploadStatus, setUploadStatus] = useState({
    selfie: false
  });

  const handleDocumentUpload = async (
    type,
    file,
    { setStarted, setProgress, setCompleted, resetState }
  ) => {
    setStarted();

    try {
      await api.user.addDocument(type, file, {
        onUploadProgress: event => {
          const percent = Math.round((event.loaded * 100) / event.total);
          setProgress(percent);
        }
      });

      setCompleted();

      setUploadStatus({
        ...uploadStatus,
        [type]: true
      });
    } catch (error) {
      displayError(error);
      resetState();
    }
  };

  const handleNextButton = async () => {
    try {
      await dispatch(fetchUserData());
    } catch (error) {
      displayError(error);
    }
  };

  return (
    <div className="selfie-upload doc-upload-view">
      <div className="step-illustration">
        <img src={selfieImage} alt="" width="240" height="170" />
      </div>

      <Typography component="h1">{t('account:selfie-with-id')}</Typography>

      <div className="subtitle">{t('account:take-id-selfie')}</div>

      <div className="about-button">
        <button type="button" className="help-button" onClick={modal.open}>
          {t('account:explain-me-why')}
        </button>
      </div>

      <div className="upload-buttons">
        <FileUploadButton
          className="upload-button"
          image={<img className="img-selfie-icon" src={selfieIcon} alt="" />}
          onFileSelected={(file, callbacks) =>
            handleDocumentUpload('selfie', file, callbacks)
          }
          accept={'image/*, application/pdf'}
          disabled={false}
        >
          {t('account:load-picture')}
        </FileUploadButton>
      </div>

      <div className="button-group">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!uploadStatus.selfie}
          onClick={handleNextButton}
        >
          {t('common:next')}
        </Button>
      </div>

      <HelpModal open={modal.isOpen} onClose={modal.close} />
    </div>
  );
}

export default SelfieDocument;
