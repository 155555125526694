import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';

import { sendAcceptTos } from '../../store/actions/account';
import { useTermsAndConditions } from '../../hooks/termsAndConditions';
import Button from '@material-ui/core/Button';

const AcceptTermsAndConditionsDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['account']);
  const termsAndConditions = useTermsAndConditions();

  const [hasAccepted, setHasAccepted] = useState(false);

  const handleAccept = async () => {
    if (typeof onClose === 'function') {
      await dispatch(sendAcceptTos(termsAndConditions[0].id));
      onClose();
    }
  };

  const handleChange = event => {
    setHasAccepted(!hasAccepted);
  };

  const term = () => {
    try {
      return decodeURIComponent(termsAndConditions[0].content);
    } catch (e) {
      return;
    }
  };

  return (
    <Dialog
      scroll="body"
      open={open}
      aria-labelledby="terms-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="terms-dialog-title">
        {t('account:tos-title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {termsAndConditions[0] && termsAndConditions[0].content ? (
            <span
              dangerouslySetInnerHTML={{
                __html: term()
              }}
            ></span>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasAccepted}
                onChange={handleChange}
                name="jason"
              />
            }
            label={t('account:tos-text')}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={!hasAccepted} onClick={handleAccept} color="primary">
          {t('account:agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptTermsAndConditionsDialog;
