import { useEffect } from 'react';

export const useScrollRestore = (props) => {
  useEffect(() => {
    const scrollToTop = () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch {
        window.scrollTo(0, 0);
      }
    };

    scrollToTop();
  }, [props]);
};
