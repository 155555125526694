import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Paper, Divider, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import StationLocation from '../../components/vehicles/VehicleDetails/StationLocation';
import TermsDialog from '../../components/content/TermsDialog';
import AskTrackingModal from '../../components/bookings/AskTrackingModal';
import backIcon from '../../assets/images/back.svg';
import { useVehicleLoader } from '../../hooks/vehicleLoader';
import { useStationLoader } from '../../hooks/stationLoader';
import { useScrollRestore } from '../../hooks/scrollRestore';
import { getSelectedSearchResult } from '../../store/selectors/search';
import { SEARCH_RESULT_BOOKING_DISCARDED } from '../../store/actions/search';
import { useModal } from '../../utils/modal';
import RentalTermsModal from '../../components/shared/RentalTermsModal';
import { useErrorToaster } from '../../utils/errorToaster';
import { useDrawerMenu } from '../../hooks/drawerMenu';
import { createRental, createPassenger } from '../../store/actions/rentals';
import { rentalDateFormat } from '../../utils/variables';
import config from '../../config';
import Price from '../../components/shared/Price';
import { getSearchDates } from '../../store/selectors/search';

const BookingView = ({ history }) => {
  const theme = useTheme();
  const { t } = useTranslation(['booking', 'common', 'search']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const modal = useModal();
  const modalTracking = useModal();
  const displayError = useErrorToaster();
  const userMenu = useDrawerMenu();
  const search = useSelector(state => state.search);
  const searchDates = useSelector(getSearchDates);
  const tenant = useSelector(state => state.tenant);
  const btob = tenant.btob;
  const vehicleId = search.selectedResult;
  const destinationId = search.selectedDestination;
  const stationId = search.selectedStation;
  const rental = search.rental;
  const carpooling = search.carpooling;
  const carpoolingDestination = search.carpoolingDestination;
  const estimatedDistance = search.estimatedDistance;
  const rentalLabel = search.rentalLabel;
  const userId = useSelector(state => state.account.id);
  const startDate = rental
    ? rental.reservation_start_time
    : searchDates.startDate;
  const endDate = rental ? rental.reservation_end_time : searchDates.endDate;
  const searchResult = useSelector(getSelectedSearchResult);
  const { vehicle } = useVehicleLoader(vehicleId);
  const { station } = useStationLoader(stationId);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const driverStatus = useSelector(state => state.account.serverStep);

  useScrollRestore(vehicleId);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleBack = () => {
    dispatch({
      type: SEARCH_RESULT_BOOKING_DISCARDED
    });
  };

  const handleBooking = async () => {
    if (!isAuthenticated) {
      userMenu.open();
      return;
    }
    if (
      !btob &&
      driverStatus !== 'driver_ok' &&
      driverStatus !== 'driver_waiting_for_validation'
    ) {
      history.push(`${config.auth.rootUrl}signup`);
      return;
    }
    rental ? handlePassenger() : submitRentalBooking();
  };

  const handleGeotracking = async geotracking => {
    handleRental(geotracking);
  };

  const submitRentalBooking = async () => {
    if (tenant.usePrivacyUsage && vehicle.geotracking_privacy_usage)
      modalTracking.open();
    else handleRental(true);
  };

  const handlePassenger = async () => {
    setSubmitting(true);
    try {
      await dispatch(
        createPassenger({
          rental: rental
        })
      );
      setSubmitting(false);
    } catch (error) {
      displayError(error);
      setSubmitting(false);
    }
  };

  const handleRental = async geotracking => {
    setSubmitting(true);
    try {
      await dispatch(
        createRental({
          driverId: userId,
          vehicleId,
          stationId,
          destinationId,
          startTime: startDate,
          endTime: endDate,
          estimatedDistance: estimatedDistance,
          rentalLabelId: rentalLabel ? rentalLabel.id : null,
          geotracking: geotracking,
          carpooling: carpooling,
          carpoolingDestination: carpoolingDestination
        })
      );
      setSubmitting(false);
    } catch (error) {
      displayError(error);
      setSubmitting(false);
    }
  };

  return (
    <div className="view-booking">
      <Container
        maxWidth="md"
        className={isMobile ? 'mobile-container' : 'page-container'}
      >
        <div className="page-header">
          <div className="back-button">
            <IconButton onClick={handleBack}>
              <img src={backIcon} alt="" width="20" height="20" />
            </IconButton>
          </div>

          {rental && rental.driver_id !== userId ? (
            <Typography component="h1">
              {t('booking:booking-review-carpooling')}
            </Typography>
          ) : (
            <Typography component="h1">
              {t('booking:booking-review')}
            </Typography>
          )}
        </div>

        {vehicle && station ? (
          <div>
            <div className="sections">
              <div className="section flex-row">
                <div>
                  <div className="title">{t('booking:vehicle')}</div>
                  <div className="section-content">
                    {vehicle.make} {vehicle.model}
                  </div>
                </div>
                <div className={btob ? '' : 'hidden'}>
                  <div className="title">{t('booking:plate-number')}</div>
                  <div className="section-content">{vehicle.license_plate}</div>
                </div>
              </div>

              <div className="section">
                <div className="date-range-labels">
                  <div className="label">{t('search:departure')}</div>
                  <div className="label">{t('search:return')}</div>
                </div>

                <Paper className="date-range-paper">
                  <div className="date">
                    {moment(startDate).format(rentalDateFormat)}
                  </div>
                  <Divider orientation="vertical" className="date-divider" />
                  <div className="date">
                    {moment(endDate).format(rentalDateFormat)}
                  </div>
                </Paper>
              </div>

              <div className="hidden section">
                <div className="title">{t('booking:included-distance')}</div>
                <div className="section-content">
                  <span className="kilometers">XXX</span> Km/{t('common:day')}
                </div>
              </div>

              <div className="section">
                <div className="title">
                  {btob ? station.name : t('booking:address')}
                </div>
                <div className="section-content">
                  {station.address ? station.address : null}
                </div>
                {station.latitude && station.longitude ? (
                  <StationLocation
                    latitude={station.latitude}
                    longitude={station.longitude}
                  />
                ) : null}
              </div>

              <div className="section booking-terms">
                <Trans i18nKey="booking:accept-terms">
                  En cliquant sur Confirmer, j'accepte les
                  <span className="action-link" onClick={modal.open}>
                    conditions de location
                  </span>
                </Trans>
              </div>
            </div>

            <div className="booking-box">
              <div className="summary">
                <div className="summary-content">
                  {searchResult && searchResult.price ? (
                    <div className="price">
                      {searchResult && searchResult.price.value_cents > 0
                        ? 'Total : '
                        : null}
                      <Price
                        cents={searchResult.price.value_cents}
                        currency={searchResult.price.iso_code}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="button-wrapper">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleBooking}
                  disabled={isSubmitting}
                >
                  {t('common:confirm')}
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </Container>

      <AskTrackingModal
        handleGeotracking={handleGeotracking}
        open={modalTracking.isOpen}
        onClose={modalTracking.close}
      />
      <TermsDialog open={modal.isOpen} onClose={modal.close} />
    </div>
  );
};

export default withRouter(BookingView);
