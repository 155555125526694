import api from '../../utils/api';

export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED';

export function fetchCategories() {
  return async function(dispatch) {
    try {
      const response = await api.categories.getAll();

      dispatch({
        type: CATEGORIES_FETCHED,
        categories: response.categories
      });
    } catch (error) {
      throw error;
    }
  };
}
