import {
  SEARCH_RESULT_SELECTED,
  SEARCH_CARPOOLINGS_RESULTS_FETCHED,
  SEARCH_RESULTS_FETCHED,
  SEARCH_SUBMITTED,
  SEARCH_GET_USER_POSITION,
  SEARCH_RESULT_CLOSED,
  SEARCH_MAP_ZOOM_CHANGED,
  SEARCH_MAP_DRAGGED,
  SEARCH_RESULT_BOOKING_REQUESTED,
  SEARCH_RESULT_BOOKING_DISCARDED,
  RESET_SEARCH_STATE
} from '../actions/search';
import { RENTAL_CREATED, PASSENGER_CREATED } from '../actions/rentals';

const initialState = {
  startStation: {
    displayText: '',
    station: null
  },
  endStation: {
    displayText: '',
    station: null
  },
  location: {
    displayText: '',
    lat: null,
    long: null
  },
  destination: {
    displayText: '',
    label: null,
    name: null,
    place_id: null,
    formatted_address: null,
    lat: null,
    long: null
  },
  userLocation: {
    lat: null,
    long: null
  },
  rentalLabel: null,
  estimatedDistance: null,
  startDate: null,
  endDate: null,
  carpooling: null,
  carpoolingDestination: null,
  categories: [],
  stations: [],
  options: [],
  gearsType: 'all',
  seatsCount: 'all',
  results: [],
  carpoolings: [],
  resultCount: 0,
  page: 1,
  limit: 50,
  selectedResult: null,
  selectedStation: null,
  selectedDestination: null,
  rental: null,
  destination_id: null,
  view: 'map',
  mapZoom: 14,
  mapCenter: {
    lat: null,
    lng: null
  },
  submited: false,
  isDefaultMapCenter: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SUBMITTED:
      return {
        ...state,
        startStation: action.startStation,
        endStation: action.endStation,
        location: action.location,
        destination: action.destination,
        rentalLabel: action.rentalLabel,
        startDate: action.startDate,
        endDate: action.endDate,
        carpooling: action.carpooling,
        estimatedDistance: action.estimatedDistance,
        carpoolingDestination: action.carpoolingDestination,
        categories: action.categories,
        options: action.options,
        gearsType: action.gearsType,
        seatsCount: action.seatsCount,
        stations: action.stations,
        mapCenter: {
          lat: action.startStation.station
            ? action.startStation.station.latitude
            : action.location.lat,
          lng: action.startStation.station
            ? action.startStation.station.longitude
            : action.location.long
        },
        isDefaultMapCenter: false
      };
    case SEARCH_RESULTS_FETCHED:
      return {
        ...state,
        resultCount: action.resultCount,
        results: action.results,
        view: 'map',
        selectedResult: null,
        selectedStation: null,
        selectedDestination: null,
        submited: true,
        rental: null,
        destination_id: null
      };
    case SEARCH_CARPOOLINGS_RESULTS_FETCHED:
      return {
        ...state,
        carpoolings: action.carpoolings,
        view: 'map',
        selectedResult: null,
        selectedStation: null,
        selectedDestination: null,
        submited: true,
        rental: null,
        destination_id: null
      };
    case SEARCH_RESULT_SELECTED:
      return {
        ...state,
        selectedResult: action.vehicleId,
        selectedStation: action.stationId,
        selectedDestination: action.destinationId,
        estimatedDistance: action.estimatedDistance,
        rental: null,
        view: 'vehicle'
      };
    case SEARCH_RESULT_BOOKING_REQUESTED:
      return {
        ...state,
        carpooling: action.carpooling,
        carpoolingDestination: action.carpoolingDestination,
        view: 'booking'
      };
    case SEARCH_RESULT_BOOKING_DISCARDED:
      return {
        ...state,
        view: 'vehicle'
      };
    case RENTAL_CREATED:
      return {
        ...state,
        startStation: {
          displayText: '',
          station: null
        },
        endStation: {
          displayText: '',
          station: null
        },
        location: {
          displayText: '',
          lat: null,
          long: ''
        },
        destination: {
          displayText: '',
          label: null,
          name: null,
          place_id: null,
          formatted_address: null,
          lat: null,
          long: null
        },
        rentalLabel: null,
        estimatedDistance: null,
        carpooling: null,
        carpoolingDestination: null,
        categories: [],
        options: [],
        gearsType: 'all',
        seatsCount: 'all',
        stations: [],
        mapCenter: {
          lat: null,
          lng: null
        },
        submited: false,
        results: [],
        carpoolings: [],
        isDefaultMapCenter: true,
        view: 'confirmation'
      };
    case PASSENGER_CREATED:
      return {
        ...state,
        startStation: {
          displayText: '',
          station: null
        },
        endStation: {
          displayText: '',
          station: null
        },
        location: {
          displayText: '',
          lat: null,
          long: ''
        },
        destination: {
          displayText: '',
          label: null,
          name: null,
          place_id: null,
          formatted_address: null,
          place_id: null,
          lat: null,
          long: null
        },
        rentalLabel: null,
        estimatedDistance: null,
        carpooling: null,
        carpoolingDestination: null,
        categories: [],
        options: [],
        gearsType: 'all',
        seatsCount: 'all',
        stations: [],
        mapCenter: {
          lat: null,
          lng: null
        },
        submited: false,
        results: [],
        carpoolings: [],
        isDefaultMapCenter: true,
        view: 'confirmation'
      };
    case SEARCH_RESULT_CLOSED:
      return {
        ...state,
        selectedResult: null,
        selectedStation: null,
        selectedDestination: null,
        rental: null,
        view: 'map'
      };
    case RESET_SEARCH_STATE:
      return {
        ...state,
        selectedResult: null,
        selectedStation: null,
        selectedDestination: null,
        rental: null,
        results: [],
        carpoolings: [],
        view: 'map'
      };
    case SEARCH_GET_USER_POSITION:
      return {
        ...state,
        userLocation: {
          ...state.location,
          lat: action.latitude,
          long: action.longitude
        }
      };
    case SEARCH_MAP_ZOOM_CHANGED:
      return {
        ...state,
        mapZoom: action.zoom
      };
    case SEARCH_MAP_DRAGGED:
      return {
        ...state,
        mapCenter: {
          lat: action.latitude,
          lng: action.longitude
        },
        isDefaultMapCenter: false
      };
    default:
      return state;
  }
};
