import React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import errorCodes from './errorCodes';

export const useErrorToaster = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['validation']);

  return errors => {
    let message = 'validation:internal-error';
    const createHtmlMessage = message => ({ __html: t(message) });

    if (errors.errors === undefined) {
      return enqueueSnackbar(t(message), { variant: 'error' });
    } else {
      errors.errors.map(error => {
        if (error.code && errorCodes[error.code.toUpperCase()]) {
          message = errorCodes[error.code.toUpperCase()];
        } else if (error.detail) {
          message = error.detail;
        }
        return enqueueSnackbar(
          <span dangerouslySetInnerHTML={createHtmlMessage(message)} />,
          { variant: 'error' }
        );
      });
    }
  };
};
