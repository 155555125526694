// CardSection.js
import React from 'react';
import { CardElement } from 'react-stripe-elements';

import SecuredPaymentNotice from '../shared/SecuredPaymentNotice';

class CardSection extends React.Component {
  render() {
    return (
      <div>
        <CardElement
          className="card-element"
          hidePostalCode={true}
          style={{ base: { fontSize: this.props.isMobile ? '13px' : '18px' } }}
        />
        <SecuredPaymentNotice />
      </div>
    );
  }
}

export default CardSection;
