import httpClient from '../httpClient';

export default {
  get() {
    return httpClient('v0.4').get('/tenant');
  },
  getRegistrationFlows() {
    return httpClient('v0.4').get('/registration_flows');
  },
  getAdditionalDocuments(apiLanguage) {
    return httpClient('v0.4', apiLanguage).get('/additional_documents');
  }
};
