import moment from 'moment-timezone';
import { nextQuarter } from '../../utils/dateHelper';
import { datePickerFormat } from '../../utils/variables';

export const initMinDate = state => {
  return new Date(nextQuarter(moment()).format(datePickerFormat));
};

export const initMaxStartDate = state => {
  return new Date(
    nextQuarter(
      moment().add(state.tenant.maximumTimeInAdvance, 'seconds')
    ).format(datePickerFormat)
  );
};

export const initMinEndDate = state => {
  return new Date(
    nextQuarter(
      moment().add(state.tenant.minimumReservationDuration, 'seconds')
    ).format(datePickerFormat)
  );
};

export const initCancellationMinimalDelay = state => {
  return moment()
    .add(state.tenant.cancellationMinimalDelay, 'seconds')
    .milliseconds(0);
};

export const initBoundsModificationMinimalDelay = state => {
  return moment()
    .add(state.tenant.boundsModificationMinimalDelay, 'seconds')
    .milliseconds(0);
};
