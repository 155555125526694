import React from 'react';
import Header from '../shared/Header';

const HeaderLayout = ({ children }) => {
  return (
    <div className="header-layout">
      <div className="header-container">
        <Header />
      </div>
      <div className="body-container">
        {children}
      </div>
    </div>
  );
};

export default HeaderLayout;
