import api from '../../utils/api';
import config from '../../config';
import { ACCOUNT_USER_FETCHED } from './account';

export const AUTH_LOGGED_IN = 'AUTH_LOGGED_IN';
export const AUTH_LOGGED_OUT = 'AUTH_LOGGED_OUT';
export const AUTH_REGISTERED = 'AUTH_REGISTERED';
export const AUTH_GUEST_TOKEN_CREATED = 'AUTH_GUEST_TOKEN_CREATED';

export function createUser({ email, password }) {
  return async function(dispatch) {
    try {
      const response = await api.user.createUser({ email, password });

      await dispatch(
        authenticateUser({
          username: email,
          password
        })
      );

      dispatch({
        type: AUTH_REGISTERED,
        user: response.user
      });
    } catch (error) {
      throw error;
    }
  };
}

export function authenticateUserOidcToken({ oidcToken }) {
  return async function(dispatch) {
    try {
      const response = await api.auth.getUserTokenByOidcToken({ oidcToken });
      localStorage.setItem(config.auth.accessTokenKey, response.access_token);
      localStorage.setItem('token_type', 'member');

      dispatch({
        type: AUTH_LOGGED_IN,
        access_token: response.access_token
      });
    } catch (error) {
      throw error;
    }
  };
}

export function authenticateUser({ username, password }) {
  return async function(dispatch) {
    try {
      const response = await api.auth.getUserToken({ username, password });
      localStorage.setItem(config.auth.accessTokenKey, response.access_token);
      localStorage.setItem('token_type', 'member');

      const responseUser = await api.user.getUser();

      if (responseUser.user.roles.length > 0) {
        dispatch({
          type: AUTH_LOGGED_IN,
          access_token: response.access_token
        });

        dispatch({
          type: ACCOUNT_USER_FETCHED,
          user: responseUser.user
        });
      } else {
        localStorage.removeItem(config.auth.accessTokenKey);
        localStorage.removeItem('token_type');
        createGuestToken();
        throw 'no role';
      }
    } catch (error) {
      throw error;
    }
  };
}

export function authentSSO(id_token) {
  return async function(dispatch) {
    try {
      const response = await api.auth.authentSSO(id_token);
      localStorage.setItem(config.auth.accessTokenKey, response.access_token);
      localStorage.setItem('token_type', 'member');

      dispatch({
        type: AUTH_LOGGED_IN,
        access_token: response.access_token
      });
    } catch (error) {
      throw error;
    }
  };
}

export function logOut(dispatchLogout) {
  return async function(dispatch) {
    try {
      localStorage.removeItem(config.auth.accessTokenKey);
      localStorage.removeItem('token_type');

      if (!dispatchLogout) {
        dispatch({
          type: AUTH_LOGGED_OUT
        });
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createGuestToken() {
  return async function(dispatch) {
    try {
      const response = await api.auth.getGuestToken();

      localStorage.setItem(config.auth.accessTokenKey, response.access_token);
      localStorage.setItem('token_type', 'guest');

      dispatch({
        type: AUTH_GUEST_TOKEN_CREATED,
        token: response.access_token
      });
      return response.access_token;
    } catch (error) {
      throw error;
    }
  };
}
