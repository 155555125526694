import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { getAccountDetails } from '../../store/selectors/account';
import { useErrorToaster } from '../../utils/errorToaster';
import { updateUser } from '../../store/actions/account';
import AddressCustomInput from '../../components/account/AddressCustomInput';
import AccountDateInput from '../../components/account/AccountDateInput';
import AccountTextInput from '../../components/account/AccountTextInput';

function DriverDetails() {
  const { t } = useTranslation(['account', 'home', 'common', 'validation']);
  const ref = React.useRef(null);
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const tenant = useSelector(state => state.tenant);
  const accountDetails = useSelector(getAccountDetails(tenant.dateFormat));

  const validationSchema = () => {
    const schemaFields = {};

    if (tenant.requiredDriverFields.includes('driving_licence_number'))
      schemaFields.licenceNumber = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('driving_licence_location'))
      schemaFields.licenceLocation = Yup.string()
        .nullable()
        .required(t('validation:required'));
    if (tenant.requiredDriverFields.includes('driving_licence_obtaining_time'))
      schemaFields.licenceObtainingTime = Yup.string().validDate(
        t('validation:date-invalid')
      );
    if (tenant.requiredDriverFields.includes('driving_licence_expiration_time'))
      schemaFields.licenceExpirationTime = Yup.string().validDate(
        t('validation:date-invalid')
      );

    return Yup.object(schemaFields);
  };

  const handleFormSubmit = async (
    {
      licenceNumber,
      licenceLocation,
      licenceObtainingTime,
      licenceExpirationTime
    },
    { setSubmitting }
  ) => {
    try {
      await dispatch(
        updateUser({
          licenceNumber,
          licenceLocation,
          licenceObtainingTime,
          licenceExpirationTime
        })
      );
    } catch (error) {
      setSubmitting(false);
      displayError(error);
    }
  };

  return (
    <div className="driver-details">
      <Typography component="h1" className="driver-details">
        {t('account:driver-details')}
      </Typography>

      <Formik
        innerRef={ref}
        validationSchema={validationSchema}
        initialValues={accountDetails}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleChange,
          isSubmitting,
          errors,
          touched,
          setFieldValue
        }) => (
          <Form noValidate={true} className="frontoffice-form">
            <AccountTextInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'licenceNumber'}
              driverKey={'driving_licence_number'}
              Keylabel={'account:license-number'}
            />

            <AccountTextInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'licenceLocation'}
              driverKey={'driving_licence_location'}
              Keylabel={'account:obtaining-location'}
            />

            <AccountDateInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'licenceObtainingTime'}
              driverKey={'driving_licence_obtaining_time'}
              Keylabel={'account:obtaining-date'}
            />

            <AccountDateInput
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              keyName={'licenceExpirationTime'}
              driverKey={'driving_licence_expiration_time'}
              Keylabel={'account:expiration-date'}
            />

            <div className="button-group">
              <Button
                type="submit"
                fullWidth
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                {t('common:next')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DriverDetails;
