import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));
const OptionSelector = ({ initialValue, onValueChanged }) => {
  const { t } = useTranslation(['search']);
  const classes = useStyles();
  const options = useSelector(state => state.tenant.vehicleOptions);
  const [optionName, setOptionName] = useState(initialValue);

  const initState = ({ options = [], initialValue = [] }) => {
    const state = {};

    options.forEach(option => {
      state[option] = initialValue.indexOf(option) > -1;
    });

    return state;
  };

  const [state, setState] = useState(initState({ options, initialValue }));

  const handleChange = e => {
    setOptionName(e.target.value);
    let selectedOptions = e.target.value;
    let checkedOptions = {};
    selectedOptions.map(value => {
      checkedOptions = { ...checkedOptions, [value]: true };
    });
    setState({
      ...state,
      ...checkedOptions
    });
  };

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      const options = [];

      Object.keys(state).forEach(key => {
        if (state[key] === true) {
          options.push(key);
        }
      });

      onValueChanged(options);
    }
  }, [state]);

  return (
    <div className="category-selector">
      <Paper className="input-wrapper">
        <FormControl className={classes.formControl}>
          <Select
            multiple
            displayEmpty
            value={optionName}
            onChange={handleChange}
            input={<Input />}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.length === 0 ? (
                  <Chip
                    key={'none'}
                    label={t('search:option-none')}
                    className={classes.chip}
                  />
                ) : null}
                {selected.map(value => (
                  <Chip
                    key={`chip-option-${value}`}
                    label={t(`search:${value}`)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {options.map(option => (
              <MenuItem key={`option-${option}`} value={option}>
                <Checkbox checked={optionName.indexOf(option) > -1} />
                <ListItemText primary={t(`search:${option}`)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </div>
  );
};

export default OptionSelector;
