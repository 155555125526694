import httpClient from '../httpClient';

export default {
  getAll() {
    return httpClient('v0.4').get('/stations');
  },
  getOne(id) {
    return httpClient('v0.4').get(`/stations/${id}`);
  }
};
