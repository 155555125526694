import React from 'react';
import { useTranslation } from 'react-i18next';
import TermsDialog from '../content/TermsDialog';
import { useModal } from '../../utils/modal';

const DrawerFooter = () => {
  const { t } = useTranslation(['common']);
  const termsModal = useModal();

  const handleLegalClick = () => {
    termsModal.open();
  };

  return (
    <div className="drawer-footer">
      <div className="legal" onClick={handleLegalClick}>
        {t('common:legal')}
      </div>
      <TermsDialog open={termsModal.isOpen} onClose={termsModal.close} />
    </div>
  );
};

export default DrawerFooter;
