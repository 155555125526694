import axios from 'axios';
import _ from 'lodash';
import config from '../config';

const API_URL = _.trimEnd(config.api.url, '/');

export default function(
  apiVersion = null,
  apiLanguage = localStorage.getItem('lng')
) {
  const http = axios.create({
    baseURL: API_URL
  });

  http.defaults.headers.common['Content-Type'] = 'application/json';
  http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  if (apiVersion) {
    http.defaults.headers.common[
      'Accept'
    ] = `application/vnd.openfleet.${apiVersion}`;
  }

  if (apiLanguage) {
    http.defaults.headers.common['Accept-Language'] = apiLanguage;
  } else {
    http.defaults.headers.common['Accept-Language'] = 'fr';
  }

  /**
   * Inject Access Token
   */

  http.interceptors.request.use(
    request => {
      const token = localStorage.getItem(config.auth.accessTokenKey);

      if (token) {
        request.headers['Authorization'] = `Bearer ${token}`;
      }

      return request;
    },
    error => Promise.reject(error)
  );

  /**
   * Handle API status codes.
   *
   * Return an objet containing following properties:
   * - type : internal | bad_request | forbidden | not_found | payment | unauthorized
   * - response : {} - original axios response object
   */

  http.interceptors.response.use(
    response => Promise.resolve(response.data),
    error => {
      let type = 'internal';

      const codeTypeMapping = {
        400: 'bad_request',
        401: 'unauthorized',
        402: 'payment',
        403: 'forbidden',
        404: 'not_found',
        409: 'conflict'
      };

      if (
        error.response &&
        error.response.status &&
        codeTypeMapping[error.response.status]
      ) {
        type = codeTypeMapping[error.response.status];
      }

      let errors = null;
      if (error.response && error.response.data) {
        errors = error.response.data.message
          ? [
              {
                code: error.response.data.code,
                detail: error.response.data.message
              }
            ]
          : error.response.data.errors;
      }

      if (error.response.status === 401) {
        localStorage.removeItem(config.auth.accessTokenKey);
        localStorage.removeItem('token_type');
        window.location.href = '/frontofficev2/login';
      }

      return Promise.reject({
        type,
        code:
          error.response && error.response.status
            ? error.response.status
            : null,
        response: error.response,
        errors
      });
    }
  );

  return http;
}
