import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const CategorySelector = ({ initialValue, onValueChanged }) => {
  const { t } = useTranslation(['search']);
  const classes = useStyles();
  const categories = useSelector(state => state.categories.list);

  const initDisplay = () => {
    let cats = [];

    initialValue.forEach(id => {
      cats.push(_.find(categories, { id: id }).name);
    });
    return cats;
  };

  const [categoryName, setCategoryName] = useState(initDisplay);

  const initState = ({ categories = [], initialValue = [] }) => {
    const state = {};

    categories.forEach(category => {
      state[category] = initialValue.indexOf(category) > -1;
    });
    return state;
  };
  const [state, setState] = useState(initState({ categories, initialValue }));

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      const cats = [];

      Object.keys(state).forEach(key => {
        if (state[key] === true) {
          cats.push(_.find(categories, { name: key }).id);
        }
      });

      onValueChanged(cats);
    }
  }, [state]);

  const handleChange = e => {
    setCategoryName(e.target.value);
    let selectedCategories = e.target.value;
    let checkedCategories = {};
    selectedCategories.map(value => {
      checkedCategories = { ...checkedCategories, [value]: true };
    });
    setState({
      ...state,
      ...checkedCategories
    });
  };

  return (
    <div className="category-selector">
      <Paper className="input-wrapper">
        <FormControl className={classes.formControl}>
          <Select
            multiple
            displayEmpty
            value={categoryName}
            onChange={handleChange}
            input={<Input />}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.length === 0 ? (
                  <Chip
                    key={'all'}
                    label={t('search:category-all')}
                    className={classes.chip}
                  />
                ) : null}
                {selected.map(value => (
                  <Chip
                    key={`chip-category-${value}`}
                    label={_.find(categories, { name: value }).name}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {categories.map(category => (
              <MenuItem key={`category-${category.name}`} value={category.name}>
                <Checkbox checked={categoryName.indexOf(category.name) > -1} />
                <ListItemText primary={category.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </div>
  );
};

export default CategorySelector;
