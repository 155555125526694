import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import CardSection from './CardSection';

//TODO Refacto Functional Component stripe (elements.getElement('card')) not accessible in Functional Component.
class CardModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: null,
      last_name: null
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    const cardElement = this.props.elements.getElement('card');

    this.props.stripe
      .confirmCardSetup(this.props.setupIntent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${this.state.last_name} ${this.state.first_name}`
          }
        }
      })
      .then(confirmCardSetupRes => {
        this.props.onResult(confirmCardSetupRes);
      });
  };

  handleChangeFirstName = event => {
    this.state.first_name = event.target.value;
  };

  handleChangeLastName = event => {
    this.state.last_name = event.target.value;
  };

  stripeForm() {
    const { t } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={2} className="billing-details">
          <Grid item xs={6} className="firstname">
            <div className="form-group">
              <TextField
                name="firstName"
                label={t('account:firstName')}
                variant="outlined"
                onChange={this.handleChangeFirstName}
                margin="none"
                fullWidth
              />
            </div>
          </Grid>

          <Grid item xs={6} className="lastname">
            <div className="form-group">
              <TextField
                name="lastName"
                label={t('account:lastName')}
                variant="outlined"
                onChange={this.handleChangeLastName}
                margin="none"
                fullWidth
              />
            </div>
          </Grid>
        </Grid>
        <br></br>

        <CardSection
          isMobile={this.props.isMobile}
          className="card-selection"
        />

        <DialogActions className="card-button">
          <Button
            type="submit"
            className="submit-button"
            variant="contained"
            color="primary"
          >
            {t('common:confirm')}
          </Button>

          {this.props.isModal ? (
            <Button
              className="cancel-button"
              variant="outlined"
              color="primary"
              onClick={this.props.onClose}
            >
              {t('common:cancel')}
            </Button>
          ) : null}
        </DialogActions>
      </form>
    );
  }

  form() {
    const { t } = this.props;
    return (
      <div>
        <Typography component="h1" className="driver-details">
          {t('payment_method:payment-methods')}
        </Typography>
        {this.stripeForm()}
      </div>
    );
  }

  dialog() {
    const { t } = this.props;

    return (
      <Dialog
        className="dialog-card"
        fullWidth
        fullScreen={this.props.isMobile}
        style={{ base: { padding: this.props.isMobile ? '0px' : '30px' } }}
        maxWidth={'xs'}
        open={this.props.open}
        scroll="paper"
        onClose={this.props.onClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('payment_method:add-payment-methods')}
        </DialogTitle>

        <DialogContent>{this.stripeForm()}</DialogContent>
      </Dialog>
    );
  }

  render() {
    return (
      <div className="card-modal">
        {this.props.isModal ? this.dialog() : this.form()}
      </div>
    );
  }
}

export default withTranslation()(injectStripe(CardModal));
