import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSnackbar } from 'notistack';

import {
  sendValidationCode,
  validateNewPhoneNumber
} from '../../store/actions/account';
import MultiDigitInput from '../shared/MultiDigitInput';
import { useErrorToaster } from '../../utils/errorToaster';

const ValidatePhoneNumberModal = ({ open, onClose }) => {
  const { t } = useTranslation(['account']);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const phoneNumber = useSelector(state => state.account.newPhoneNumber);
  const formattedNumber = phoneNumber
    ? parsePhoneNumberFromString(phoneNumber, 'FR').formatNational()
    : '';
  const { enqueueSnackbar } = useSnackbar();
  const displayError = useErrorToaster();

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleCodeSubmit = async (code, reset) => {
    setIsSubmitting(true);

    try {
      await dispatch(validateNewPhoneNumber(phoneNumber, code));
      enqueueSnackbar(t('account:phone-number-changed'), {
        variant: 'success'
      });
    } catch (error) {
      displayError(error);
      reset();
      setIsSubmitting(false);
    }
  };

  const resendCode = async () => {
    try {
      await dispatch(sendValidationCode(phoneNumber));
      enqueueSnackbar(t('account:code-resend-success'), { variant: 'success' });
    } catch (error) {
      displayError(error);
    }
  };

  return (
    <Dialog
      className="validate-number-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="phone-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="password-dialog-title">
        {t('account:sms-code-sent')}
      </DialogTitle>

      <DialogContent>
        <div className="code-recipient-number">{formattedNumber}</div>

        <MultiDigitInput
          digits={6}
          onComplete={handleCodeSubmit}
          disabled={isSubmitting}
        />

        <div className="resend-code action-link" onClick={resendCode}>
          {t('account:resend-code')}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ValidatePhoneNumberModal;
