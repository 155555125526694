import React from 'react';
import check from '../../assets/images/check.svg';
import { useTranslation } from 'react-i18next';
import loginScreenImage from '../../assets/images/login-screen-image.png';
import { useSelector } from 'react-redux';

function HeroPanel() {
  const { t } = useTranslation('home');
  const tenant = useSelector(state => state.tenant);
  const ownloginBackground = tenant.loginBackground;

  if (ownloginBackground) {
    return (
      <div className="hero-panel">
        <div
          style={{ backgroundImage: `url(${ownloginBackground})` }}
          className="img-background"
        ></div>
      </div>
    );
  } else {
    return (
      <div className="hero-panel">
        <div className="inner">
          <div className="image-container">
            <img src={loginScreenImage} alt="" width="520" height="300" />
          </div>

          <div className="features">
            <div className="feature">
              <div className="check">
                <img src={check} alt="" width="30" height="30" />
              </div>
              {t('free-registration')}
            </div>
            <div className="feature">
              <div className="check">
                <img src={check} alt="" width="30" height="30" />
              </div>
              {t('no-subscription')}
            </div>
            <div className="feature">
              <div className="check">
                <img src={check} alt="" width="30" height="30" />
              </div>
              {t('vehicule-quality')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroPanel;
