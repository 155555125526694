import account from './en/account';
import payment_method from './en/payment_method';
import booking from './en/booking';
import common from './en/common';
import footer from './en/footer';
import home from './en/home';
import search from './en/search';
import validation from './en/validation';

export default {
  account,
  payment_method,
  booking,
  common,
  footer,
  home,
  search,
  validation
};
