import { formatDuration, intervalToDuration } from 'date-fns';
import fr from 'date-fns/locale/fr';
import moment from 'moment-timezone';

export const nextQuarter = time => {
  const roundedUp = Math.ceil(time.minute() / 15) * 15;

  return time
    .minute(roundedUp)
    .second(0)
    .milliseconds(0);
};

export const duration = (startDate, endDate, lang = 'fr') => {
  if (!startDate || !endDate) {
    return null;
  }
  return formatDuration(
    intervalToDuration({ start: new Date(startDate), end: new Date(endDate) }),
    { locale: lang === 'fr' ? fr : null }
  );
};

// Return the date with the current timezone (datepicker has browser timezone and we want tenant timezone)
export const overrideTimezone = date => {
  if (!date) {
    return null;
  }
  return moment(
    `${date.getFullYear()}-${date.getDate()}-${date.getMonth() +
      1} ${date.getHours()}:${date.getMinutes()}`,
    'YYYY-DD-MM h:mm'
  );
};
