import React, { createRef, useEffect, useState } from 'react';

function MultiDigitInputControl({
  digits = 6,
  initialValues = {},
  onChange,
  disabled = false
}) {
  const [values, setValues] = useState(initialValues);
  const formRef = createRef();
  const inputRefs = {};

  const initForm = () => {
    initRefs();
  };

  const initRefs = () => {
    for (let i = 0; i < digits; i += 1) {
      inputRefs[i] = createRef();
    }
  };

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    });

    if (value.length) {
      focusNextDigit(Number(key));
    }
  };

  const handleKeyDown = (key, code) => {
    if (code === 8 && !values[key].length) {
      focusPrevDigit(key);
    }
  };

  const handleInputValue = () => {
    const value = getInputValue();

    if (typeof onChange === 'function') {
      onChange(value, setValues);
    }
  };

  const focusNextDigit = key => {
    if (key < digits - 1) {
      const targetKey = key + 1;
      inputRefs[targetKey].current.focus();
    } else {
      inputRefs[key].current.blur();
    }
  };

  const focusPrevDigit = key => {
    if (key > 0) {
      const prevKey = key - 1;
      inputRefs[prevKey].current.focus();
    }
  };

  const getInputValue = () => {
    let input = '';

    Object.keys(values).forEach(key => {
      input += values[key];
    });

    return input;
  };

  initForm();

  useEffect(() => {
    handleInputValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const inputElements = Object.keys(values).map(key => (
    <input
      type="number"
      key={`input-${key}`}
      ref={inputRefs[key]}
      className="digit-input"
      maxLength={1}
      pattern="[0-9]"
      min="0"
      autoComplete="off"
      value={values[key]}
      onChange={e => handleChange(key, e.target.value)}
      onKeyDown={e => handleKeyDown(key, e.keyCode, e.currentTarget.value)}
      disabled={disabled}
    />
  ));

  return (
    <form className="multi-digit-input" ref={formRef}>
      {inputElements}
    </form>
  );
}

export default MultiDigitInputControl;
