import React from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTranslation } from 'react-i18next';

const SecuredPaymentNotice = () => {
  const { t } = useTranslation(['account']);

  return (
    <div className="secured-payment-notice">
      <LockOutlinedIcon />
      <div>{t('account:secured-payment-info')}</div>
    </div>
  );
};

export default SecuredPaymentNotice;
