import tenantLogo from '../assets/images/openfleet-logo.png';
import pinSmallMap from '../assets/images/custom/openfleet/openfleet-pin-small.svg';
import pinBigMap from '../assets/images/custom/openfleet/openfleet-pin-big.svg';
import favicon from '../assets/images/custom/openfleet/favicon.png';

const openfleet = {
  tenant: {
    name: 'Openfleet',
    politique_de_confidentialite_url:
      'https://www.openfleet.com/politique-de-confidentialite/',
    assets: {
      favicon: favicon,
      logo: tenantLogo,
      pinSmallMap: pinSmallMap,
      pinBigMap: pinBigMap
    }
  },
  typography: {
    fontFamily: [
      'SofiaPro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      light: '#102d5e',
      main: '#102d5e',
      dark: '#102d5e'
    },
    secondary: {
      main: '#2a74ed'
    },
    background: {
      default: '#ffffff'
    }
  }
};

export default openfleet;
