import React from 'react';
import HeaderFooterLayout from '../components/layout/HeaderFooterLayout';
import ContainerWithLeftPanel from '../components/layout/ContainerWithLeftPanel';
import HeroPanel from '../components/shared/HeroPanel';
import LoginOrRegister from '../components/auth/LoginOrRegister';

function AuthenticationPage() {
  return (
    <div className="authentication-page">
      <HeaderFooterLayout>
        <ContainerWithLeftPanel
          panel={<HeroPanel />}
          main={<LoginOrRegister />}
        />
      </HeaderFooterLayout>
    </div>
  );
}

export default AuthenticationPage;
