import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';

const RentalLabelAutocomplete = ({ initialValue, onValueChanged }) => {
  const rentalLabels = useSelector(state => state.rentalLabels.list);

  const [rentalLabel, setInputValue] = useState([initialValue]);

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      onValueChanged({
        displayText: rentalLabel ? rentalLabel.name : '',
        rentalLabel: rentalLabel
      });
    }
  }, [rentalLabel]);

  return (
    <div className="location-input">
      <Paper className="input-wrapper">
        <Autocomplete
          disableClearable
          value={Array.isArray(rentalLabel) ? rentalLabel[0] : rentalLabel}
          id={`${rentalLabel.id}`}
          className="input-control"
          options={rentalLabels}
          getOptionLabel={option => option.name}
          onChange={(event, value) => setInputValue(value)}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              variant="standard"
            />
          )}
        />
      </Paper>
    </div>
  );
};

export default RentalLabelAutocomplete;
