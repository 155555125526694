import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import FormLabel from '@material-ui/core/FormLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

import HeaderLayout from '../components/layout/HeaderLayout';
import { useErrorToaster } from '../utils/errorToaster';
import LocationInput from '../components/search/LocationInput';
import StationAutocomplete from '../components/search/StationAutocomplete';
import RentalLabelAutocomplete from '../components/search/RentalLabelAutocomplete';
import EstimatedDistanceInput from '../components/search/EstimatedDistanceInput';
import CategorySelector from '../components/search/CategorySelector';
import OptionSelector from '../components/search/OptionSelector';
import DateRangeInput from '../components/search/DateRangeInput';
import closeIcon from '../assets/images/close.svg';
import { submitSearch, submitCarpooling } from '../store/actions/search';
import { fetchRentalLabels } from '../store/actions/rentalLabels';
import { fetchCategories } from '../store/actions/categories';
import { fetchStations } from '../store/actions/stations';
import config from '../config';
import {
  initMinDate,
  initMinEndDate,
  initMaxStartDate
} from '../store/selectors/tenant';
import SeatsCountAutocomplete from '../components/search/SeatsCountAutocomplete';
import GearsTypeAutocomplete from '../components/search/GearsTypeAutocomplete';

const SearchPage = ({ history }) => {
  const { t } = useTranslation(['search']);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const searchState = useSelector(state => state.search);
  const tenant = useSelector(state => state.tenant);
  moment.tz.setDefault(tenant.timezone);
  const language = useSelector(state => state.app.language);
  const btob = tenant.btob;
  const useLabels = tenant.useLabels;
  const useCategories = tenant.useCategories;
  const useEstimatedDistance = tenant.useEstimatedDistance;
  const useEstimatedDestinationField = tenant.useEstimatedDestinationField;
  const distanceUnit = tenant.distanceUnit;

  const { enqueueSnackbar } = useSnackbar();
  const displayError = useErrorToaster();

  const minEndDate = useSelector(initMinEndDate);
  const maxStartDate = useSelector(initMaxStartDate);
  const minDate = useSelector(initMinDate);

  const loadData = async () => {
    try {
      await Promise.all([
        dispatch(fetchStations()),
        tenant.useCategories && dispatch(fetchCategories()),
        tenant.useLabels && dispatch(fetchRentalLabels())
      ]);
    } catch (error) {
      console.error('Error while loading data...');
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const [formState, setFormState] = useState({
    isProcessing: false,
    startStation: searchState.startStation,
    endStation: searchState.endStation,
    location: searchState.location,
    destination: searchState.destination,
    startDate: searchState.startDate ? searchState.startDate : minDate,
    endDate: searchState.endDate ? searchState.endDate : minEndDate,
    minStartDate: minDate,
    maxStartDate: maxStartDate,
    minEndDate: minEndDate,
    maxEndDate: maxStartDate,
    rentalLabel: searchState.rentalLabel,
    estimatedDistance: searchState.estimatedDistance,
    categories: searchState.categories
      ? searchState.categories.map(id => Number(id))
      : null,
    options: searchState.options,
    gearsType: searchState.gearsType,
    seatsCount: searchState.seatsCount
  });

  const handleClose = () => {
    history.push(config.auth.rootUrl);
  };

  const handleStation = ({ station }) => {
    setFormState({
      ...formState,
      startStation: {
        displayText: station ? station.name : '',
        station: station
      },
      endStation: {
        displayText: station ? station.name : '',
        station: station
      }
    });
  };

  const handleEstimatedDistance = ({ distance }) => {
    setFormState({
      ...formState,
      estimatedDistance: distance
    });
  };

  const handleRentalLabel = ({ rentalLabel }) => {
    setFormState({
      ...formState,
      rentalLabel: rentalLabel
    });
  };

  const handleDestinationLabel = event => {
    setFormState({
      ...formState,
      destination: {
        ...formState.destination,
        label: event.target.value
      }
    });
  };

  const handleLocationDestination = async ({
    displayText,
    lat,
    long,
    name,
    place_id,
    formatted_address
  }) => {
    setFormState({
      ...formState,
      destination: {
        label: formState.destination.label,
        name,
        place_id,
        formatted_address,
        lat,
        long,
        displayText
      }
    });
  };

  const handleLocation = ({ displayText, lat, long }) => {
    setFormState({
      ...formState,
      location: {
        displayText,
        lat,
        long
      }
    });
  };

  const handleCategories = value => {
    setFormState({
      ...formState,
      categories: value
    });
  };

  const handleOptions = value => {
    setFormState({
      ...formState,
      options: value
    });
  };

  const handleGearsType = ({ gear }) => {
    setFormState({
      ...formState,
      gearsType: gear === null ? 'all' : gear
    });
  };

  const handleSeatsCount = ({ seats }) => {
    setFormState({
      ...formState,
      seatsCount: seats === null ? 'all' : seats
    });
  };

  const handleDateRange = ({ startDate, endDate }) => {
    setFormState({
      ...formState,
      startDate,
      endDate
    });
  };

  const handleEmptyResult = () => {
    const message = formState.categories.length
      ? t('search:no-result-in-category')
      : t('search:no-result');
    enqueueSnackbar(message, { variant: 'error' });
  };

  const handleSearch = async () => {
    try {
      let carpoolingResults = [];
      setFormState({
        ...formState,
        isProcessing: true
      });

      const results = await dispatch(submitSearch(formState));
      if (tenant.btob && tenant.useCarpooling) {
        carpoolingResults = await dispatch(submitCarpooling(formState));
      }

      if (
        ((tenant.btob && tenant.useCarpooling && !carpoolingResults) ||
          !carpoolingResults.length) &&
        (!results || !results.length)
      ) {
        handleEmptyResult();
      }
      history.push(config.auth.rootUrl);
    } catch (error) {
      displayError(error);
      setFormState({ ...formState, isProcessing: false });
    }
  };

  const submitButtonEnabled = () => {
    return (
      formState.isProcessing ||
      !formState.startDate ||
      !formState.endDate ||
      (tenant.searchEngine === 'station' && !formState.startStation.station) ||
      (tenant.searchEngine === 'address' &&
        (!formState.location.lat || !formState.location.long)) ||
      (useLabels && !formState.rentalLabel) ||
      (useEstimatedDistance &&
        !useEstimatedDestinationField &&
        !formState.estimatedDistance) ||
      (useEstimatedDistance &&
        useEstimatedDestinationField &&
        (!formState.destination.place_id || !formState.destination.label))
    );
  };

  return (
    <div className="search-page">
      <HeaderLayout>
        <Container
          component="main"
          maxWidth="xs"
          className="search-form-container"
        >
          <div className="page-header flex-justify-between align-center">
            <Typography component="h1">{t('search:search')}</Typography>

            <div className="close-button">
              <div className="close-button" onClick={handleClose}>
                <IconButton>
                  <img src={closeIcon} alt="" width="32" height="31" />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="search-form">
            <div
              className={
                tenant.searchEngine === 'station' ? 'form-group' : 'hidden'
              }
            >
              <div className="input-label">
                <FormLabel required={true}>{t('search:station')}</FormLabel>
              </div>
              <StationAutocomplete
                initialValue={formState.startStation.station}
                onValueChanged={handleStation}
              />
            </div>

            {tenant.searchEngine === 'address' ? (
              <div className="form-group">
                <div className="input-label">
                  <FormLabel required={true}>{t('search:address')}</FormLabel>
                </div>
                <LocationInput
                  initialValue={formState.location}
                  onValueChanged={handleLocation}
                  language={language}
                />
              </div>
            ) : null}

            {useEstimatedDistance && useEstimatedDestinationField ? (
              <div>
                <div className="form-group">
                  <div className="input-label">
                    <FormLabel required={true}>
                      {t('search:destination')}
                    </FormLabel>
                  </div>
                  <LocationInput
                    initialValue={formState.destination}
                    onValueChanged={handleLocationDestination}
                    language={language}
                  />
                </div>

                <div className="location-input">
                  <Paper className="input-wrapper">
                    <InputBase
                      placeholder={t('search:destination-label')}
                      type="string"
                      id="destination-label"
                      className="input-control"
                      value={formState.destination.label}
                      onChange={event => handleDestinationLabel(event)}
                    />
                  </Paper>
                </div>
              </div>
            ) : null}

            <div className="form-group">
              <div className="flex-labels">
                <div className="input-label">
                  <FormLabel required={true}>{t('search:departure')}</FormLabel>
                </div>
                <div className="input-label">
                  <FormLabel required={true}>{t('search:return')}</FormLabel>
                </div>
              </div>
              <DateRangeInput
                instantSearch={true}
                withPortal={isMobile}
                language={language}
                initialValue={{
                  startDate: formState.startDate,
                  endDate: formState.endDate,
                  minStartDate: formState.minStartDate,
                  maxStartDate: formState.maxStartDate,
                  minEndDate: formState.minEndDate,
                  maxEndDate: formState.maxEndDate
                }}
                onValueChanged={handleDateRange}
                minimumReservationDuration={tenant.minimumReservationDuration}
                timezone={tenant.timezone}
              />
            </div>

            <div className={useLabels ? 'form-group' : 'hidden'}>
              <div className="input-label">
                <FormLabel required={true}>
                  {t('search:rental-label')}
                </FormLabel>
              </div>
              <RentalLabelAutocomplete
                initialValue={formState.rentalLabel}
                onValueChanged={handleRentalLabel}
              />
            </div>

            <div
              className={
                useEstimatedDistance && !useEstimatedDestinationField
                  ? 'form-group'
                  : 'hidden'
              }
            >
              <div className="input-label">
                <FormLabel required={true}>
                  {t('search:estimated-distance', {
                    distanceUnit: distanceUnit
                  })}
                </FormLabel>
              </div>
              <EstimatedDistanceInput
                initialValue={formState.estimatedDistance}
                onValueChanged={handleEstimatedDistance}
              />
            </div>

            <div className={useCategories ? 'form-group' : 'hidden'}>
              <div className="input-label">{t('search:vehicule-type')}</div>
              <CategorySelector
                initialValue={formState.categories}
                onValueChanged={handleCategories}
              />
            </div>

            <div className="form-group">
              <div className="input-label">{t('search:vehicle-options')}</div>
              <OptionSelector
                initialValue={formState.options}
                onValueChanged={handleOptions}
              />
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div className="form-group">
                  <div className="input-label">
                    {t('search:vehicle-gears-type')}
                  </div>
                  <GearsTypeAutocomplete
                    initialValue={formState.gearsType}
                    onValueChanged={handleGearsType}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="form-group">
                  <div className="input-label">
                    {t('search:vehicle-seats-count')}
                  </div>
                  <SeatsCountAutocomplete
                    initialValue={formState.seatsCount}
                    onValueChanged={handleSeatsCount}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="button-group">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSearch}
                disabled={submitButtonEnabled()}
              >
                {t('search:submit')}
              </Button>
            </div>
          </div>
        </Container>
      </HeaderLayout>
    </div>
  );
};

export default withRouter(SearchPage);
