import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCustomContent } from '../store/actions/customContents';

export const useCustomContent = (type) => {
  const dispatch = useDispatch();
  const customContent = useSelector(state => state.customContents[type]);

  useEffect(() => {
    if (!customContent) {
      dispatch(fetchCustomContent(type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customContent]);

  return customContent ? customContent : {};
};
