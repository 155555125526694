import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../config';

const PrivateRoute = ({ tenant: tenant, component: Component, ...rest }) => {
  const redirectPath = config.auth.guestRedirectPath;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const account = useSelector(state => state.account);

  return (
    <Route
      {...rest}
      render={props =>
        (isAuthenticated &&
          tenant.platformType === 'private' &&
          account.isLoaded &&
          account.serverStep === 'driver_ok') ||
        tenant.platformType === 'public' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
