import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';

const EstimatedDistanceInput = ({ initialValue, onValueChanged }) => {
  const [distance, setInputValue] = useState([initialValue]);

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      onValueChanged({
        distance: distance
      });
    }
  }, [distance]);

  return (
    <div className="location-input">
      <Paper className="input-wrapper">
        <InputBase
          type="number"
          id="location-input"
          className="input-control"
          value={distance}
          onChange={e => setInputValue(e.target.value)}
        />
      </Paper>
    </div>
  );
};

export default EstimatedDistanceInput;
