import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehicleById } from '../store/actions/vehicles';

export const useVehicleLoader = vehicleId => {
  const dispatch = useDispatch();
  const [vehicle, setVehicle] = useState({});
  const vehicles = useSelector(state => state.vehicles.list);

  useEffect(() => {
    const fetchDataFromServer = id => {
      return dispatch(fetchVehicleById(id));
    };

    const loadVehicle = async () => {
      if (vehicleId) {
        let vehicleInStore = vehicles
          ? find(vehicles, { id: vehicleId })
          : null;

        if (!vehicleInStore) {
          const newVehicle = await fetchDataFromServer(vehicleId);
          return setVehicle(newVehicle);
        } else {
          return setVehicle(vehicleInStore);
        }
      }
    };
    loadVehicle();
  }, [vehicleId, setVehicle]);

  return { vehicle };
};
