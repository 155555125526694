import React from 'react';
import { useTranslation } from 'react-i18next';

const CarpoolingInfo = ({ driver = {}, rental = {} }) => {
  const { t } = useTranslation(['booking']);

  const renderUser = () => {
    if (driver) {
      return (
        <div className="section">
          <div className="title">{t('booking:driver-carpooling')}</div>
          <div className="section-content">
            {driver.first_name} {driver.last_name}
          </div>
        </div>
      );
    }
  };

  const renderRentalCarpoolingInfo = () => {
    if (rental && rental.carpooling_destination) {
      return (
        <div className="section">
          <div className="title">{t('booking:destination-carpooling')}</div>
          <div className="section-content">{rental.carpooling_destination}</div>
        </div>
      );
    }
  };

  return (
    <div>
      {renderUser()}
      {renderRentalCarpoolingInfo()}
    </div>
  );
};

export default CarpoolingInfo;
