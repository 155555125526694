import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useTheme } from '@material-ui/core/styles';

import { useVehicleLoader } from '../../hooks/vehicleLoader';
import { fullSearchFilterDateFormat } from '../../utils/variables';

const VehicleCarPoolingCard = props => {
  const theme = useTheme();
  const {
    vehicleId,
    carpoolingDestination,
    price,
    startStationId,
    startDate,
    endDate,
    ...rest
  } = props;
  const { t } = useTranslation(['search', 'common']);
  const { vehicle } = useVehicleLoader(vehicleId);

  const boxShadow = {
    boxShadow: `0 0 2px 1px ${theme.palette.primary.main}`
  };

  return (
    <div style={boxShadow} className="carpooling vehicule-card" {...rest}>
      <div className="relative_box image">
        {vehicle.thumbnail_url ? (
          <img src={vehicle.thumbnail_url} alt="" />
        ) : null}
        <h3 className="carpooling-title">{t('search:carpooling-title')}</h3>
      </div>
      <div className="details">
        <div className="desc">
          <div className="date">
            <span className="label">
              {t('search:carpooling-date-label')} :{' '}
            </span>
            {moment(startDate).format(fullSearchFilterDateFormat)} -{' '}
            {moment(endDate).format(fullSearchFilterDateFormat)}
          </div>

          {carpoolingDestination ? (
            <h3 className="carpooling-text">
              <span className="label">
                {t('search:carpooling-destination-label')} :
              </span>{' '}
              {carpoolingDestination}
            </h3>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VehicleCarPoolingCard;
