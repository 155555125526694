import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { useLanguageLoader } from '../../hooks/languageLoader';

const Header = ({ history }) => {
  const tenant = useSelector(state => state.tenant);
  const language = useSelector(state => state.app.language);
  const location = useLocation();
  const useLanguage = useLanguageLoader();
  const { t } = useTranslation(['account', 'common']);

  const handleUseLanguage = () => {
    if (language === 'fr') useLanguage.toggleEnglish();
    else if (language === 'en') useLanguage.toggleFrench();
  };

  const handleLogoClick = () => {
    if (location.pathname === '/frontofficev2/search')
      history.push(`${config.auth.rootUrl}`);
    else if (location.pathname === '/frontofficev2/signup')
      history.push(`${config.auth.rootUrl}login`);
    else if (location.pathname === '/frontofficev2/forgot-password')
      history.push(`${config.auth.rootUrl}login`);
  };

  return (
    <div className="tenant-header">
      <div className="tenant-logo">
        <img
          className="tenant-img-logo"
          onClick={handleLogoClick}
          src={tenant.logo}
          alt={tenant.human_name}
        />
      </div>

      {tenant.useMultilanguage ? (
        <div className="tenant-language">
          <Button
            className="language-header"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleUseLanguage}
          >
            {language === 'fr' ? 'English' : 'Français'}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(Header);
