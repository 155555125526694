import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { sendAcceptTos } from '../../store/actions/account';
import { useTermsAndConditions } from '../../hooks/termsAndConditions';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    margin: '0px'
  }
}));

const CookieConsentModal = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['account']);
  const [hasAccepted, setHasAccepted] = useState(true);

  const handleAccept = async () => {
    if (typeof onClose === 'function') {
      let date = new Date();

      date.setTime(date.getTime() + 180 * 24 * 60 * 60 * 1000); // 6 month
      document.cookie = 'cookieModalShown=true' + ';expires=' + date;
      onClose();
    }
  };

  const handleChange = event => {
    setHasAccepted(!hasAccepted);
  };

  return (
    <div className="modal-cookie-consent">
      <Dialog
        className="cookie-dialog"
        scroll="body"
        open={open}
        aria-labelledby="terms-dialog-title"
        fullWidth
      >
        <DialogTitle id="terms-dialog-title">
          {t('account:cookie-consent-title')}
        </DialogTitle>
        <DialogContent>{t('account:cookie-consent-content')}</DialogContent>
        <DialogContent>
          <DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasAccepted}
                  onChange={handleChange}
                  name="jason"
                />
              }
              label={t('account:cookie-consent-tech')}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!hasAccepted}
            onClick={handleAccept}
            color="primary"
          >
            {t('account:cookie-consent-button-all')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CookieConsentModal;
