import {
  TENANT_FETCHED,
  FETCH_REGISTRATION_STEP,
  FETCH_ADDITIONAL_DOCUMENTS
} from '../actions/tenant';
import { dateInputFrFormat, dateInputFormat } from '../../utils/variables';
import _ from 'lodash';

const initialState = {
  btob: true,
  language: 'fr',
  theme: 'openfleet',
  human_name: 'openfleet',
  prepareDelay: -1,
  minimumReservationDuration: -1,
  maximumReservationDuration: -1,
  cancellationMinimalDelay: -1,
  boundsModificationMinimalDelay: -1,
  damageReportMode: 'none',
  maximumTimeInAdvance: -1,
  useCategories: false,
  useEntities: false,
  useLabels: false,
  useTermsAndConditions: false,
  useEstimatedDistance: false,
  useEstimatedDestinationField: false,
  usePayments: false,
  requiredPaymentCard: false,
  usePrivacyUsage: false,
  usePrivacyUsageTimeSlot: false,
  useCookieConsent: false,
  useBilling: false,
  useCarpooling: false,
  useFleetPlanning: false,
  useMultilanguage: false,
  useSingleSignOn: false,
  useRegistration: false,
  autoRegister: false,
  driverDocumentsSelfie: false,
  driverDocumentsIdentify: false,
  driverDocumentsLicence: false,
  platformType: 'private',
  singleSignOnClientId: null,
  singleSignOnIssuer: null,
  stateMachine: null,
  searchEngine: 'station',
  registrationSteps: [],
  additionalDocumentsFr: [],
  additionalDocumentsEn: [],
  singleSignOnProvider: null,
  singleSignOnAuthorizationEndpoint: null,
  singleSignOnUrl: null,
  singleSignOnUrlLogout: null,
  loginBackground: null,
  favicon: null,
  logo: null,
  dateFormat: null,
  distanceUnit: 'km',
  timezone: 'America/Montreal',
  iso3166CountryCode: 'CA',
  vehicleOptions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TENANT_FETCHED:
      return {
        ...state,
        btob: action.tenant.state_machine === 'btob_default',
        language: action.tenant.language,
        theme: action.tenant.theme,
        prepareDelay: action.tenant.prepare_delay,
        distanceUnit: action.tenant.distance_unit,
        dateFormat:
          action.tenant.date_format === 'd/m/y'
            ? dateInputFrFormat
            : action.tenant.date_format === 'y-m-d'
            ? dateInputFormat
            : dateInputFrFormat,
        minimumReservationDuration:
          action.tenant.minimum_reservation_duration !== -1
            ? action.tenant.minimum_reservation_duration
            : 901 /* 15 minutes */,
        maximumReservationDuration: action.tenant.maximum_reservation_duration,
        cancellationMinimalDelay:
          action.tenant.cancellation_minimal_delay !== -1
            ? action.tenant.cancellation_minimal_delay
            : 1 /* 1 seconde */,
        boundsModificationMinimalDelay:
          action.tenant.bounds_modification_minimal_delay !== -1
            ? action.tenant.bounds_modification_minimal_delay
            : 1 /* 1 seconde */,
        damageReportMode: action.tenant.damage_report_mode,
        maximumTimeInAdvance:
          action.tenant.rental_maximum_time_in_advance !== -1
            ? action.tenant.rental_maximum_time_in_advance
            : 28080000 /* 1 year */,
        useCategories: action.tenant.use_categories,
        useEntities: action.tenant.use_entities,
        useLabels: action.tenant.use_labels,
        useTermsAndConditions: action.tenant.use_terms_and_conditions,
        usePrivacyUsage: action.tenant.use_privacy_usage,
        usePrivacyUsageTimeSlot: action.tenant.use_privacy_usage_time_slot,
        useEstimatedDistance: action.tenant.use_estimated_distance,
        useEstimatedDestinationField:
          action.tenant.use_estimated_destination_field,
        usePayments: action.tenant.use_payments,
        requiredPaymentCard: action.tenant.required_payment_card,
        useBilling: action.tenant.use_billing,
        useCarpooling: action.tenant.use_carpooling,
        useFleetPlanning: action.tenant.use_front_fleet_planning,
        useMultilanguage: action.tenant.use_multi_language,
        useRegistration: action.tenant.use_registration,
        autoRegister:
          action.tenant.settings['users.autoregister?'] === 'enabled'
            ? true
            : false,
        useCookieConsent: action.tenant.use_cookie_consent,
        stateMachine: action.tenant.state_machine,
        searchEngine: action.tenant.search_engine,
        loginBackground: action.tenant.login_background,
        human_name: action.tenant.human_name,
        favicon: action.tenant.favicon,
        requiredDriverFields: action.tenant.required_driver_fields,
        driverFields: action.tenant.driver_fields,
        driverDocumentsLicence:
          action.tenant.settings[
            'registration_flows.driver_on_documents_licence_form?'
          ] === 'enabled'
            ? true
            : false,
        driver_on_sms_form:
          action.tenant.settings['registration_flows.driver_on_sms_form?'] ===
          'enabled'
            ? true
            : false,
        driver_on_documents_identity_form:
          action.tenant.settings[
            'registration_flows.driver_on_documents_identity_form?'
          ] === 'enabled'
            ? true
            : false,
        driverDocumentsSelfie:
          action.tenant.settings[
            'registration_flows.driver_on_documents_selfie_form?'
          ] === 'enabled'
            ? true
            : false,
        driver_on_documents_other_form:
          action.tenant.settings[
            'registration_flows.driver_on_documents_other_form?'
          ] === 'enabled'
            ? true
            : false,
        logo: action.tenant.logo,
        platformType: action.tenant.platform_type,
        vehicleOptions: action.tenant.vehicle_options,
        useSingleSignOn:
          action.tenant.use_single_sign_on &&
          action.tenant.single_sign_on_provider === 'openid' &&
          action.tenant.single_sign_on_url !== null,
        singleSignOnIssuer: action.tenant.single_sign_on_issuer,
        singleSignOnProvider: action.tenant.single_sign_on_provider,
        singleSignOnClientId: action.tenant.single_sign_on_client_id,
        singleSignOnAuthorizationEndpoint:
          action.tenant.single_sign_on_authorization_endpoint,
        singleSignOnUrl: action.tenant.single_sign_on_url,
        singleSignOnUrlLogout: action.tenant.single_sign_on_url_logout,
        iso3166CountryCode:
          action.tenant.settings['general.iso3166_country_code?'],
        timezone: action.tenant.timezone
          ? action.tenant.timezone
          : 'America/Montreal'
      };
    case FETCH_REGISTRATION_STEP:
      return {
        ...state,
        registrationSteps: _.map(action.registrationSteps, function(object) {
          return object.name;
        })
      };
    case FETCH_ADDITIONAL_DOCUMENTS:
      return {
        ...state,
        additionalDocumentsFr: action.additionalDocumentsFr,
        additionalDocumentsEn: action.additionalDocumentsEn
      };
    default:
      return state;
  }
};
