import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map, Marker } from 'google-maps-react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import InfoWindowEx from './InfoWindowEx';
import pinUser from '../../assets/images/pin-user.svg';
import {
  SEARCH_MAP_DRAGGED,
  SEARCH_MAP_ZOOM_CHANGED
} from '../../store/actions/search';
import styles from '../../assets/maps/theme-silver';

const mapStyles = {
  width: '100%',
  height: '100%'
};

const SearchMap = ({
  google,
  mapCenter,
  markers = [],
  selectedResult,
  userPosition,
  zoom,
  bounds,
  handleChangeStations
}) => {
  const { t } = useTranslation(['search']);
  const dispatch = useDispatch();
  const theme = useTheme();
  const stations = useSelector(state => state.stations.list);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const handleDrag = (mapProps, map) => {
    dispatch({
      type: SEARCH_MAP_DRAGGED,
      latitude: map.center.lat(),
      longitude: map.center.lng()
    });
  };

  const handleZoom = (mapProps, map) => {
    dispatch({
      type: SEARCH_MAP_ZOOM_CHANGED,
      zoom: map.zoom
    });
  };

  const onMapClicked = () => {
    setActiveMarker(null);
    setIsInfoVisible(false);
  };

  const onMarkerClick = (props, marker, e) => {
    setActiveMarker(marker);
    setIsInfoVisible(true);
  };

  const handleSelectedStation = marker => {
    let station = _.find(stations, { id: marker.station_id });
    handleChangeStations(station);
  };

  return (
    <div className="map-wrapper">
      <Map
        google={google}
        zoom={zoom}
        style={mapStyles}
        initialCenter={mapCenter}
        center={mapCenter}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        styles={styles}
        onDragend={handleDrag}
        onClick={onMapClicked}
        onZoom_changed={handleZoom}
        bounds={bounds}
      >
        {userPosition.lat && userPosition.lng ? (
          <Marker position={userPosition} icon={pinUser} />
        ) : null}
        {markers.map(marker => (
          <Marker
            key={marker.id}
            station_id={marker.id}
            name={marker.name}
            description={marker.description}
            address={marker.address}
            position={marker.location}
            onClick={(props, marker, e) => {
              onMarkerClick(props, marker);
            }}
            icon={`${
              marker.id === selectedResult
                ? theme.tenant.assets.pinBigMap
                : theme.tenant.assets.pinSmallMap
            }`}
          />
        ))}

        {activeMarker ? (
          <InfoWindowEx marker={activeMarker} visible={isInfoVisible}>
            <div>
              <h2>{activeMarker.name}</h2>
              <h4>{activeMarker.address}</h4>
              <p>{activeMarker.description}</p>
              <a
                className="button-info-window-station"
                onClick={handleSelectedStation.bind(this, activeMarker)}
              >
                {t('search:button-map-info')}
              </a>
            </div>
          </InfoWindowEx>
        ) : null}
      </Map>
    </div>
  );
};

export default SearchMap;
