import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import AccountBoxOutlined from '@material-ui/icons/AccountBoxOutlined';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LanguageOutlined from '@material-ui/icons/LanguageOutlined';
import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined';
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

import closeIcon from '../../../assets/images/close.svg';
import DrawerFooter from '../DrawerFooter';
import UserInfo from './UserInfo';
import { createGuestToken, logOut } from '../../../store/actions/auth';
import config from '../../../config';
import { useLanguageLoader } from '../../../hooks/languageLoader';
import { isRegistrationOK } from '../../../utils/stepMachine';
import { useModal } from '../../../utils/modal';

const MemberMenu = ({ onClose, location }) => {
  const { t } = useTranslation(['account', 'common']);
  const privacyModal = useModal();
  const [isHelpOpen, setIsHelpOpen] = useState(
    location.pathname.indexOf(`/${config.auth.rootUrl}/help/`) > -1
  );
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.tenant);
  const profile = useSelector(state => state.account);
  const usePayments = tenant.usePayments;
  const useFleetPlanning = tenant.useFleetPlanning;
  const useMultilanguage = tenant.useMultilanguage;
  const useLanguage = useLanguageLoader();

  const handleHelpClick = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  const handleLanguageClick = () => {
    setIsLanguageOpen(!isLanguageOpen);
  };

  const handleFleetPlanningLink = () => {
    window.location.href = '/frontoffice/calendars';
  };

  const handleBackOfficeLink = () => {
    window.location.href = '/backoffice';
  };

  const handleLogout = async () => {
    const id_token = localStorage.getItem('id_token');
    await dispatch(logOut(tenant.useSingleSignOn && id_token));
    if (tenant.useSingleSignOn && id_token) {
      let url =
        tenant.singleSignOnUrlLogout +
        '&state=' +
        encodeURI(
          `{"source": "frontofficev2", "redirect_to": "${window.location.origin}/frontofficev2/"}`
        ) +
        '&id_token_hint=' +
        id_token;
      window.location.href = url;
    }
    await dispatch(createGuestToken());
  };

  return (
    <div className="user-menu member-menu">
      <div className="drawer-header">
        <div className="close-button">
          <IconButton onClick={onClose}>
            <img src={closeIcon} alt="" width="30" height="30" />
          </IconButton>
        </div>

        <UserInfo />
      </div>

      <List className="drawer-menu">
        <ListItem
          button
          className="drawer-menu-item"
          component={NavLink}
          to={`${config.auth.rootUrl}account/bookings`}
        >
          <ListItemIcon>
            <DirectionsCarOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t('account:bookings')}></ListItemText>
        </ListItem>

        {tenant.useRegistration && !isRegistrationOK(profile.serverStep) ? (
          <ListItem
            button
            className="drawer-menu-item"
            component={NavLink}
            to={`${config.auth.rootUrl}signup`}
          >
            <ListItemIcon>
              <AccountBoxOutlined />
            </ListItemIcon>
            <ListItemText
              primary={t('account:account-end-register')}
            ></ListItemText>
          </ListItem>
        ) : (
          <ListItem
            button
            className="drawer-menu-item"
            component={NavLink}
            to={`${config.auth.rootUrl}account/details`}
          >
            <ListItemIcon>
              <AccountBoxOutlined />
            </ListItemIcon>
            <ListItemText primary={t('account:account-details')}></ListItemText>
          </ListItem>
        )}

        {usePayments ? (
          <ListItem
            button
            className="drawer-menu-item"
            component={NavLink}
            to={`${config.auth.rootUrl}account/payment-method`}
          >
            <ListItemIcon>
              <PaymentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('account:payment-methods')}></ListItemText>
          </ListItem>
        ) : null}

        {profile.canAccessBackOffice ? (
          <ListItem
            button
            className="drawer-menu-item"
            onClick={handleBackOfficeLink}
          >
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText primary={t('account:backoffice')}></ListItemText>
          </ListItem>
        ) : null}

        {useFleetPlanning ? (
          <ListItem
            button
            className="drawer-menu-item"
            onClick={handleFleetPlanningLink}
          >
            <ListItemIcon>
              <DateRangeOutlined />
            </ListItemIcon>
            <ListItemText primary={t('account:fleet-planning')}></ListItemText>
          </ListItem>
        ) : null}

        <ListItem button className="drawer-menu-item" onClick={handleHelpClick}>
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:help')}></ListItemText>
          {isHelpOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
          className="collapse-menu-item"
          in={isHelpOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem
              button
              className="drawer-menu-item nested-item"
              component={NavLink}
              to={`${config.auth.rootUrl}account/help/faq`}
            >
              <ListItemText primary={t('account:faq')} />
            </ListItem>
            <ListItem
              button
              className="drawer-menu-item nested-item"
              component={NavLink}
              to={`${config.auth.rootUrl}account/help/contact`}
            >
              <ListItemText primary={t('account:contact')} />
            </ListItem>
          </List>
        </Collapse>

        {useMultilanguage ? (
          <ListItem
            button
            className="drawer-menu-item"
            onClick={handleLanguageClick}
          >
            <ListItemIcon>
              <LanguageOutlined />
            </ListItemIcon>
            <ListItemText primary={t('common:language')}></ListItemText>
            {isLanguageOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ) : null}

        {useMultilanguage ? (
          <Collapse
            className="collapse-menu-item"
            in={isLanguageOpen}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                className="drawer-menu-item nested-item"
                onClick={useLanguage.toggleFrench}
              >
                <ListItemText primary={t('account:language-fr')} />
              </ListItem>
              <ListItem
                className="drawer-menu-item nested-item"
                onClick={useLanguage.toggleEnglish}
              >
                <ListItemText primary={t('account:language-en')} />
              </ListItem>
            </List>
          </Collapse>
        ) : null}

        <ListItem
          button
          className="drawer-menu-item"
          component={NavLink}
          to={`${config.auth.rootUrl}account/privacy`}
        >
          <ListItemIcon>
            <LockOpenOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t('account:privacy')}></ListItemText>
        </ListItem>

        <ListItem button className="drawer-menu-item" onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppOutlined />
          </ListItemIcon>
          <ListItemText primary={t('account:logout')}></ListItemText>
        </ListItem>
      </List>

      <DrawerFooter />
    </div>
  );
};

export default withRouter(MemberMenu);
