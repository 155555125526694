import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import closeIcon from '../../assets/images/close.svg';
import VehicleDetails from '../../components/vehicles/VehicleDetails';
import Photo from '../../components/vehicles/VehicleDetails/Photo';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utils/api';
import {
  SEARCH_RESULT_CLOSED,
  SEARCH_RESULT_BOOKING_REQUESTED
} from '../../store/actions/search';
import { useVehicleLoader } from '../../hooks/vehicleLoader';
import { useStationLoader } from '../../hooks/stationLoader';
import { useScrollRestore } from '../../hooks/scrollRestore';
import { useCustomContent } from '../../hooks/customContent';
import {
  getSelectedSearchResult,
  getVehicleSearchDuration,
  getSearchDates
} from '../../store/selectors/search';
import Price from '../../components/shared/Price';
import { fullSearchFilterDateFormat } from '../../utils/variables';
import config from '../../config';

const VehicleView = () => {
  const { t } = useTranslation(['booking', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const search = useSelector(state => state.search);
  const searchDates = useSelector(getSearchDates);
  const vehicleId = search.selectedResult;
  const stationId = search.selectedStation;
  const rental = search.rental;
  const startDate = searchDates.startDate;
  const endDate = searchDates.endDate;
  const { vehicle } = useVehicleLoader(vehicleId);
  const { station } = useStationLoader(stationId);
  const userId = useSelector(state => state.account.id);
  const tenant = useSelector(state => state.tenant);
  const btob = tenant.btob;

  const faq = useCustomContent('faq');
  const rentalDuration = useSelector(getVehicleSearchDuration);
  const searchResult = useSelector(getSelectedSearchResult);
  const [driver, setDriver] = useState(null);

  useScrollRestore(vehicleId);

  const [formState, setFormState] = useState({
    carpooling: search.carpooling ? search.carpooling : false,
    carpoolingDestination: search.carpoolingDestination
      ? search.carpoolingDestination
      : ''
  });

  const handleClose = () => {
    dispatch({
      type: SEARCH_RESULT_CLOSED
    });
  };

  const handleBookingButton = () => {
    if (btob && tenant.useCarpooling && !rental) {
      if (formState.carpoolingDestination !== '' && !formState.carpooling) {
        enqueueSnackbar(
          t('booking:error-message-destination-whitout-checkbox'),
          { variant: 'warning' }
        );
        return;
      }
    }
    dispatch({
      type: SEARCH_RESULT_BOOKING_REQUESTED,
      carpooling: formState.carpooling,
      carpoolingDestination: formState.carpoolingDestination
    });
  };

  const handleCarpooling = event => {
    setFormState({
      ...formState,
      carpooling: event.target.checked
    });
  };

  const handleCarpoolingDestination = event => {
    setFormState({
      ...formState,
      carpoolingDestination: event.target.value
    });
  };

  useEffect(() => {
    const loadDriver = async () => {
      if (rental === null) return;
      const userResponse = await api.user.getProfile(rental.driver_id);
      const driver = userResponse.user;

      setDriver(driver);
    };

    loadDriver();
  }, []);

  return (
    <div className="vehicle-booking">
      {vehicle && station ? (
        <Container
          maxWidth="md"
          className={isMobile ? 'mobile-container' : 'page-container'}
        >
          <div className="page-header">
            {rental && rental.driver_id !== userId ? (
              <Typography component="h1">
                {t('booking:vehicle-carpooling')}
              </Typography>
            ) : (
              <Typography component="h1">
                {t('booking:vehicle-details')}
              </Typography>
            )}

            <div className="close-button">
              <IconButton onClick={handleClose}>
                <img src={closeIcon} alt="" width="30" height="30" />
              </IconButton>
            </div>
          </div>

          <Photo image={vehicle.photos ? vehicle.photos[0] : null} />

          <Paper
            className={
              tenant.useCarpooling && !rental ? 'download-app-box' : 'hidden'
            }
          >
            <div className="space-between">
              <div>
                <h2>{t('booking:carpooling-title')}</h2>
              </div>
              <div className="carpooling">
                <Checkbox
                  className="checkbox"
                  name="carpooling"
                  value={formState.carpooling}
                  checked={formState.carpooling}
                  onChange={handleCarpooling}
                  color="primary"
                />
              </div>
            </div>
            <div className="text">
              <p>{t('booking:carpooling-sentence')}</p>
            </div>

            <div className="text">
              <div className="form-group">
                <TextField
                  name="destination"
                  label={t('booking:destination-carpooling')}
                  variant="outlined"
                  margin="none"
                  value={formState.carpoolingDestination}
                  onChange={handleCarpoolingDestination}
                  fullWidth
                />
              </div>
            </div>
          </Paper>

          <VehicleDetails vehicle={vehicle} station={station} driver={driver} />

          <Grid container justify="center">
            <Button
              className="help-button"
              variant="outlined"
              color="primary"
              component={Link}
              to={`${config.auth.rootUrl}account/help/faq`}
            >
              {t('booking:how-it-works')}
            </Button>
          </Grid>

          <div className="booking-box">
            <div className="summary">
              <div className="summary-content">
                <span>
                  {moment(
                    rental ? rental.reservation_start_time : startDate
                  ).format(fullSearchFilterDateFormat)}
                  -
                  {moment(
                    rental ? new Date(rental.reservation_end_time) : endDate
                  ).format(fullSearchFilterDateFormat)}
                </span>
                {rentalDuration ? (
                  <div className="duration">({rentalDuration})</div>
                ) : null}

                {searchResult && searchResult.price ? (
                  <div className="price">
                    {searchResult && searchResult.price.value_cents > 0
                      ? 'Total : '
                      : null}
                    <Price
                      cents={searchResult.price.value_cents}
                      currency={searchResult.price.iso_code}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="button-wrapper">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleBookingButton}
              >
                {rental && rental.driver_id !== userId
                  ? t('booking:book-carpooling')
                  : t('booking:book-now')}
              </Button>
            </div>
          </div>
        </Container>
      ) : null}
    </div>
  );
};

export default VehicleView;
