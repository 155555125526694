import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createGuestToken, logOut } from '../../store/actions/auth';
import config from '../../config';
import { getParameterByName } from '../../utils/paramsHelper';

function ProcessCompleteMessage({ history }) {
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.tenant);
  const account = useSelector(state => state.account);
  const redirect_uri = getParameterByName('redirect_uri');
  const { t } = useTranslation(['account', 'common']);

  const handleButtonClick = () => {
    const access_token = localStorage.getItem(config.auth.accessTokenKey);

    if (redirect_uri)
      window.location.href =
        decodeURIComponent(redirect_uri) + '?access_token=' + access_token;
    else history.push(config.auth.rootUrl);
  };

  const handleLogout = async () => {
    await dispatch(logOut());
    await dispatch(createGuestToken());
    history.push(`${config.auth.rootUrl}login`);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1">{t('common:thanks')},</Typography>

      <div className="approval-needed">{t('account:agent-review-needed')}</div>

      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={12} md={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogout}
          >
            {t('account:logout')}
          </Button>
        </Grid>
        {redirect_uri !== null ? (
          <Grid item xs={12} sm={12} md={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
            >
              {t('common:back-mobile')}
            </Button>
          </Grid>
        ) : null}
        {redirect_uri === null && tenant.platformType === 'public' ? (
          <Grid item xs={12} sm={12} md={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
            >
              {t('common:back-home')}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

export default withRouter(ProcessCompleteMessage);
