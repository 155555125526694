import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const GearsTypeAutocomplete = ({ initialValue, onValueChanged }) => {
  const gearsTypes = ['all', 'manual', 'semi_automatic', 'automatic'];

  const { t } = useTranslation(['search']);
  const [gear, setInputValue] = useState(initialValue);

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      onValueChanged({ gear });
    }
  }, [gear]);

  return (
    <div className="location-input">
      <Paper className="input-wrapper">
        <Autocomplete
          value={gear}
          className="input-control"
          options={gearsTypes}
          getOptionLabel={gearType => t(`gears-type-${gearType}`)}
          onChange={(event, value) => setInputValue(value)}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              variant="standard"
            />
          )}
        />
      </Paper>
    </div>
  );
};

export default GearsTypeAutocomplete;
