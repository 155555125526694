import { STATION_FETCHED } from '../actions/stations';
import { find } from 'lodash';
import { STATIONS_FETCHED } from '../actions/stations';

const initialState = {
  list: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STATION_FETCHED:
      let stationExist = find(state.list, { id: action.id });

      if (stationExist) {
        return state;
      } else {
        let stations = [...state.list, action.stations];
        return {
          ...state,
          list: stations
        };
      }
    case STATIONS_FETCHED:
      return {
        ...state,
        list: action.stations
      };
    default:
      return state;
  }
};
