import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorToaster } from '../../utils/errorToaster';
import DateRangeInput from '../search/DateRangeInput';
import { updateRental } from '../../store/actions/rentals';
import {
  initMinDate,
  initMinEndDate,
  initMaxStartDate,
  initBoundsModificationMinimalDelay
} from '../../store/selectors/tenant';
import { datePickerFormat } from '../../utils/variables';

const ModifyBookingModal = ({ open, onClose, rental, onSuccess }) => {
  const { t } = useTranslation(['common', 'booking', 'search']);
  const dispatch = useDispatch();
  const displayError = useErrorToaster();
  const language = useSelector(state => state.app.language);
  const timezone = useSelector(state => state.tenant.timezone);
  const boundsModificationMinimalDelay = useSelector(
    initBoundsModificationMinimalDelay
  );
  const minEndDate = useSelector(initMinEndDate);
  const maxStartDate = useSelector(initMaxStartDate);
  const minDate = useSelector(initMinDate);
  const [isProcessing, setProcessing] = useState(false);

  const [formState, setFormState] = useState({
    startDate: new Date(
      moment(rental.reservation_start_time).format(datePickerFormat)
    ),
    endDate: new Date(
      moment(rental.reservation_end_time).format(datePickerFormat)
    ),
    minStartDate: minDate,
    maxStartDate: maxStartDate,
    minEndDate: minEndDate,
    maxEndDate: maxStartDate,
    boundsModificationMinimalDelay: boundsModificationMinimalDelay
  });

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleDateRange = ({ startDate, endDate }) => {
    setFormState({
      ...formState,
      startDate,
      endDate
    });
  };

  const handleConfirm = async () => {
    setProcessing(true);

    try {
      await dispatch(
        updateRental({
          ...formState,
          id: rental.id
        })
      );
      onSuccess();
    } catch (error) {
      displayError(error);
      setProcessing(false);
    }
  };

  const submitButtonEnabled = () => {
    return isProcessing || !isEditable();
  };

  const isEditable = () => {
    return (
      moment(formState.startDate) > formState.boundsModificationMinimalDelay
    );
  };

  const errorMessage = (
    <div className="error-message">{t('booking:modify-delay-too-short')}</div>
  );

  return (
    <div className="modify-booking-modal">
      <Dialog
        open={open}
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="booking-dialog-title"
      >
        <DialogTitle id="booking-dialog-title">
          {t('booking:modification-modal-title')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {t('booking:modification-modal-text')}
          </DialogContentText>
          <DialogContentText>
            {!isEditable() ? errorMessage : null}
          </DialogContentText>

          <Container maxWidth="xs">
            <div className="search-form">
              <div className="form-group">
                <div className="flex-labels">
                  <div className="input-label">{t('search:departure')}</div>
                  <div className="input-label">{t('search:return')}</div>
                </div>
                <DateRangeInput
                  instantSearch={false}
                  withPortal={true}
                  language={language}
                  timezone={timezone}
                  initialValue={{
                    startDate: formState.startDate,
                    endDate: formState.endDate,
                    minStartDate: formState.minStartDate,
                    maxStartDate: formState.maxStartDate,
                    minEndDate: formState.minEndDate,
                    maxEndDate: formState.maxEndDate
                  }}
                  onValueChanged={handleDateRange}
                />
              </div>
            </div>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('common:back')}</Button>

          <Button
            type="submit"
            color="primary"
            disabled={submitButtonEnabled()}
            onClick={handleConfirm}
          >
            {t('common:confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModifyBookingModal;
