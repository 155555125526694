import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DateInput from '../../components/shared/DateInput/DateInput';

const AccountDateInput = ({
  handleChange,
  values,
  errors,
  touched,
  keyName,
  driverKey,
  Keylabel
}) => {
  const { t } = useTranslation(['account']);
  const tenant = useSelector(state => state.tenant);

  return (
    <div className="form-group">
      {tenant.driverFields.includes(driverKey) && (
        <DateInput
          required={tenant.requiredDriverFields.includes(driverKey)}
          id={keyName}
          name={keyName}
          placeholder={t(Keylabel)}
          value={values[keyName]}
          onChange={handleChange}
          error={touched[keyName] && !!errors[keyName]}
          helperText={
            touched[keyName] && errors[keyName] ? errors[keyName] : null
          }
        />
      )}
    </div>
  );
};

export default AccountDateInput;
