import api from '../../utils/api';

export const ACCOUNT_USER_FETCHED = 'ACCOUNT_USER_FETCHED';
export const ACCOUNT_DETAILS_SAVED = 'ACCOUNT_DETAILS_SAVED';
export const ACCOUNT_CREDIT_CARD_CREATED = 'ACCOUNT_CREDIT_CARD_CREATED';
export const ACCOUNT_CREDIT_CARD_FETCHED = 'ACCOUNT_CREDIT_CARD_FETCHED';
export const ACCOUNT_NEW_PHONE_NUMBER_SUBMITTED =
  'ACCOUNT_NEW_PHONE_NUMBER_SUBMITTED';
export const ACCOUNT_NEW_PHONE_NUMBER_DISCARDED =
  'ACCOUNT_NEW_PHONE_NUMBER_DISCARDED';
export const ACCOUNT_NEW_PHONE_NUMBER_VALIDATED =
  'ACCOUNT_NEW_PHONE_NUMBER_VALIDATED';

export function fetchUserData() {
  return async function(dispatch) {
    try {
      const response = await api.user.getUser();

      dispatch({
        type: ACCOUNT_USER_FETCHED,
        user: response.user
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateUser({
  firstName,
  lastName,
  addressLine1,
  addressLine2,
  addressPostalCode,
  addressLocality,
  addressState,
  addressCountry,
  phoneNumber,
  birthdayTime,
  birthdayLocation,
  licenceNumber,
  licenceObtainingTime,
  licenceLocation,
  licenceExpirationTime
}) {
  return async function(dispatch) {
    try {
      const response = await api.user.updateUser({
        first_name: firstName,
        last_name: lastName,
        cell_phone: phoneNumber,
        birthday_location: birthdayLocation,
        birthday_time: birthdayTime,
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_postal_code: addressPostalCode,
        address_locality: addressLocality,
        address_state: addressState,
        address_country: addressCountry,
        driving_licence_number: licenceNumber,
        driving_licence_obtaining_time: licenceObtainingTime,
        driving_licence_location: licenceLocation,
        driving_licence_expiration_time: licenceExpirationTime
      });

      dispatch({
        type: ACCOUNT_DETAILS_SAVED,
        user: response.user
      });
    } catch (error) {
      throw error;
    }
  };
}
export function sendAcceptTos(terms_and_conditions_version_id) {
  return async function(dispatch) {
    try {
      const response = await api.user.sendAcceptTos(
        terms_and_conditions_version_id
      );
      dispatch({
        type: ACCOUNT_DETAILS_SAVED,
        user: response.user
      });
    } catch (error) {
      throw error;
    }
  };
}

export function savePhone(phoneNumber) {
  return async function(dispatch) {
    try {
      await api.user.sendSmsValidationCode(phoneNumber);

      dispatch({
        type: ACCOUNT_NEW_PHONE_NUMBER_SUBMITTED,
        newPhoneNumber: phoneNumber
      });
    } catch (error) {
      throw error;
    }
  };
}

export function sendValidationCode(phoneNumber) {
  return async function() {
    try {
      await api.user.sendSmsValidationCode(phoneNumber);
    } catch (error) {
      throw error;
    }
  };
}

export function sendDataRequest() {
  return async function() {
    try {
      await api.user.sendDataRequest();
    } catch (error) {
      throw error;
    }
  };
}

export function submitValidationCode(code) {
  return async function(dispatch) {
    try {
      await api.user.validateSmsCode(code);
    } catch (error) {
      throw error;
    }
  };
}

export function createStripeSetupIntents() {
  return async function(dispatch) {
    try {
      const response = await api.user.createStripeSetupIntents();
      const stripe_setup_intent = response.stripe_setup_intent;
    } catch (error) {
      throw error;
    }
  };
}
export function confimStripeCard(setupIntent, paymentMethod) {
  return async function(dispatch) {
    try {
      const response = await api.user.confirmStripeCard(
        setupIntent,
        paymentMethod
      );

      dispatch({
        type: ACCOUNT_CREDIT_CARD_CREATED,
        creditCard: response
      });
    } catch (error) {
      throw error;
    }
  };
}

export function confimFirstStripeCard(setupIntent, paymentMethod) {
  return async function(dispatch) {
    try {
      await api.user.confirmStripeCard(setupIntent, paymentMethod);
    } catch (error) {
      throw error;
    }
  };
}

export function submitNewPhoneNumber(phoneNumber) {
  return async function(dispatch) {
    try {
      await api.user.sendSmsValidationCode(phoneNumber);

      dispatch({
        type: ACCOUNT_NEW_PHONE_NUMBER_SUBMITTED,
        phoneNumber
      });
    } catch (error) {
      throw error;
    }
  };
}

export function validateNewPhoneNumber(phoneNumber, validationCode) {
  return async function(dispatch) {
    try {
      await api.user.validateSmsCode(validationCode);
      await api.user.updateUser({
        cell_phone: phoneNumber
      });

      dispatch({
        type: ACCOUNT_NEW_PHONE_NUMBER_VALIDATED
      });
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCreditCards() {
  return async function(dispatch) {
    try {
      const response = await api.user.getCreditCards();

      dispatch({
        type: ACCOUNT_CREDIT_CARD_FETCHED,
        creditCards: response.stripe_cards
      });
    } catch (error) {
      throw error;
    }
  };
}

export function setDefaultCreditCard(cardId) {
  return async function(dispatch) {
    try {
      const response = await api.user.setDefaultCreditCard(cardId);
    } catch (error) {
      throw error;
    }
  };
}

export function deleteCreditCard(cardId) {
  return async function(dispatch) {
    try {
      const response = await api.user.deleteCreditCard(cardId);
    } catch (error) {
      throw error;
    }
  };
}

export function resetPassword({ email }) {
  return async function(dispatch) {
    try {
      const response = await api.user.resetPassword(email);
    } catch (error) {
      throw error;
    }
  };
}

export function confirmPassword(
  password,
  passwordConfirmation,
  resetPasswordToken
) {
  return async function(dispatch) {
    try {
      const response = await api.user.confirmPassword(
        password,
        passwordConfirmation,
        resetPasswordToken
      );
    } catch (error) {
      throw error;
    }
  };
}
