import React from 'react';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';

const CategoryFilter = ({ handleDeleteCategory, onClick }) => {
  const categoriesIdSelect = useSelector(state => state.search.categories);
  const categories = useSelector(state => state.categories.list);

  const getCategory = categoryId => {
    return _.find(categories, { id: categoryId });
  };

  if (categoriesIdSelect.length > 0) {
    return (
      <div style={{ display: 'inline' }}>
        {categoriesIdSelect.map(categoryId => (
          <Chip
            key={categoryId}
            label={getCategory(categoryId).name}
            onClick={onClick}
            onDelete={() => handleDeleteCategory(categoryId)}
            color="primary"
            variant="outlined"
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default CategoryFilter;
