import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import closeIcon from '../../assets/images/close.svg';

const CustomContent = ({ title, subtitle, content, handleReturn }) => {
  return (
    <div className="custom-content">
      <div className="page-header">
        <Typography component="h1">{title}</Typography>

        <div className="close-button">
          <IconButton onClick={handleReturn}>
            <img src={closeIcon} alt="" width="30" height="30" />
          </IconButton>
        </div>
      </div>

      <Typography component="h2">{subtitle}</Typography>

      {content ? (
        <div
          dangerouslySetInnerHTML={{ __html: decodeURIComponent(content) }}
        ></div>
      ) : null}
    </div>
  );
};

export default CustomContent;
