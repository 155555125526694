import { AUTH_LOGGED_OUT, AUTH_REGISTERED } from '../actions/auth';
import {
  ACCOUNT_CREDIT_CARD_CREATED,
  ACCOUNT_CREDIT_CARD_FETCHED,
  ACCOUNT_NEW_PHONE_NUMBER_DISCARDED,
  ACCOUNT_NEW_PHONE_NUMBER_SUBMITTED,
  ACCOUNT_NEW_PHONE_NUMBER_VALIDATED,
  ACCOUNT_USER_FETCHED,
  ACCOUNT_DETAILS_SAVED
} from '../actions/account';
import {
  getUserStatus,
  isRegistrationComplete,
  mapServerStep
} from '../../utils/stepMachine';
import moment from 'moment-timezone';

const initialState = {
  isRegistrationComplete: false,
  registrationStep: 'registration',
  serverStep: 'driver_on_login_form',
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  tosAccepted: null,
  addressLine1: null,
  addressLine2: null,
  addressPostalCode: null,
  addressLocality: null,
  addressState: null,
  birthdayLocation: null,
  birthdayTime: null,
  address_country: null,
  avatar: null,
  phoneNumber: null,
  newPhoneNumber: null,
  licenceNumber: null,
  licenceExpirationTime: null,
  licenceLocation: null,
  licenceObtainingTime: null,
  status: null,
  creditCards: [],
  canAccessBackOffice: false,
  isLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGGED_OUT:
      return initialState;
    case ACCOUNT_USER_FETCHED:
      return {
        ...state,
        id: action.user.id,
        email: action.user.email,
        firstName: action.user.first_name,
        lastName: action.user.last_name,
        addressLine1: action.user.address_line1,
        addressLine2: action.user.address_line2,
        addressPostalCode: action.user.address_postal_code,
        addressLocality: action.user.address_locality,
        addressState: action.user.address_state,
        addressCountry: action.user.address_country,
        tosAccepted: action.user.tos_accepted,
        birthdayTime: action.user.birthday_time,
        birthdayLocation: action.user.birthday_location,
        phoneNumber: action.user.cell_phone,
        licenceNumber: action.user.driving_licence_number,
        licenceLocation: action.user.driving_licence_location,
        licenceExpirationTime: action.user.driving_licence_expiration_time,
        licenceObtainingTime: action.user.driving_licence_obtaining_time,
        serverStep: action.user.workflow_next_step,
        registrationStep: mapServerStep(action.user.workflow_next_step),
        isRegistrationComplete: isRegistrationComplete(
          action.user.workflow_next_step
        ),
        status: getUserStatus(
          action.user.validated,
          action.user.workflow_next_step
        ),
        avatar: action.user.small_main_photo_url,
        canAccessBackOffice: action.user.can_access_backoffice,
        isLoaded: true
      };
    case ACCOUNT_DETAILS_SAVED:
      return {
        ...state,
        id: action.user.id,
        email: action.user.email,
        firstName: action.user.first_name,
        lastName: action.user.last_name,
        addressLine1: action.user.address_line1,
        addressLine2: action.user.address_line2,
        addressPostalCode: action.user.address_postal_code,
        addressLocality: action.user.address_locality,
        addressState: action.user.address_state,
        addressCountry: action.user.address_country,
        tosAccepted: action.user.tos_accepted,
        birthdayTime: action.user.birthday_time,
        birthdayLocation: action.user.birthday_location,
        phoneNumber: action.user.cell_phone,
        licenceNumber: action.user.driving_licence_number,
        licenceLocation: action.user.driving_licence_location,
        licenceExpirationTime: action.user.driving_licence_expiration_time,
        licenceObtainingTime: action.user.driving_licence_obtaining_time,
        serverStep: action.user.workflow_next_step,
        registrationStep: mapServerStep(action.user.workflow_next_step),
        isRegistrationComplete: isRegistrationComplete(
          action.user.workflow_next_step
        ),
        status: getUserStatus(
          action.user.validated,
          action.user.workflow_next_step
        ),
        avatar: action.user.small_main_photo_url,
        canAccessBackOffice: action.user.can_access_backoffice,
        isLoaded: true
      };
    case AUTH_REGISTERED:
      return {
        ...state,
        email: action.user.email,
        registrationStep: mapServerStep(action.user.workflow_next_step)
      };
    case ACCOUNT_NEW_PHONE_NUMBER_SUBMITTED:
      return {
        ...state,
        newPhoneNumber: action.phoneNumber || action.newPhoneNumber
      };
    case ACCOUNT_NEW_PHONE_NUMBER_DISCARDED:
      return {
        ...state,
        newPhoneNumber: null
      };
    case ACCOUNT_NEW_PHONE_NUMBER_VALIDATED:
      return {
        ...state,
        phoneNumber: state.newPhoneNumber,
        newPhoneNumber: null
      };
    case ACCOUNT_CREDIT_CARD_CREATED:
      return {
        ...state,
        creditCards: [...state.creditCards, action.creditCard]
      };
    case ACCOUNT_CREDIT_CARD_FETCHED:
      return {
        ...state,
        creditCards: action.creditCards
      };
    default:
      return state;
  }
};
