import api from '../../utils/api';
import { overrideTimezone } from '../../utils/dateHelper';

export const SEARCH_SUBMITTED = 'SEARCH_SUBMITTED';
export const SEARCH_RESULTS_FETCHED = 'SEARCH_RESULTS_FETCHED';
export const SEARCH_CARPOOLINGS_RESULTS_FETCHED =
  'SEARCH_CARPOOLINGS_RESULTS_FETCHED';
export const SEARCH_RESULT_SELECTED = 'SEARCH_RESULT_SELECTED';
export const SEARCH_RESULT_CLOSED = 'SEARCH_RESULT_CLOSED';
export const SEARCH_GET_USER_POSITION = 'SEARCH_GET_USER_POSITION';
export const SEARCH_MAP_ZOOM_CHANGED = 'SEARCH_MAP_ZOOM_CHANGED';
export const SEARCH_MAP_DRAGGED = 'SEARCH_MAP_DRAGGED';
export const SEARCH_RESULT_BOOKING_REQUESTED =
  'SEARCH_RESULT_BOOKING_REQUESTED';
export const SEARCH_RESULT_BOOKING_DISCARDED =
  'SEARCH_RESULT_BOOKING_DISCARDED';
export const RESET_SEARCH_STATE = 'RESET_SEARCH_STATE';

export function submitSearch({
  location,
  startDate,
  endDate,
  categories,
  options,
  rentalLabel,
  estimatedDistance,
  destination,
  startStation,
  endStation,
  gearsType,
  seatsCount
}) {
  return async function(dispatch) {
    dispatch({
      type: SEARCH_SUBMITTED,
      startStation,
      endStation,
      estimatedDistance,
      destination,
      rentalLabel,
      location,
      startDate,
      endDate,
      categories,
      options,
      gearsType,
      seatsCount
    });

    try {
      const { total, results } = await api.vehicles.search({
        start_station_id: startStation.station ? startStation.station.id : null,
        end_station_id: endStation.station ? endStation.station.id : null,
        latitude: location.lat || null,
        longitude: location.long || null,
        categories,
        options,
        rental_label_id: rentalLabel ? rentalLabel.id : null,
        estimated_distance: estimatedDistance,
        destination_label: destination.label,
        destination_name: destination.name,
        destination_gmaps_place_id: destination.place_id,
        destination_formatted_address: destination.formatted_address,
        destination_latitude: destination.lat,
        destination_longitude: destination.long,
        startTime: overrideTimezone(startDate).format(),
        endTime: overrideTimezone(endDate).format(),
        gears_type: gearsType === 'all' ? null : gearsType,
        seats_count: seatsCount
      });

      dispatch({
        type: SEARCH_RESULTS_FETCHED,
        resultCount: total,
        results
      });

      return results;
    } catch (error) {
      throw error;
    }
  };
}

export function submitCarpooling({
  startDate,
  endDate,
  categories,
  rentalLabel,
  startStation,
  endStation
}) {
  return async function(dispatch) {
    try {
      const { carpoolings } = await api.rentals.searchCarpoolings({
        start_station_id: startStation.station ? startStation.station.id : null,
        end_station_id: endStation.station ? endStation.station.id : null,
        // categories, # TODO not handle in back end
        // rental_label_id: rentalLabel ? rentalLabel.id : null, # TODO not handle in back end
        start_time: overrideTimezone(startDate).format(),
        end_time: overrideTimezone(endDate).format()
      });

      dispatch({
        type: SEARCH_CARPOOLINGS_RESULTS_FETCHED,
        carpoolings: carpoolings
      });

      return carpoolings;
    } catch (error) {
      throw error;
    }
  };
}

export function locateUser() {
  return async function(dispatch) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        dispatch({
          type: SEARCH_GET_USER_POSITION,
          name: 'You',
          latitude: coords.latitude,
          longitude: coords.longitude
        });
      });
    }
  };
}

export function resetSearch() {
  return async function(dispatch) {
    dispatch({
      type: RESET_SEARCH_STATE
    });
  };
}
