import { CATEGORIES_FETCHED } from '../actions/categories';

const initialState = {
  list: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIES_FETCHED:
      return {
        ...state,
        list: action.categories
      };
    default:
      return state;
  }
};
