import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

const UserInfo = () => {
  const theme = useTheme();
  const { t } = useTranslation(['account']);
  const account = useSelector(state => state.account);
  const firstName = account.firstName;
  const lastName = account.lastName;
  const status = account.status;
  const avatar = account.avatar;

  const statusTexts = {
    verified: 'account:status-verified',
    pending_validation: 'account:status-pending-validation',
    incomplete: 'account:status-incomplete'
  };

  return (
    <div className="user-info">
      <div className="col-avatar">
        <div
          className="user-avatar"
          style={{
            border: `3px solid ${
              status === 'verified'
                ? theme.palette.primary.main
                : theme.palette.primary.light
            }`
          }}
        >
          {avatar ? (
            <Avatar src={avatar} />
          ) : (
            <Avatar>{firstName ? firstName[0].toUpperCase() : ''}</Avatar>
          )}
        </div>
      </div>
      <div className="col-main">
        <div className="user-name">
          {firstName} {lastName}
        </div>
        <div
          className="user-status"
          style={{
            color: theme.palette.primary.main,
            border: `1px solid ${
              status === 'verified'
                ? theme.palette.primary.main
                : theme.palette.secondary.main
            }`
          }}
        >
          {t(statusTexts[status])}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
