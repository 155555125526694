import React from 'react';
import PropTypes from 'prop-types';
import Step from './Step';

function Stepper({ title, steps, activeStep, completedSteps = [] }) {
  const stepElements = Object.keys(steps).map((stepKey, i) => (
    <Step
      key={`step-${i}`}
      title={steps[stepKey]}
      active={activeStep === stepKey}
      completed={completedSteps.includes(stepKey)}
    />
  ));

  return (
    <div className="stepper">
      <div className="stepper-title">{title}</div>

      <div className="steps">
        {stepElements}
      </div>
    </div>
  );
}

Stepper.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired
};

export default Stepper;
