import httpClient from '../httpClient';

export default {
  getProfile(id) {
    return httpClient('v0.4').get(`/users/${id}`);
  },
  getUser() {
    return httpClient('v0.4').get('/user');
  },
  createUser({ email, password }) {
    return httpClient('v0.4').post('/user', {
      user: {
        email,
        password,
        tos_accepted: true
      }
    });
  },
  updateUser(userData) {
    return httpClient('v0.4').put('/user', {
      user: {
        ...userData
      }
    });
  },
  deleteUser() {
    return httpClient('v0.4').delete(`/user`);
  },
  changePassword({ currentPassword, password, passwordConfirmation }) {
    return httpClient('v0.4').put('/user/password', {
      user: {
        current_password: currentPassword,
        password,
        password_confirmation: passwordConfirmation
      }
    });
  },
  sendSmsValidationCode(phoneNumber) {
    return httpClient('v0.3').post('/user/sms_code', {
      sms_code: {
        cell_phone: phoneNumber
      }
    });
  },
  sendDataRequest() {
    return httpClient('v0.4').post('/user/data_request');
  },
  validateSmsCode(code) {
    return httpClient('v0.3').put('/user/sms_code', {
      sms_code: {
        code
      }
    });
  },
  getDocuments() {
    return httpClient('v0.4').get('/user/documents');
  },
  addPhoto(file, config = {}) {
    const formData = new FormData();
    formData.append('photo[image]', file);

    return httpClient('v0.4').post(`/user/photos`, formData, config);
  },
  addDocument(type, file, config = {}) {
    const formData = new FormData();
    formData.append('document[file]', file);
    formData.append('document[document_type]', type);

    return httpClient('v0.4').post(`/user/documents`, formData, config);
  },
  attachDocument(documentId, additionalDocumentId) {
    return httpClient('v0.4').post(`/user/documents/${documentId}/attach`, {
      additional_document: {
        id: additionalDocumentId
      }
    });
  },
  addCreditCard(cardToken) {
    return httpClient('v0.4').post('/user/payment_cards', {
      payment_card: {
        stripe_token: cardToken
      }
    });
  },
  createStripeSetupIntents() {
    return httpClient('v0.4').post('/user/stripe_setup_intents');
  },
  confirmStripeCard(setupIntentId, paymentMethodId) {
    return httpClient('v0.4').post('/user/stripe_cards', {
      stripe_card: {
        stripe_setup_intent_id: setupIntentId,
        stripe_payment_method_id: paymentMethodId
      }
    });
  },
  getCreditCards() {
    return httpClient('v0.4').get('/user/stripe_cards');
  },
  setDefaultCreditCard(cardId) {
    return httpClient('v0.4').put(`/user/stripe_cards/${cardId}/default`);
  },
  deleteCreditCard(cardId) {
    return httpClient('v0.4').delete(`/user/stripe_cards/${cardId}`);
  },
  resetPassword(email) {
    return httpClient('v0.3').post('/reset_password', {
      email: email.toLowerCase(),
      context: 'frontoffice'
    });
  },
  confirmPassword(password, passwordConfirmation, resetPasswordToken) {
    return httpClient('v0.3').post('/reset_password/confirm', {
      password: password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken
    });
  },
  sendAcceptTos(terms_and_conditions_version_id) {
    return httpClient('v0.4').post(
      '/user/terms_and_conditions_versions/accept',
      {
        terms_and_conditions: {
          version_id: terms_and_conditions_version_id
        }
      }
    );
  }
};
