import React, { useState } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import DateMaskedInput from './DateMaskedInput';

function DateInput({
  required = false,
  placeholder = '',
  onChange,
  value = '',
  name = '',
  error = false,
  helperText = null
}) {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = e => {
    setInputValue(e.target.value);

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  return (
    <div className="date-input">
      <DateMaskedInput
        required={required}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        error={error}
        helperText={helperText}
      />
    </div>
  );
}

export default DateInput;
