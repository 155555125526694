import React from 'react';
import { useTranslation } from 'react-i18next';
import doorsImage from '../../../assets/images/vehicle/doors.svg';
import shiftImage from '../../../assets/images/vehicle/shift.svg';
import seatImage from '../../../assets/images/vehicle/seat.svg';

import fuelImage from '../../../assets/images/vehicle/fuel.svg';
import electricImage from '../../../assets/images/vehicle/electric.svg';

const Options = ({ doors, transmission, seats, fuel }) => {
  const { t } = useTranslation(['booking']);

  const transmissions = {
    AUTOMATIC: 'booking:transmission-auto',
    MANUAL: 'booking:transmission-manual'
  };

  const fuelTypes = {
    ELECTRIC: { txt: 'booking:electric', img: electricImage },
    GASOLINE: { txt: 'booking:gasoline', img: fuelImage },
    DIESEL: { txt: 'booking:diesel', img: fuelImage },
    GPL: { txt: 'booking:gpl', img: fuelImage },
    HYBRID: { txt: 'booking:hybrid', img: electricImage }
  };

  return (
    <div className="features">
      {doors ? (
        <div className="feature">
          <div className="icon">
            <img src={doorsImage} alt="" />
          </div>
          <div className="label">
            {t('booking:doors-count', { count: doors })}
          </div>
        </div>
      ) : null}

      {seats ? (
        <div className="feature">
          <div className="icon">
            <img src={seatImage} alt="" />
          </div>
          <div className="label">
            {t('booking:seats-count', { count: seats })}
          </div>
        </div>
      ) : null}

      <div
        className={
          transmission && transmissions[transmission] ? 'feature' : 'hidden'
        }
      >
        <div className="icon">
          <img src={shiftImage} alt="" />
        </div>
        <div className="label">{t(transmissions[transmission])}</div>
      </div>

      {fuel && fuelTypes[fuel] ? (
        <div className="feature">
          <div className="icon">
            <img src={fuelTypes[fuel].img} alt="" />
          </div>
          <div className="label">{t(fuelTypes[fuel].txt)}</div>
        </div>
      ) : null}
    </div>
  );
};

export default Options;
