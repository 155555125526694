import algolia from './algolia';
import api from './api';
import auth from './auth';
import stripe from './stripe';
import google from './google';
import rollbar from './rollbar';

export default {
  algolia,
  api,
  auth,
  stripe,
  google,
  rollbar
};
