import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderFooterLayout from '../components/layout/HeaderFooterLayout';
import ContainerWithLeftPanel from '../components/layout/ContainerWithLeftPanel';
import HeroPanel from '../components/shared/HeroPanel';
import ResetPassword from '../components/auth/ResetPassword';
import config from './../config';

function ResetPasswordPage({ history }) {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  if (isAuthenticated) {
    history.push(config.auth.rootUrl);
  }
  return (
    <div className="login-page">
      <HeaderFooterLayout>
        <ContainerWithLeftPanel
          panel={<HeroPanel />}
          main={<ResetPassword />}
        />
      </HeaderFooterLayout>
    </div>
  );
}

export default withRouter(ResetPasswordPage);
